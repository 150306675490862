import { threadAPI } from '@/api'
import defineListStore from './defineListStore'
import ThreadModel from '@/models/thread'

// 评论列表
export const commentListStore = defineListStore({
  getMethod: threadAPI.getCommentList,
  dataParser: (items) => {
    return items.map((item) => {
      const threadModel = ThreadModel.fromJson(item)
      try {
        return threadModel
      } catch (e) {
        console.error(e)
      }
      return item
    })
  },
})

<template>
  <div class="main-wide">
    <div class="crumb">
      <app-link :to="home.toLink()">首页</app-link
      ><app-link :to="user.toLink({ id })">{{ getUname() }}</app-link
      >消息中心
    </div>
    <section class="comm-mod mod-notification">
      <header class="hd">
        <div class="source">发信人</div>
        <div class="con">主题</div>
        <div class="date">日期</div>
      </header>
      <div class="bd">
        <list-loader
          v-bind="notificationStates"
          @load="notificationActions.load()"
        >
          <div class="notification-list">
            <div
              v-for="item in notificationItems"
              :key="item.id"
              :class="['item', item.readFlag ? '' : 'noread']"
            >
              <div class="source">{{ item.senderName }}</div>
              <div class="con">
                <app-link v-if="item.giftId" :to="item.url"
                  >{{ item.content ? item.content : item.title
                  }}<span>马上抢号&gt;&gt;</span></app-link
                >
                <template
                  v-else-if="item.addition && safeJSONParse(item.addition).type"
                >
                  <app-link
                    v-if="[1, 103].includes(JSON.parse(item.addition).type)"
                    :to="
                      thread.toLink({
                        id: JSON.parse(item.addition).tid || item.threadId,
                      })
                    "
                    >{{ item.content ? item.content : item.title
                    }}<span>查看帖子&gt;&gt;</span></app-link
                  ><app-link
                    v-else-if="
                      [2, 3, 4, 5, 6].includes(JSON.parse(item.addition).type)
                    "
                    :to="
                      thread.toLink(
                        { id: JSON.parse(item.addition).tid || item.threadId },
                        '#comment'
                      )
                    "
                    >{{ item.content ? item.content : item.title
                    }}<span>查看详情&gt;&gt;</span></app-link
                  ><app-link
                    v-else-if="
                      [101, 102, 104, 107, 108, 109, 110, 113, 115].includes(
                        JSON.parse(item.addition).type
                      )
                    "
                    :to="thread.toLink({ id: 29130 })"
                    >{{ item.content ? item.content : item.title
                    }}<span>查看详情&gt;&gt;</span></app-link
                  ><app-link
                    v-else-if="
                      [105, 106].includes(JSON.parse(item.addition).type)
                    "
                    :to="thread.toLink({ id: 29131 })"
                    >{{ item.content ? item.content : item.title
                    }}<span>查看详情&gt;&gt;</span></app-link
                  ><app-link v-else-if="item.url" :to="item.url"
                    >{{ item.content ? item.content : item.title
                    }}<span>查看详情&gt;&gt;</span></app-link
                  >
                  <div v-else>
                    {{ item.content ? item.content : item.title }}
                  </div></template
                >
                <app-link
                  v-else-if="item.url && !/my\.17173\.com/gi.test(item.url)"
                  :to="item.url"
                  >{{ item.content ? item.content : item.title
                  }}<span>查看详情&gt;&gt;</span></app-link
                >
                <div v-else>{{ item.content ? item.content : item.title }}</div>
              </div>
              <div class="date">{{ dateFormatNormal(item.sendTime) }}</div>
            </div>
          </div>
        </list-loader>
      </div>
    </section>
  </div>
  <app-fixed-bar></app-fixed-bar>
</template>

<script setup>
import { home, user, thread } from '@/core/pages'
import { getUname } from '@/core/passport'
import { userNotificationStore } from '@/store/user'
import { dateFormatNormal } from '@/core/util'

const {
  items: notificationItems,
  states: notificationStates,
  actions: notificationActions,
} = userNotificationStore()

function safeJSONParse(str) {
  try {
    return JSON.parse(str)
  } catch (e) {
    return {}
  }
}

let flag = true
function init() {
  const timer = setInterval(async () => {
    if (!notificationStates.loading && flag) {
      flag = false
      clearInterval(timer)
      await notificationActions.load()
    }
  }, 300)
}
init()
</script>

<style lang="less">
.mod-notification {
  .hd {
    display: flex;
    padding-top: @gap;
    margin-bottom: @gap;
    color: @color-light;
  }
  .no-more {
    margin-top: 0;
  }

  .source {
    flex-shrink: 0;
    width: 10em;
    color: @color-light;
  }
  .con {
    flex: 1;
  }
  .date {
    flex-shrink: 0;
    width: 8em;
    margin-left: @gap;
    color: @color-light;
  }
}
.notification-list {
  .item {
    display: flex;
    margin-bottom: @gap;

    &.noread {
      .con {
        font-weight: 700;
        word-break: break-all;
      }
    }

    a {
      span {
        margin-left: 0.3em;
        color: @color-orange;
        white-space: nowrap;
      }
    }
  }
}
</style>

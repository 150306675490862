<template>
  <div class="editor-wrap">
    <editor
      ref="editorRef"
      :options="editorOption"
      :max-image-length="3"
    ></editor>
  </div>
  <div class="func-box">
    <div v-if="isDraftEnable && isShowSaveTip" class="txt-note">草稿已保存</div>
    <p class="enter-status">
      <span class="num" :class="{ error: contentCount > maxLength }">{{
        contentCount
      }}</span
      >/{{ maxLength }}
    </p>
    <button
      ref="submitCommentRef"
      class="btn-submit"
      @click="postAuthCheck(submitComment)"
    >
      发表
    </button>
  </div>
</template>

<script setup>
import {
  getUid,
  postAuthCheck,
  isBindPhone,
  isSockpuppet,
} from '@/core/passport'

import { threadAPI } from '@/api'
import { toast } from '@/components/toast'

const props = defineProps({
  tid: {
    type: String,
    required: true,
  },
  aid: {
    type: String,
    default: '',
  },
  biKey: {
    type: String,
    default: '',
  },
})

const tid = ref(props.tid)

const editorRef = ref(null)
const contentCount = ref(0)
const maxLength = 1000
const storageKey = ref(0)

const isDraftEnable = ref(!props.aid)

const saveDraft = () => {
  localStorage.setItem(storageKey.value, editorRef.value.contents)
}

let isChange = 0
let saveTimer = 0
const isShowSaveTip = ref(0)

saveTimer = setInterval(() => {
  if (!isChange) {
    isShowSaveTip.value = 1
    return
  }
  isChange = 0
  isShowSaveTip.value = 0
}, 3000)

const onChange = () => {
  contentCount.value = editorRef.value.length - 1
  if (isDraftEnable.value) {
    saveDraft()
  }
  isChange = 1
}

const submitCommentRef = ref('')
let isSubmit = true
const submitComment = async () => {
  if (!isSubmit) {
    return
  }
  isSubmit = false

  if (props.biKey.length) {
    _jc_ping.push(['_trackBlockClick', props.biKey])
  }

  const params = {
    tid: tid.value,
    message: editorRef.value.contents,
    aid: isDraftEnable.value ? null : props.aid,
  }

  if (!(await isSockpuppet()) && !(await isBindPhone())) {
    Passport.Dialog.showMobileBinder()
    return
  }
  submitCommentRef.value.disabled = true
  const postPromise = threadAPI.postComment(params)
  const checkRes = await postPromise.check()

  if (checkRes.success) {
    if (isDraftEnable.value) {
      localStorage.removeItem(storageKey.value)
    }

    if (!window.location.href.includes('#comment')) {
      window.location.href = `${window.location.href}#comment`
    }
    window.location.reload()
  } else {
    toast(checkRes.msg)
    setTimeout(() => {
      isSubmit = true
      submitCommentRef.value.disabled = false
    }, 3000)
  }
}

const editorOption = {
  image: !!isDraftEnable.value,
  video: false,
  splitMode: true,
  onChange,
}

onMounted(() => {
  if (isDraftEnable.value) {
    // 渲染本地缓存的评论
    storageKey.value = `comment-post-draft-${getUid()}-${tid.value}`
    const draftContents = JSON.parse(localStorage.getItem(storageKey.value))
    if (draftContents) {
      editorRef.value.loadContents(draftContents)
    }
  }
})

onUnmounted(() => {
  if (saveTimer) clearInterval(saveTimer)
})
</script>

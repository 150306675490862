<template>
  <div class="main-wide"></div>
</template>

<script setup>
document.title = `玩家帮助中心_17173网络游戏门户站`
window.location.href = 'https://support.qq.com/product/351729'
</script>
<style lang="less" scoped>
#app,
.g-topbar {
  display: none !important ;
}
</style>

<template>
  <section class="article">
    <div
      v-if="fixTop"
      class="article-top"
      :style="{
        top: topbarHeight,
      }"
    >
      <div v-if="data.title" class="tit">{{ data.title }}</div>
      <div class="action-bar">
        <span class="view"
          ><svg-icon name="view"></svg-icon
          >{{ data.views > 0 ? numberFormat(data.views) : '' }}</span
        >
        <span class="reply" @click="scrollToComment"
          ><svg-icon name="comment"></svg-icon
          >{{ data.replies ? numberFormat(data.replies) : '' }}</span
        >
        <span class="bump" @click="postAuthCheck(bump)"
          ><svg-icon
            :name="[data.hasSupported ? 'bump-active' : 'bump']"
          ></svg-icon
          >{{ data.supports > 0 ? numberFormat(data.supports) : '' }}</span
        >
      </div>
    </div>
    <header class="article-hd">
      <div class="top">
        <author-info v-if="data" :data="data" />
        <div v-if="canComment()" class="user-operations">
          <div class="user-operations-btn"></div>
          <div class="user-operations-popup">
            <app-link
              v-if="isCurrent(data.authorUid)"
              v-track-click="'MFryAn'"
              :to="post.toLink({ id: data.id })"
              class="btn-edit-thread"
              >编辑</app-link
            >
            <a
              v-if="isCurrent(data.authorUid) || isManager"
              href="javascript:;"
              @click="onRemoveClick"
              >删除</a
            >
            <a v-if="isManager" href="javascript:;" @click="onDigestThreadClick"
              >加精</a
            >
            <a v-if="isManager" href="javascript:;" @click="onHideThreadClick"
              >隐藏</a
            >
            <a
              v-if="!isCurrent(data.authorUid)"
              href="javascript:;"
              @click="reportDialogVisible = true"
              >举报</a
            >
          </div>
        </div>
      </div>
      <div v-if="data.digest" class="type">
        <img src="@/assets/images/ico-type-jh.png" alt="" />
      </div>
      <h1 v-if="data.title" class="tit">{{ data.title }}</h1>
      <div class="info">
        <p class="group-name">
          <app-link :to="group.toLink({ id: data.gid })">{{
            data.groupName
          }}</app-link>
        </p>
        <div class="action-bar">
          <span class="view"
            ><svg-icon name="view"></svg-icon
            >{{ data.views > 0 ? numberFormat(data.views) : '' }}</span
          >
          <span class="reply" @click="scrollToComment"
            ><svg-icon name="comment"></svg-icon
            >{{ data.replies ? numberFormat(data.replies) : '' }}</span
          >
          <span class="bump" @click="postAuthCheck(bump)"
            ><svg-icon
              :name="[data.hasSupported ? 'bump-active' : 'bump']"
            ></svg-icon
            >{{ data.supports > 0 ? numberFormat(data.supports) : '' }}</span
          >
        </div>
      </div>
    </header>
    <div class="article-bd">
      <article class="article-con">
        <template v-for="(item, index) in data.richContents" :key="index">
          <paragraph-text
            v-if="item.isText"
            :data="item"
            :is-desc="false"
          ></paragraph-text>
          <p v-if="item.isImage" class="image" :class="item.needCommentClass">
            <ImagesViewer
              :data="data.images"
              :item="item"
              :is-show-img-cmt-btn="true"
              @show-img-comment-dialog="showImgCommentDialog"
            ></ImagesViewer>
          </p>
          <p
            v-if="item.isLink"
            :class="[{ 'embed-link': item.value }, item.needCommentClass]"
          >
            <a v-if="item.value" :href="item.value" target="_blank">
              <img :src="item.attr.logo" />
              <span class="txt">{{ item.attr.title || item.value }}</span></a
            >
          </p>
          <p v-if="item.isIframe" :class="item.needCommentClass">
            <iframe
              :src="item.value"
              scrolling="no"
              width="100%"
              height="500"
              border="0"
              frameborder="no"
              framespacing="0"
              allowfullscreen="true"
            >
            </iframe>
          </p>
          <p v-if="item.isVideo" :class="item.needCommentClass">
            <!-- :height="(600 * item.height) / item.width + 'px'" -->
            <template v-if="item.value.code === 0"
              ><vue3videoPlay
                width="100%"
                height="400px"
                color="#409eff"
                :control-btns="['fullScreen', 'volume']"
                :src="item.value.url"
                :poster="item.value.picUrl"
                :type="item.value.url.endsWith('.mp4') ? 'video/mp4' : 'm3u8'"
            /></template>
            <span v-else-if="item.value.code === 603" class="video-disabled">
              视频审核未通过
            </span>
            <span v-else class="video-checking">视频审核中</span>
          </p>
        </template>
      </article>

      <section
        v-if="hotThreadList.length"
        v-track-module="'BZrqIj'"
        class="comm-mod mod-hot-thread"
      >
        <header class="hd">
          <div class="tit">》》》近期热帖：</div>
        </header>
        <div class="bd">
          <div class="hot-thread-list">
            <div
              v-for="hotData in hotThreadList.slice(0, 4)"
              :key="hotData.id"
              class="item"
            >
              <app-link :to="thread.toLink({ id: hotData.id })">
                <figure v-if="hotData.videos.length" class="pic">
                  <img :src="hotData.videos[0].value.picUrl" />
                </figure>
                <figure v-else-if="hotData.images.length" class="pic">
                  <img :src="hotData.images[0].value" />
                </figure>
                <div class="con">
                  <template
                    v-for="(item, index) in hotData.richContents"
                    :key="index"
                  >
                    <div v-if="item.isText" class="txt">{{ item.value }}</div>
                  </template>
                  <div class="action-bar">
                    <span class="time">{{
                      dateFormat(hotData.createDate)
                    }}</span>
                    <span class="reply"
                      ><svg-icon name="comment"></svg-icon
                      >{{
                        hotData.posts > 0 ? numberFormat(hotData.posts) : ''
                      }}</span
                    >
                  </div>
                </div>
              </app-link>
            </div>
          </div>
        </div>
      </section>
      <group-info :data="groupInfo" />
      <div class="action-bar">
        <span class="bump" @click="postAuthCheck(bump)"
          ><svg-icon
            :name="[data.hasSupported ? 'bump-active' : 'bump']"
          ></svg-icon
          >{{ data.supports > 0 ? numberFormat(data.supports) : '' }}</span
        >
      </div>
    </div>
    <footer class="article-ft">
      <app-publish-platform v-if="data.platform === 1"></app-publish-platform>
      <div class="other-info">
        <div class="tags">
          <app-link
            v-for="item in data.searchKeywords"
            :key="item.id"
            :to="subject.toLink({ id: item.id })"
            >{{ item.label }}</app-link
          >
        </div>
        <div class="pub-time">发布于：{{ dateFormat(data.createDate) }}</div>
      </div>
    </footer>
  </section>
  <dialog-report
    v-show="reportDialogVisible"
    :data="reportData"
    @close="reportDialogVisible = false"
  ></dialog-report>
  <dialog-image-comment
    v-if="isShowImgCmtDialog"
    :data="imgCmtCon"
    :tid="data.id"
    :author="data.author"
    @close="isShowImgCmtDialog = false"
  ></dialog-image-comment>
</template>

<script setup>
import 'vue3-video-play/dist/style.css'
import vue3videoPlay from 'vue3-video-play'
import debounce from 'lodash.debounce'
import { dateFormat, numberFormat } from '@/core/util'
import { postAuthCheck, isCurrent, canComment } from '@/core/passport'
import { threadAPI } from '@/api'
import { home, post, group, thread, subject } from '@/core/pages'
import { dialog } from '@/components/dialog'

import AuthorInfo from './AuthorInfo.vue'
import GroupInfo from './GroupInfo.vue'
import { toast } from '@/components/toast'
import DialogImageComment from './DialogImageComment.vue'

const props = defineProps({
  data: {
    type: Object,
    default: null,
  },
  isManager: {
    type: Boolean,
    default: false,
  },
  scrollToComment: {
    type: Function,
    default: null,
  },
  groupInfo: {
    type: Object,
    default: null,
  },
  hotThreadList: {
    type: Object,
    default: null,
  },
})

const reportDialogVisible = ref(false)
const reportData = {
  kind: 1,
  list: [
    '内容与主题不符',
    '垃圾广告营销',
    '谩骂侮辱不友善内容',
    '淫秽色情信息',
    '违法有害信息',
  ],
  content: props.data.id,
  reportedUid: props.data.authorUid,
  hasForm: true,
}

async function bump() {
  const { data } = props
  const res = threadAPI.submitSupportThread(data.id)
  const status = await res.check()
  if (status.success) {
    const d = await res.data()
    data.supports = d.totalCount
    data.hasSupported = d.supportStatus
  } else {
    toast(status.msg)
  }
}

function onRemoveClick() {
  const { data } = props
  dialog({
    msg: '再考虑下吧，帖子删除就无法找回了',
    onConfirm: async () => {
      await threadAPI.delThread(data.id).check()
      home.to()
    },
  })
}

function onDigestThreadClick() {
  const { data } = props
  dialog({
    msg: '确定设置为精华帖吗？',
    onConfirm: async () => {
      const res = await threadAPI.digestThread(data.id).check()
      toast(res.msg)
    },
  })
}
function onHideThreadClick() {
  const { data } = props
  console.log(data.id)
  dialog({
    msg: '确定隐藏该贴吗？',
    onConfirm: async () => {
      const res = await threadAPI.hideThread(data.id).check()
      toast(res.msg)
    },
  })
}

const isShowImgCmtDialog = ref(false)
const imgCmtCon = ref(null)
function showImgCommentDialog(val) {
  isShowImgCmtDialog.value = !!val
  imgCmtCon.value = val
  _jc_ping.push(['_trackBlockClick', 'rYrymi'])
}

const fixTop = ref(0)
const topbarHeight = ref(0)

const handleScroll = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  const windowHeight = document.documentElement.clientHeight
  fixTop.value = scrollTop >= windowHeight * 0.1
}
const debouncedFunction = debounce(handleScroll, 100)

onMounted(() => {
  window.addEventListener('scroll', debouncedFunction)
  window.addEventListener('resize', debouncedFunction)
  topbarHeight.value = document.querySelector('body').style.paddingTop || 0
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', debouncedFunction)
  window.removeEventListener('resize', debouncedFunction)
})
</script>

<style lang="less">
.article {
  .mod();

  &-top,
  &-hd {
    .tit {
      color: @color-black;
      font-weight: 700;
      line-height: 1.38;
    }
    .reply {
      cursor: pointer;
    }
    .action-bar,
    .info {
      .em(14em);
      color: @color-light;
    }
  }

  &-top {
    position: fixed;
    z-index: @article-top-zindex;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: @main-wide-width;
    height: 49px;
    padding: 0 20px;

    background: #fff;
    box-sizing: border-box;

    .tit {
      flex: 1;
      .text-ellipsis();
    }

    .action-bar {
      flex: 1;
      justify-content: flex-end;
    }

    &::after {
      content: ' ';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 6px;
      border-top: 1px solid @bg-gray;
      background: linear-gradient(to bottom, @bg-gray 0%, transparent 100%);
      transform: translateY(100%);
    }
  }

  &-hd {
    position: relative;
    padding: @gap;
    .top {
      position: relative;
      z-index: 50;
      margin-bottom: @gap*1.5;
    }
    .tit {
      .em(26em);
    }
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 1.1em;
    }
    .type {
      position: absolute;
      top: @gap;
      right: @gap;
      width: 6.5em;
      margin-top: 2em;
      pointer-events: none;

      & + .tit {
        margin-right: 4em;
      }
    }
  }

  .group-name {
    position: relative;
    padding-left: 22px;
    color: @color-orange;

    a {
      color: currentColor;

      &:hover {
        text-decoration: underline;
      }

      &::before,
      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      &::before {
        left: 0;
        border: 1px solid @color-yellow;
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
      &::after {
        left: 6px;
        width: 6px;
        height: 6px;
        background: currentColor;
        border-radius: 50%;
      }
    }
  }

  &-bd {
    padding: 0 @gap;

    & > .action-bar {
      width: 70px;
      margin: 2em auto;

      span {
        flex-direction: column;
        justify-content: center;
        width: 70px;
        height: 70px;
        margin-left: 0;
        .em(14em);
        line-height: 1.2;
        border: 1px solid @bg-gray;
        border-radius: 50%;
      }
      .svg-ico {
        font-size: 1.3em;
        margin-right: 0;
        margin-bottom: 0.2em;
      }
    }
  }

  &-ft {
    margin-top: 4em;

    .em(14em);

    .pub-platform {
      padding: 0.8em @gap 0.4em;
    }

    .other-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 @gap;
      border-top: 1px solid @color-lighten;
    }

    .tags {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-top: 0.8em;
      padding-bottom: 0.3em;

      a {
        padding: 0 0.5em;
        margin-right: 0.5em;
        margin-bottom: 0.5em;
        line-height: 2.28;
        color: #ff9c00;
        background: #fffae5;
        border-radius: 4px;

        &::before {
          content: '#';
        }
      }
    }
    .pub-time {
      flex-shrink: 0;
      color: @color-light;
      text-align: right;
      padding-top: 0.8em;
      padding-bottom: 0.8em;
    }
  }

  .mod-hot-thread {
    margin-top: 3em;
    .hd {
      padding: 0;
      border-bottom: 0;
    }
    .tit {
      color: inherit;
      line-height: 1.5;
    }
  }

  .hot-thread-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      flex-shrink: 0;
      width: 48%;
    }
    a {
      padding: 5px 10px;
      border-radius: 6px;
      &:hover {
        background-color: @bg-gray;
      }
    }

    .pic {
      margin-left: 0;
      margin-right: 20px;
    }

    .txt {
      height: 1.75em * 2;
    }

    .con {
      width: 100%;
    }
  }
}

@embed-border-radius: 6px;
@embed-wrap-gap: 10px;
@embed-inner-gap: 13px;
@needcomment-inner-gap: 1em;
@needcomment-border-color: #e5e7ea;
.needcomment-first-ele {
  position: relative;
  padding-top: @needcomment-inner-gap + 0.5 !important;
  margin-top: 1em;
  border-top: 1px dashed @needcomment-border-color;
  border-top-left-radius: @embed-border-radius;
  border-top-right-radius: @embed-border-radius;

  &::before {
    content: '\672c\5e16\9690\85cf\5185\5bb9';
    position: absolute;
    top: -1px;
    right: 0;
    left: 0;
    z-index: 20;
    width: 8em;
    .em(14em);
    line-height: 2;
    text-align: center;
    margin: auto;
    background-color: #fff;
    border: 1px dashed @needcomment-border-color;
    border-radius: 2em;
    transform: translate(0, -50%);
  }
}

.article-inline-ele {
  max-width: 100%;
  margin-top: 1.75em;
  margin-bottom: 1.75em;
}
.article-con {
  word-wrap: break-word;

  // p,
  // div,
  // ul,
  // ol,
  // table {
  //   padding: 1.1em 0;
  // }
  ol {
    list-style: inside decimal;
  }

  h2 {
    font-size: 125%;
  }

  video,
  iframe {
    .article-inline-ele();
  }
  .image {
    text-align: center;
    cursor: pointer;
  }

  .img-box {
    display: inline-flex;
    flex-direction: column;
    position: relative;

    .article-inline-ele();

    &:hover {
      .img-cmt-btn {
        display: flex;
      }
    }

    .img-desc {
      margin-top: 0.5em;
    }
  }

  .img-cmt-btn {
    display: none;
    align-items: center;
    justify-content: flex-end;
    min-width: 9em;

    position: absolute;
    z-index: 20;
    top: 0;
    right: 0;
    left: 0;
    padding: 0.3em 0.5em;
    .em(14em);
    color: #fff;

    .svg-ico {
      width: 1.28em;
      height: 1.42em;
      margin-right: 0.3em;
      fill: currentColor;
    }

    &::after {
      content: ' ';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(
        to bottom,
        rgba(#000, 0.5) 0%,
        rgba(#000, 0) 100%
      );
      pointer-events: none;
    }
  }
  .img-desc {
    display: block;
    margin-top: -1.5em;
    margin-bottom: 1.75em;
    font-size: (14em / 16);
    color: @color-light;
    line-height: 1.3;
  }

  a {
    color: @color-blue;

    &:hover {
      text-decoration: underline;
    }
  }

  .embed-link {
    a {
      display: flex;
      align-items: center;
      padding: 0.7em 0.8em;
      color: #494d5c;
      line-height: 1.5;
      border: 1px solid @bg-gray;
      border-radius: 6px;
      word-break: break-all;

      &:hover {
        text-decoration: none;
        background: @bg-gray;
      }
    }

    img {
      flex-shrink: 0;
      max-width: 20px;
      margin: 0 0.8em 0 0;
    }
  }

  // 隐藏回复可见
  *:not(.hide-need-comment-con) + .hide-need-comment-con,
  .hide-need-comment-con:first-child {
    display: flex;
    align-items: center;

    padding: 20px;
    color: #ff9c00;
    line-height: 1.1;
    background: #fefdf8;
    border: 1px dashed @color-yellow;
    border-radius: 6px;

    &::before {
      content: ' ';
      flex-shrink: 0;
      width: 1.2em;
      height: 1.2em;
      margin-right: 0.8em;
      background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2019%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23ff9c00%22%20d%3D%22M14%207h-1V3s-1.6-3-5-3-5%203-5%203v4H2C.9%207%200%207.9%200%209v7c0%201.7%201.3%203%203%203h10c1.7%200%203-1.3%203-3V9c0-1.1-.9-2-2-2zm-5%206.7V15c0%20.6-.4%201-1%201s-1-.4-1-1v-1.3c-.6-.3-1-1-1-1.7%200-1.1.9-2%202-2s2%20.9%202%202c0%20.7-.4%201.4-1%201.7zM11%207H5V4s.5-2%203-2%203%202%203%202v3z%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E)
        no-repeat 50% 50% / contain;
    }

    &::after {
      content: '隐藏内容，回复后可见。';
    }
  }

  // 显示回复可见
  .show-need-comment-con {
    padding-left: @needcomment-inner-gap !important;
    padding-right: @needcomment-inner-gap !important;
    background: #fff;
    border-left: 1px dashed @needcomment-border-color;
    border-right: 1px dashed @needcomment-border-color;

    // 回复可见：头部样式
    &:first-child {
      &:extend(.needcomment-first-ele all);
    }

    // 回复可见：底边样式
    &:last-child {
      padding-bottom: 1em !important;
      border-bottom: 1px dashed @needcomment-border-color;
      border-bottom-left-radius: @embed-border-radius;
      border-bottom-right-radius: @embed-border-radius;
    }

    // 回复可见：底边样式
    & + *:not(.show-need-comment-con) {
      &::before {
        content: ' ';
        display: block;
        height: @needcomment-inner-gap;
        border: 1px dashed @needcomment-border-color;
        border-top: 0;
        border-bottom-left-radius: @embed-border-radius;
        border-bottom-right-radius: @embed-border-radius;
      }
    }
  }

  // 回复可见：头部样式
  *:not(.show-need-comment-con) + .show-need-comment-con {
    &:extend(.needcomment-first-ele all);
  }
}
</style>

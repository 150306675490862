import PageInfo from './PageInfo'
/*
社区主页
http://dev.club.17173.com/group/index
圈子主页
http://dev.club.17173.com/group/info
圈子列表
http://dev.club.17173.com/group/list

贴子详情
http://dev.club.17173.com/thread/info
发布贴子
http://dev.club.17173.com/thread/issue

用户主页
http://dev.club.17173.com/user/space
*/

/*
export的变量名称要与pages/index.js下保持一致.
没有直接饮用pages/index.js是为了防止循环import
 */

const keywords =
  '玩家论坛,玩家社区,游戏圈,游戏论坛,游戏社区,17173玩家社区,17173游戏圈,硬核游戏玩家聚集地'
const description =
  '一起一起上是17173旗下游戏玩家社区，与游戏爱好者一起共建高质量讨论的游戏社区。玩硬核游戏，就一起一起上！'

export const home = new PageInfo({
  title: '首页',
  path: '/',
  meta: {
    keywords,
    description,
  },
})

export const post = new PageInfo({
  title: '发表帖子',
  path: '/thread/issue/:id?',
  needAuth: true,
  meta: {
    keywords,
    description,
  },
})

export const thread = new PageInfo({
  title: '帖子',
  path: '/thread/info/:id',
  meta: {
    keywords,
    description,
  },
})

export const group = new PageInfo({
  title: '圈子',
  path: '/group/info/:id',
  meta: {
    keywords,
    description,
  },
})

export const groupList = new PageInfo({
  title: '圈子列表',
  path: '/group/list',
  meta: {
    keywords,
    description,
  },
})
export const groupManagerList = new PageInfo({
  title: '管理成员',
  path: '/manager-list/:id',
  meta: {
    keywords,
    description,
  },
})

export const user = new PageInfo({
  title: '个人页',
  path: '/user/space/:id',
  meta: {
    keywords,
    description,
  },
})
export const userFollowList = new PageInfo({
  title: '关注',
  path: '/user/follow/:id',
  meta: {
    keywords,
    description,
  },
})
export const userFansList = new PageInfo({
  title: '粉丝',
  path: '/user/fans/:id',
  meta: {
    keywords,
    description,
  },
})

export const userNotification = new PageInfo({
  title: '消息中心',
  path: '/user/notification/:id?',
  meta: {
    keywords,
    description,
  },
  needAuth: true,
})

export const userProfile = new PageInfo({
  title: '编辑个人资料',
  path: '/user/profile',
  meta: {
    keywords,
    description,
  },
  needAuth: true,
})

export const help = new PageInfo({
  title: '玩家帮助中心',
  path: '/help',
  meta: {
    keywords,
    description,
  },
})

export const error = new PageInfo({
  title: '404',
  path: '/404',
  meta: {
    keywords,
    description,
  },
})

export const subject = new PageInfo({
  title: '话题',
  path: '/subject/info/:id',
  meta: {
    keywords,
    description,
  },
})

// export const sample = new PageInfo({
//   title: '功能示例',
//   path: '/sample/:id/:cc',
//   needAuth: true,
// })

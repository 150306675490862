/**
 * 绑定模块统计
 * 在html元素上配置属性: v-track-module="'模块统计代码'"
 */
export const trackModule = {
  mounted: (el, binding) => {
    if (binding.value) {
      _jc_ping.push(['_trackModule', el, binding.value])
    }
  },
}

/**
 * 绑定点击统计
 * 在html元素上配置属性: v-track-click="'点击统计代码'"
 */
export const trackClick = {
  mounted: (el, binding) => {
    if (binding.value) {
      el.addEventListener('click', () => {
        console.log(el, binding.value)
        _jc_ping.push(['_trackBlockClick', binding.value])
      })
    }
  },
}

export function gString(value) {
  return value ? value.toString() : ''
}

export function gNum(value) {
  return value || 0
}

// 服务端接口 1= true, 0=false
export function gBool(value) {
  if (typeof value === 'boolean') {
    return value
  }
  if (value * 1 === 1) {
    return true
  }
  return false
}

export function gList(value) {
  return value || []
}

import { isArray } from '@vue/shared'
import { defineStore } from './defineStore'
/*
 *定义列表store
 * @param {config.pageSize} 分页大小
 * @param {config.getMethod} /src/api/index.js 下定义的接口方法, 如: threadAPI.plazaList
 */
export default function defineListStore(config) {
  return defineStore(() => {
    const items = ref([])

    const pageIndex = ref(0)

    const pageSize = ref(config.pageSize || 10)
    const empty = ref(false)
    const loading = ref(false)
    const finished = ref(false)
    const totalCount = ref(-1)

    async function load(params, option = {}) {
      const targetOption = {
        ...option,
        ...{
          limitTime: 1000,
        },
      }
      if (loading.value !== true) {
        loading.value = true
        pageIndex.value += 1 // 因为服务端接口页数是从 1开始的,所以这里即使首次加载也+1
        const beginTime = Date.now() * 1
        const data = await config
          .getMethod({
            pageSize: pageSize.value,
            page: pageIndex.value,
            ...params,
          })
          .data()
        if (data !== null) {
          if (isArray(data)) {
            const dataSet =
              typeof config.dataParser === 'function'
                ? config.dataParser(data || [])
                : data || []
            pageIndex.value = 1
            totalCount.value = dataSet.length
            empty.value = dataSet.length === 0
            finished.value = true
            items.value = dataSet
          } else {
            const dataSet =
              typeof config.dataParser === 'function'
                ? config.dataParser(data.dataSet || [])
                : data.dataSet || []
            pageIndex.value = data.page
            totalCount.value = data.totalCount
            empty.value = data.totalCount === 0
            items.value = [...items.value, ...dataSet]
            finished.value = items.value.length >= totalCount.value
          }
        }
        const duration = Date.now() * 1 - beginTime
        const delay =
          duration > targetOption.limitTime
            ? 0
            : targetOption.limitTime - duration

        if (delay > 0) {
          setTimeout(() => {
            loading.value = false
          }, delay)
        } else {
          loading.value = false
        }
      }
    }

    function reset() {
      pageIndex.value = 0
      items.value = []
      loading.value = false
      finished.value = false
      empty.value = false
      totalCount.value = -1
    }

    return {
      items,
      pageSize,
      totalCount,
      states: reactive({
        loading,
        finished,
        empty,
      }),
      actions: {
        load,
        reset,
      },
    }
  })
}

<template>
  <div v-if="data.$visible" class="thread-item">
    <div class="thread-item-hd">
      <user-card :data="data"></user-card>
      <user-badge
        v-if="data.badge"
        :id="data.authorUid"
        :data="data.badge"
      ></user-badge>
      <div class="time">{{ dateFormat(data.createDate) }}</div>
      <app-link
        v-if="data.groupName"
        :to="group.toLink({ id: data.gid })"
        class="game"
        >{{ data.groupName }}</app-link
      >
      <div
        v-if="
          canComment() && (getUid() === data.authorUid.toString() || isManager)
        "
        class="user-operations"
      >
        <div class="user-operations-btn"></div>
        <div class="user-operations-popup">
          <app-link
            v-if="isCurrent(data.authorUid)"
            :to="post.toLink({ id: data.id })"
            >编辑</app-link
          >
          <a @click="onRemoveClick">删除</a>
        </div>
      </div>
    </div>
    <div class="thread-item-bd">
      <app-link :to="thread.toLink({ id: data.id })">
        <p class="tit">
          <b v-if="data.digest" class="type-tag-good">精华</b>{{ data.title }}
        </p>
        <div class="con">
          <template v-for="(item, index) in data.richContents" :key="index">
            <paragraph-text
              v-if="item.isText"
              :data="item.length > 70 ? item + '...' : item"
            ></paragraph-text>
            <div v-if="item.isVideo" class="video-box" @click.stop.prevent="">
              <template v-if="item.value.code === 0">
                <vue3videoPlay
                  v-if="item.isVideo"
                  v-show="delayRender"
                  width="100%"
                  height="400px"
                  color="#409eff"
                  :control-btns="['fullScreen', 'volume']"
                  :src="item.value.url"
                  :poster="item.value.picUrl"
                  :type="item.value.url.endsWith('.mp4') ? 'video/mp4' : 'm3u8'"
                />
              </template>
              <div v-else-if="item.value.code === 603" class="video-disabled">
                视频审核未通过
              </div>
              <div v-else class="video-checking">视频审核中</div>
            </div>
            <div v-if="item.isIframe" class="video-box" @click.stop.prevent="">
              <iframe
                :src="item.value"
                scrolling="no"
                border="0"
                frameborder="no"
                framespacing="0"
                allowfullscreen="true"
              >
              </iframe>
            </div>
          </template>
        </div>
      </app-link>
      <div
        v-if="data.images.length && !(data.videos.length || data.iframe.length)"
        class="img-box"
      >
        <figure
          v-for="(item, index) in data.images.slice(0, 4)"
          :key="index"
          class="pic"
        >
          <ImagesViewer :item="item" :data="data.images"></ImagesViewer>
        </figure>
      </div>
    </div>
    <div class="thread-item-ft">
      <div class="subject-tags">
        <app-link
          v-for="item in data.searchKeywords"
          :key="item.id"
          :to="subject.toLink({ id: item.id })"
          class="tag"
          :title="item.label"
          >{{ item.label }}</app-link
        >
      </div>
      <div class="action-bar">
        <span class="view"
          ><svg-icon name="view"></svg-icon
          >{{ data.views > 0 ? numberFormat(data.views) : '' }}</span
        >
        <app-link :to="thread.toLink({ id: data.id }, '#comment')" class="reply"
          ><svg-icon name="comment"></svg-icon
          >{{ data.replies > 0 ? numberFormat(data.replies) : '' }}</app-link
        >
        <span class="bump" @click.stop.prevent="postAuthCheck(bump)"
          ><svg-icon
            :name="[data.hasSupported ? 'bump-active' : 'bump']"
          ></svg-icon
          >{{ data.supports > 0 ? numberFormat(data.supports) : '' }}</span
        >
      </div>
    </div>
  </div>
  <app-recommd-users v-if="index == 1" :gid="gid"></app-recommd-users>
</template>

<script setup>
import vue3videoPlay from 'vue3-video-play'
import { thread, post, group, subject } from '@/core/pages'
import { threadAPI } from '@/api'
import { canComment, getUid, postAuthCheck, isCurrent } from '@/core/passport'
import { dateFormat, numberFormat } from '@/core/util'
import { toast } from '@/components/toast'
import { dialog } from '@/components/dialog'

const eventBus = inject('event-bus')

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  isManager: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: null,
  },
  gid: {
    type: String,
    default: null,
  },
  isSubjectList: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['remove', 'bump'])

const delayRender = ref(false)
onMounted(() => {
  setTimeout(() => {
    delayRender.value = true
  })
})

async function bump() {
  const { data } = props
  const res = await threadAPI.submitSupportThread(data.id)
  const status = await res.check()
  if (status.success) {
    const d = await res.data()
    data.supports = d.totalCount
    data.hasSupported = d.supportStatus

    if (data.hasSupported) {
      eventBus.emit('bump:up', data.authorUid)
    } else {
      eventBus.emit('bump:cancel', data.authorUid)
    }
  } else {
    toast(status.msg)
  }
}

function onRemoveClick() {
  dialog({
    msg: '再考虑下吧，帖子删除就无法找回了',
    onConfirm: () => {
      const { data } = props
      threadAPI.delThread(data.id)
      data.$visible = false
      toast('删除成功')
    },
  })
}
</script>

import axios from 'axios'
import qs from 'qs'
import md5 from 'md5'

const getToken = (url) => {
  const appkey = '50d85cf01cf70d6ec430105437d28568'
  const uri = url.replace('https', 'http')
  const timestamp = Math.floor(Date.now() / 1000)
  const accessToken = md5(appkey + uri + timestamp)
  return { timestamp, access_token: accessToken }
}

let baseUrl = import.meta.env.VITE_API_HOST
// 兼容内网地址
if (baseUrl.indexOf('internal') !== -1) {
  baseUrl = 'https://club.internal.17173.com/'
}

const instance = axios.create({
  // baseURL: '/api',
  baseURL: baseUrl,
  timeout: 60000,
  paramsSerializer(params) {
    return qs.stringify(params)
  },
  data: {
    a: 1,
  },
})

instance.interceptors.request.use(
  (config) => {
    const tokens = getToken(config.baseURL + config.url)
    const cfg = { ...config, params: { ...config.params, ...tokens } }
    return { ...cfg, withCredentials: true }
  },
  (error) => {
    console.error(error)
    return null
  }
)
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error)
    return null
  }
)

export default instance

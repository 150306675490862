<template>
  <div class="user-card-popup">
    <div class="popup-hd">
      <user-follow-btn :data="userInfo"></user-follow-btn>
    </div>
    <app-link :to="user.toLink({ id: data.authorUid })" class="popup-content">
      <div class="info">
        <figure class="avatar">
          <img :src="getAvatar(data.authorUid)" alt="" />
          <svg-icon v-if="data.authorTitle.length" name="vip"></svg-icon>
        </figure>
        <div class="main-info">
          <div class="name">
            {{ data.author }}
          </div>
          <user-badge v-if="data.badge" :data="data.badge"></user-badge>
        </div>

        <div v-if="data.authorTitle.length" class="user-tag">
          官方认证：{{ data.authorTitle }}
        </div>
        <div class="intro">{{ data.intro }}</div>
      </div>
      <div class="datas">
        <span class="thread-num"
          ><b>{{ userInfo.threadNum ? numberFormat(userInfo.threadNum) : 0 }}</b
          >帖子</span
        >
        <span class="follows-num"
          ><b>{{ numberFormat(userInfo.followNum) }}</b
          >关注</span
        >
        <span class="fans-num"
          ><b>{{ numberFormat(userInfo.fansNum) }}</b
          >粉丝</span
        >
        <span class="supports-num"
          ><b>{{ numberFormat(userInfo.support) }}</b
          >获赞</span
        >
      </div>
      <!-- <div class="funcs">
        <a href="" class="btn-message">私信</a>
      </div> -->
    </app-link>
  </div>
</template>

<script setup>
import { userAPI } from '@/api'
import { getAvatar, getUid } from '@/core/passport'
import { numberFormat } from '@/core/util'
import { user } from '@/core/pages'

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
})

const loading = ref(true)
const userInfo = ref({})

const init = async () => {
  userInfo.value = await userAPI.getUserInfo(props.data.authorUid).data()
  loading.value = false
}

init()

const eventBus = inject('event-bus')
eventBus.on('user:follow', () => {
  const isSelfCard = getUid() === props.data.authorUid
  if (isSelfCard) {
    userInfo.value.followNum += 1
  } else {
    userInfo.value.fansNum += 1
  }
})
eventBus.on('user:unfollow', () => {
  const isSelfCard = getUid() === props.data.authorUid
  if (isSelfCard) {
    userInfo.value.followNum -= 1
  } else {
    userInfo.value.fansNum -= 1
  }
})

eventBus.on('group:follow', () => {
  if (props.data.authorUid === getUid()) {
    userInfo.value.followNum += 1
  }
})
eventBus.on('group:unfollow', () => {
  if (props.data.authorUid === getUid()) {
    userInfo.value.followNum -= 1
  }
})

eventBus.on('bump:up', (uid) => {
  if (String(uid) === props.data.authorUid) {
    userInfo.value.support += 1
  }
})
eventBus.on('bump:cancel', (uid) => {
  if (String(uid) === props.data.authorUid) {
    userInfo.value.support -= 1
  }
})
</script>

<style lang="less">
.user-card-popup {
  display: none;
  top: 40px;
  left: -30px;
  width: 340px;
  font-size: @base-fs;
  .popup();

  &::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    right: 50%;
    height: 20px;
    background: rgba(#fff, 0);
  }

  .popup-hd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 80px;
    padding: 0 15px;
    background: no-repeat url(@user-default-head) 50% 50% / cover;
    border-radius: 6px 6px 0 0;

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background: rgba(#000, 0.4);
      pointer-events: none;
    }
  }
  .popup-content {
    display: block;
    position: relative;
    z-index: 5;
    padding: 0 15px 15px;
  }
  .info {
    position: relative;
    margin-top: -28px;
    padding-left: 68px;
    min-height: 54px;
  }
  .avatar {
    position: absolute;
    top: 0;
    left: 3px;
    width: 54px;
    height: 54px;
  }
  .svg-ico {
    right: 0;
    bottom: 0;
    width: 37%;
    height: 37%;
  }
  .main-info {
    display: flex;
    align-items: center;
    margin-bottom: 0.3em;
  }
  .name {
    .text-ellipsis();
    max-width: 9em;
    font-weight: 700;
    color: #fff;
    line-height: 1.2;
  }

  .user-tag {
    .em(14em);
    margin-bottom: 0.3em;
    color: @color-orange;
  }
  .intro {
    .em(14em);
    line-height: (20/14);
  }

  .datas {
    display: flex;
    margin-top: 1.2em;
    padding-bottom: 1em;
    .em(14em);
    text-align: center;
    span {
      flex: 1;
      flex-shrink: 0;
      position: relative;
      color: @color-light;

      b {
        display: block;
        overflow: hidden;
        color: @color-nor;
        white-space: nowrap;
      }

      &:not(:first-child) {
        &::before {
          content: ' ';
          overflow: hidden;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 1px;
          height: 2.57em;
          margin: auto;
          background: @color-lighten;
        }
      }
    }
  }

  .btn-follow {
    position: relative;
    z-index: 20;
  }

  .btn-message {
    display: block;
    line-height: 38px;
    text-align: center;
    background: #fff;
    border: 1px solid @color-lighten;
  }
}
</style>

import * as d from '@/core/dataGenerator'

export default class RichContentModel {
  static fromJson(data) {
    const model = new RichContentModel()
    model.type = d.gString(data.type)
    model.value = model.isImage
      ? `${data.value}!a-3-640x${data.value.substr(
          data.value.lastIndexOf('.')
        )}`
      : data.value

    if (data.childs) model.childs = data.childs

    model.attr = data.attr ? JSON.parse(data.attr) : {}

    if (model.attr?.needComment) {
      model.needCommentClass =
        model.value.length > 0
          ? 'show-need-comment-con'
          : 'hide-need-comment-con'
    }

    if (data.aid) {
      model.aid = d.gString(data.aid)
    }
    return model
  }

  get isImage() {
    return this.type === 'image'
  }

  get isText() {
    return this.type === 'text'
  }

  get isLink() {
    return this.type === 'link'
  }

  get isVideo() {
    return this.type === 'video'
  }

  get isIframe() {
    return this.type === 'iframe'
  }

  get isBlockText() {
    return this.attr.h || this.value === '\n'
  }
}

<template>
  <!-- <div v-if="loading">Loading</div> -->
  <header class="comment-hd">
    <div class="comment-tab">
      <div class="active">全部评论({{ commentsTotal }})</div>
    </div>
    <div class="operation-sort">
      <!-- <div class="operation-sort-btn">最早</div>
      <div class="operation-sort-popup">
        <a href="">最早</a>
        <a href="">最热</a>
      </div> -->
    </div>
  </header>
  <div class="comment-bd">
    <list-loader v-bind="states" @load="actions.load({ tid: props.tid })">
      <div class="comment">
        <comment-item
          v-for="item in items"
          :key="item.id"
          :data="item"
          :is-manager="isManager"
        ></comment-item>
      </div>
    </list-loader>
  </div>
</template>
<script setup>
import ListLoader from '@/components/ListLoader.vue'
import { commentListStore } from '@/store/comment'

const eventBus = inject('event-bus')

const props = defineProps({
  tid: {
    type: String,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  isManager: {
    type: Boolean,
    default: false,
  },
})

const { items, states, actions } = commentListStore()

onMounted(async () => {
  await actions.load({ tid: props.tid, platform: 'web' })
})

const commentsTotal = ref(props.total)

eventBus.on('addReply', () => {
  commentsTotal.value += 1
})
eventBus.on('removeReply', () => {
  commentsTotal.value -= 1
})
</script>

<style lang="less">
.comment {
  .comment-item-bd {
    margin-left: calc(32px + 0.7em);
  }
  .action-bar {
    span {
      cursor: pointer;
    }
  }
}
.comment-reply {
  margin-top: @gap;

  background: @bg-gray;
  border-radius: 6px;

  .comment-item {
    border-bottom-color: #e5e7ea;
  }

  .pagination {
    padding: 1em @gap 1em 60px;
    .em(14em);
    span {
      padding: 0 0.5em;
      color: @color-light;
      cursor: pointer;

      &.current {
        color: @color-nor;
        font-weight: 700;
        cursor: default;
      }
    }
  }
}
</style>

import qs from 'qs'

export default class PageInfo {
  constructor(config) {
    // 页面标题
    this.title = config.title
    // 页面地址
    this.path = config.path
    // // 页面组件实例
    // this.component = config.component
    // 父页面
    this.parent = config.parent
    this.needAuth = config.needAuth === true
    this.meta = config.meta
  }

  // 路由配置信息
  get routeInfo() {
    return { path: this.path }
  }

  // 返回指定参数后的完整跳转链接
  toLink(params, query) {
    let href = this.path
    href = href.replace(/\?/g, '')
    if (params) {
      Object.keys(params).forEach((key) => {
        href = href.replace(`:${key}`, params[key])
      })
    }

    // 移除未传值的参数
    href = href.replace(/\/:[a-z]*/g, '')
    if (query) {
      if (typeof query === 'string' && query.indexOf('#') > -1) {
        href += query
      } else {
        href += `?${qs.stringify(query)}`
      }
    }
    return href
  }

  // 跳转
  to(params) {
    const href = this.toLink(params)
    window.location.href = href
  }
}

<template>
  <a
    v-if="!data.$isDefaultFollow"
    class="btn-follow"
    :class="{ followed: isFollowed }"
    @click="onFollowClick"
    >{{ isFollowed ? '已订阅' : '订阅' }}
  </a>
</template>

<script setup>
import { groupAPI } from '@/api'
import { isLoggedIn, login, canComment } from '@/core/passport'
import { userGroupsStore } from '@/store/userGroupsStore'
import { toast } from './toast'

const eventBus = inject('event-bus')

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const isFollowed = ref(false)

watch(
  userGroupsStore.items,
  (groups) => {
    isFollowed.value = !!groups.find((g) => g.id === props.data.id)
  },
  {
    immediate: true,
  }
)

async function onFollowClick() {
  if (!isLoggedIn()) {
    login()
    return
  }
  if (canComment) {
    if (isFollowed.value) {
      // cancel follow
      isFollowed.value = false
      userGroupsStore.remove(props.data)
      await groupAPI.cancelFollowGroup(props.data.id).getRes()
      eventBus.emit('group:unfollow', props.data.id)
    } else {
      isFollowed.value = true
      userGroupsStore.add(props.data)
      await groupAPI.submitFollowGroup(props.data.id).getRes()
      eventBus.emit('group:follow', props.data.id)
    }
  } else {
    toast('被禁言')
  }
}
</script>

<template>
  <div
    ref="containerRef"
    :class="['subject-select', open ? 'show' : '']"
    @click="open = true"
  >
    <div class="input-txt field">
      <div v-for="it in displayTags" :key="it.id" class="tag" :title="it.label">
        <span>{{ it.label }}</span>
        <button @click.stop="() => remove(it.id)"></button>
      </div>
    </div>
    <Transition>
      <div v-if="open" class="dropdown">
        <div class="options">
          <div
            v-for="it in options"
            :key="it.id"
            class="option"
            :class="{
              active: isChecked(it.id),
            }"
          >
            <span
              ><input
                :id="'option_' + it.id"
                type="checkbox"
                :checked="isChecked(it.id)"
                @change="() => onOptionSelectChange(it.id)"
            /></span>
            <label :for="'option_' + it.id" class="label">{{ it.label }}</label>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { toast } from '@/components/toast'

const props = defineProps({
  /**
   * @type {{ number[] }}
   */
  modelValue: {
    type: Array,
    default: null,
  },
  /**
   * @type {{ { id, label }[] }}
   */
  options: {
    type: Array,
    default: null,
  },
})

console.log(props.modelValue)

const emit = defineEmits(['update:modelValue'])

const displayTags = computed(() =>
  props.modelValue?.map((it) => {
    return props.options?.find((o) => o.id === it)
  })
)

function remove(id) {
  emit(
    'update:modelValue',
    props.modelValue.filter((it) => it !== id)
  )
}

function add(id) {
  emit('update:modelValue', [...props.modelValue, id])
}

// Dropdown
const containerRef = ref()
const open = ref(!true)

onClickOutside(containerRef, () => {
  open.value = false
})

function isChecked(id) {
  return props.modelValue?.indexOf(id) > -1
}

function onOptionSelectChange(id) {
  if (isChecked(id)) {
    remove(id)
  } else {
    if (displayTags.value.length >= 5) {
      toast('最多添加5个话题哦~')
      return
    }
    add(id)
  }
}
</script>

<style lang="less">
.subject-select {
  position: relative;
  margin-bottom: 20px;

  &.show {
    .field {
      border-color: @color-light;
      box-shadow: 0 0 5px rgba(#000, 0.2);
    }
  }

  .field {
    display: flex;
    padding: 5px 0 0 5px;
  }

  .tag {
    display: flex;
    align-items: center;

    height: 38px;
    margin-right: 5px;
    padding: 0 10px;
    font-size: 14px;
    color: #6b6e79;
    background: @bg-gray;
    border-radius: 5px;
    cursor: default;

    &:hover {
      color: #ff9c00;
      background: #fffae5;

      button {
        background: #ffcd00;
      }
    }

    span {
      flex-shrink: 0;
      max-width: 9em;
      .text-ellipsis();
    }

    button {
      position: relative;
      width: 1.15em;
      height: 1.15em;
      margin-left: 5px;
      color: #fff;
      background: #d0d1d3;
      border: 0;
      border-radius: 50%;
      outline: 0;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 70%;
        height: 1px;
        margin: auto;
        background: currentColor;
      }

      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .dropdown {
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 210px;
    overflow-y: auto;
    margin-top: 9px;
    background: #fff;
    border: 1px solid @color-light;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(#000, 0.2);

    &::-webkit-scrollbar {
      width: 4px;
      background: #fff;
      transition: background ease 0.5s;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 2px;
    }

    &:hover {
      &::-webkit-scrollbar {
        width: 4px;
        background: #eee;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(#000, 0.2);
      }
    }

    .option {
      display: flex;
      align-items: center;
      padding: 0 20px;
      line-height: 42px;
      cursor: pointer;

      &:hover {
        background: #fffae5 !important;
      }
      &.active {
        background: @bg-gray;

        & > span {
          background: @color-yellow
            url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%2010%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M13%202.4S6.7%209%206.5%209.2c-.5.4-1.2.4-1.6%200L1.2%205.5c-.5-.5-.5-1.2%200-1.6.4-.4%201.2-.4%201.6%200l2.8%202.7L11.4.8c.4-.4%201.2-.4%201.6%200%20.4.4.4%201.1%200%201.6z%22%2F%3E%3C%2Fsvg%3E)
            no-repeat 45% 52% /80% 80%;
          border-color: @color-yellow;
        }
      }

      & > span {
        width: 1em;
        height: 1em;
        margin-right: 1em;
        border: 1px solid @bor-gray;
        border-radius: 4px;
      }

      input[type='checkbox'] {
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      label {
        width: 100%;
        .text-ellipsis();
        cursor: pointer;
      }
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="dialog dialog-report">
    <div class="dialog-in">
      <div class="dialog-hd">
        <div class="dialog-tit">举报</div>
        <a class="dialog-btn-close" @click="emits('close')">×</a>
      </div>
      <div class="dialog-bd">
        <template v-for="(item, index) in data.list" :key="index"
          ><div
            class="report-item"
            :class="{ active: index == selectedIndex }"
            @click="onReportClick(index, item)"
          >
            <span class="checkbox"
              ><svg-icon
                v-if="index == selectedIndex"
                class="svg-ico"
                name="checked"
              ></svg-icon
            ></span>
            {{ item }}
          </div></template
        >
        <div
          v-if="data.hasForm"
          :class="[
            'report-item input-item',
            data.list.length == selectedIndex ? 'active' : '',
          ]"
          @click="onReportClick(data.list.length, inputRef.value)"
        >
          <span class="checkbox"
            ><svg-icon
              v-if="data.list.length == selectedIndex"
              class="svg-ico"
              name="checked"
            ></svg-icon
          ></span>
          <div class="input-box">
            其他
            <textarea
              id="reason"
              ref="inputRef"
              name="reason"
              maxlength="200"
              rows="3"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="dialog-ft">
        <a class="dialog-btn-submit" @click="submit">确定</a>
        <a class="dialog-btn-cancel" @click="emits('close')">取消</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toast } from '@/components/toast'
import { otherAPI } from '@/api/index'

const props = defineProps({
  data: {
    type: Object,
    default: null,
  },
})

const emits = defineEmits(['close'])
const selectedIndex = ref(null)
const selectedVal = ref('')
const inputRef = ref('')

async function onReportClick(index, val) {
  selectedIndex.value = index
  selectedVal.value = val
}

async function submit() {
  if (props.data.hasForm && selectedIndex.value === props.data.list.length) {
    selectedVal.value = inputRef.value.value

    if (!selectedVal.value.length) {
      toast('请输入举报理由')
      return
    }
    if (selectedVal.value.length < 10) {
      toast('请输入至少10个字的理由')
      return
    }
    if (selectedVal.value.length > 200) {
      toast('举报理由最多200个字')
      return
    }
  }

  if (!(selectedIndex.value >= 0)) {
    toast('请选择举报理由')
    return
  }

  const params = {
    kind: props.data.kind,
    content: props.data.content,
    reason: selectedVal.value,
    reportedUid: props.data.reportedUid,
  }
  const res = await otherAPI.report(params).check()
  emits('close')
  toast(res.msg)
}
</script>

<style lang="less">
.dialog-report {
  .dialog-in {
    width: 420px;
  }
  .dialog-hd {
    padding: @gap @gap 0.2em;
    text-align: left;
    border-bottom: 1px solid @bor-gray;
  }
  .dialog-tit {
    text-align: center;
  }
  .dialog-bd {
    padding: @gap;
  }

  .dialog-ft {
    padding-bottom: @gap*3;

    display: flex;
    justify-content: center;

    a {
      width: 7em;
      .em(16em);
      line-height: 2.2;
      &:first-child {
        margin-right: @gap;
      }
    }
  }
}

.report-item {
  display: flex;
  align-items: center;
  padding: 0.2em 0;
  margin: 0.2em 0;
  cursor: pointer;

  .checkbox {
    flex-shrink: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 1em;
    height: 1em;
    margin-right: 0.3em;
    color: @color-light;
    border: 1px solid currentColor;
    border-radius: 50%;

    .svg-ico {
      width: 80%;
      height: 80%;
      fill: currentColor;
    }
  }

  &.active {
    color: @color-blue;
    .checkbox {
      color: currentColor;
    }
  }

  &.input-item {
    align-items: flex-start;
    .checkbox {
      margin-top: 0.2em;
    }
  }

  .input-box {
    width: 100%;
  }

  textarea {
    display: block;
    width: 90%;
    padding: 0.5em;
    color: @color-nor;
    border: 1px solid #dcdde0;
    border-radius: 6px;
    outline: 0;
    resize: vertical;
  }
}
</style>

<template>
  <div class="pub-platform">
    <app-link to="https://app.17173.com/" class="txt"
      >发自：一起一起上APP</app-link
    >
    <div class="tip"><img :src="qrcode" /></div>
  </div>
</template>

<script setup>
import { getAssets } from '@/core/util'

const qrcode = getAssets('images/qrcode.png')
</script>

<style lang="less">
.pub-platform {
  display: table;
  .em(14rem);

  .txt {
    color: @color-light;
  }

  .tip {
    display: none;
    position: absolute;
    z-index: @pub-platform-tip-zindex;

    width: 146px;
    height: 175px;
    margin-top: 10px;
    padding: 23px;
    background: #fff url(@/assets/images/qrcode-bg2.png) no-repeat center 18px;
    border-radius: 10px;
    filter: drop-shadow(0 2px 8px rgba(#000, 0.2));

    &::before {
      content: ' ';
      position: absolute;
      top: -5px;
      right: 0;
      left: 0;
      width: 10px;
      height: 10px;
      margin: auto;
      background: #fff;
      transform: rotate(45deg);
    }

    img {
      width: 100px;
      height: 100px;
    }
  }

  &:hover {
    .tip {
      display: block;
    }
  }
}
</style>

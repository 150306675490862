<template>
  <div class="wrap-404">
    <img :src="bg" alt="" />
    <p class="txt">Sorry，您要访问的页面不见了！</p>
    <app-link class="btn-back-home" :to="home.toLink()" target="self"
      >返回首页</app-link
    >
  </div>
</template>

<script setup>
import { getAssets } from '@/core/util'
import { home } from '@/core/pages'

const bg = getAssets('images/404.png')
</script>

<style lang="less">
.wrap-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 560px;

  .txt {
    margin: 1em 0 1.5em;
    .em(20rem);
  }
  .btn-back-home {
    width: 140px;
    line-height: 42px;
    text-align: center;

    background: #fff;
    border: 1px solid @color-black;
    border-radius: 4px;
  }
}
</style>

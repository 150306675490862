/*
 * 引入assets下的静态资源
 * @param {name} 静态资源地址(assets下路径+文件名, 如: 'images/logo.png')
 * @example
 * getAssets('images/logo.png')
 */
import debounce from 'lodash.debounce'

export const getAssets = (name) => {
  const path = `../assets/${name}`
  const modules = import.meta.globEager('../assets/*/*')
  return modules[path].default
}

export const getType = (o) => {
  const s = Object.prototype.toString.call(o)
  return s.match(/\[object (.*?)\]/)[1].toLowerCase()
}

export const emptyFn = () => {}

export const dateFormatNormal = (val, isYearOnly = false) => {
  const date = new Date(val.toString().length > 10 ? val : val * 1000)
  const y = date.getFullYear()
  const m =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const hh = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const mm =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()

  return isYearOnly ? `${y}-${m}-${d}` : `${y}-${m}-${d} ${hh}:${mm}`
}

export const dateFormat = (val) => {
  const time = val.toString().length > 10 ? val : val * 1000

  const diff = new Date().getTime() - time
  let text = ''

  if (diff < 0) {
    return dateFormatNormal(time)
  }

  const m = 60 * 1000
  const h = m * 60
  const d = h * 24

  const diffM = Math.floor(diff / m)
  const diffH = Math.floor(diff / h)
  const diffD = Math.floor(diff / d)

  if (diffD >= 7) {
    text = '7天前'
  } else if (diffH >= 24) {
    text = `${diffD}天前`
  } else if (diffH >= 1) {
    text = `${diffH}小时前`
  } else if (diffM > 5) {
    text = `${diffM}分钟前`
  } else {
    text = '刚刚'
  }

  return `${text}`
}

export const numberFormat = (val) => {
  if (val >= 10000) {
    return `${Math.round(val / 1000) / 10}万`
  }

  return val
}

export const sticky = (parent, el, time = 200) => {
  window.addEventListener(
    'scroll',
    debounce(() => {
      const top = document.documentElement.scrollTop || document.body.scrollTop

      const $parent = document.querySelector(parent)
      const $el = document.querySelector(el)
      if (top >= $parent.offsetTop) {
        if (!$el.classList.contains('fixed')) {
          $el.style.width = `${$parent.clientWidth}px`
          $el.classList.add('fixed')
          $el.style.top = document.querySelector('body').style.paddingTop || 0
        }
      } else if ($el.classList.contains('fixed')) {
        $el.classList.remove('fixed')
        $el.style.top = 'auto'
      }
    }, time)
  )
}

export const emoList = [
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/01.png',
    title: 'GD01',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/02.png',
    title: 'GD02',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/03.png',
    title: 'GD03',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/04.png',
    title: 'GD04',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/05.png',
    title: 'GD05',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/06.png',
    title: 'GD06',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/07.png',
    title: 'GD07',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/08.png',
    title: 'GD08',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/09.png',
    title: 'GD09',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/10.png',
    title: 'GD10',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/11.png',
    title: 'GD11',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/12.png',
    title: 'GD12',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/13.png',
    title: 'GD13',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/14.png',
    title: 'GD14',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/15.png',
    title: 'GD15',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/51.png',
    title: 'GD51',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/52.png',
    title: 'GD52',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/90.png',
    title: 'GD90',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/91.png',
    title: 'GD91',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/92.png',
    title: 'GD92',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/93.png',
    title: 'GD93',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/94.png',
    title: 'GD94',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/95.png',
    title: 'GD95',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/96.png',
    title: 'GD96',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/97.png',
    title: 'GD97',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/98.png',
    title: 'GD98',
  },
  {
    url: 'https://ue.17173cdn.com/a/lib/v4/1.3.24/comment/assets/img/attitude/99.png',
    title: 'GD99',
  },
]

function findEmoByCode(t) {
  return emoList.find((it) => it.title === t)
}
export const formatParagraph = (t) => {
  return t.replace(/\[(GD[0-9]{2})\]/g, (_, code) => {
    const found = findEmoByCode(code)
    return found ? `<img src=${found.url} class="emo-img">` : ''
  })
}

import * as d from '@/core/dataGenerator'

export default class GroupModel {
  static fromJson(data) {
    const model = new GroupModel()
    model.id = d.gString(data.gid)
    model.name = d.gString(data.name)
    model.logo = d.gString(data.icon).length
      ? `${d.gString(data.icon)}!a-3-240x${d
          .gString(data.icon)
          .substr(d.gString(data.icon).lastIndexOf('.'))}`
      : ''
    model.bgImage = d.gString(data.bgImage)
    model.lastThread = d.gNum(data.lastThread)
    model.threadNum = d.gNum(data.threadNum)
    model.followNum = d.gNum(data.follow || data.followNum)
    model.followState = d.gNum(data.followState)
    model.newGameCode = d.gNum(data.newGameCode)
    model.rule = d.gString(data.rule)
    model.official = d.gNum(data.official)
    model.headlines = d.gString(data.header)
    model.platform = d.gNum(data.platform || 0)

    // 是否有新帖
    model.$hasNew = false
    // 用于默认订阅的圈子，不能取订阅
    model.$isDefaultFollow = model.id === '1'

    return model
  }
}

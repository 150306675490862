import { threadAPI, subjectAPI } from '@/api'
import defineListStore from './defineListStore'
import defineDetailStore from './defineDetailStore'
import ThreadModel from '@/models/thread'

export const threadInfoStore = defineDetailStore({
  getMethod: threadAPI.getThreadInfo,
  dataParser: (data) => {
    const json = { ...data.threadInfo, ...data.groupInfo }
    const threadModel = ThreadModel.fromJson(json)
    return threadModel
  },
})

// 首页帖子列表
export const homeThreadListStore = defineListStore({
  getMethod: threadAPI.plazaList,
  dataParser: (items) => {
    return items.map((item) => {
      const threadModel = ThreadModel.fromJson(item)
      try {
        return threadModel
      } catch (e) {
        console.error(e)
      }
      return item
    })
  },
})

// 首页关注动态列表
export const homeDynamicListStore = defineListStore({
  getMethod: threadAPI.dynamicList,
  dataParser: (items) => {
    return items.map((item) => {
      const threadModel = ThreadModel.fromJson(item)
      try {
        return threadModel
      } catch (e) {
        console.error(e)
      }
      return item
    })
  },
})

// 首页关注动态列表
export const homeDigestListStore = defineListStore({
  getMethod: threadAPI.digestList,
  dataParser: (items) => {
    return items.map((item) => {
      const threadModel = ThreadModel.fromJson(item)
      try {
        return threadModel
      } catch (e) {
        console.error(e)
      }
      return item
    })
  },
})

// 圈子主页帖子列表
export const groupHomeThreadListStore = defineListStore({
  getMethod: threadAPI.groupHomeList,
  dataParser: (items) => {
    return items.map((item) => {
      const threadModel = ThreadModel.fromJson(item)
      try {
        return threadModel
      } catch (e) {
        console.error(e)
      }
      return item
    })
  },
})

// 用户帖子列表
export const userThreadListStore = defineListStore({
  getMethod: threadAPI.getUserThreadList,
  dataParser: (items) => {
    return items.map((item) => {
      const threadModel = ThreadModel.fromJson(item)
      try {
        return threadModel
      } catch (e) {
        console.error(e)
      }
      return item
    })
  },
})

// 话题帖子列表
export const subjectThreadListStore = defineListStore({
  getMethod: subjectAPI.getSubjectThreadList,
  dataParser: (items) => {
    return items.map((item) => {
      const threadModel = ThreadModel.fromJson(item)
      try {
        return threadModel
      } catch (e) {
        console.error(e)
      }
      return item
    })
  },
})

import mitt from 'mitt'
import App from './App.vue'
import routes from './routes'
import svgIcon from './components/SvgIcon.vue'
import pages from './pages'
import { trackModule, trackClick } from './directives/bi'

const app = createApp(App)

app.use(routes).component('svg-icon', svgIcon)

app.provide('pages', pages)
app.directive('trackModule', trackModule)
app.directive('trackClick', trackClick)

const eventBus = mitt()
eventBus.on('*', (...args) => console.debug(args))
app.provide('event-bus', eventBus)

app.mount('#app')

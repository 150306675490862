import { defineStore } from './defineStore'
/*
 *定义详情store
 */
export default function defineDetailStore(config) {
  return defineStore(() => {
    const data = ref(null)
    const loading = ref(false)
    const check = ref(null)

    async function load(params, option = {}) {
      const targetOption = {
        ...option,
        ...{
          limitTime: 0,
        },
      }

      if (loading.value) return

      loading.value = true
      const beginTime = Date.now()

      const instance = await config.getMethod({
        ...params,
      })
      const checkStatus = await instance.check()
      if (checkStatus.success) {
        const resData = await instance.data()
        data.value =
          typeof config.dataParser === 'function'
            ? config.dataParser(resData || [])
            : resData || {}

        const duration = Date.now() - beginTime
        const delay =
          duration > targetOption.limitTime
            ? 0
            : targetOption.limitTime - duration

        if (delay > 0) {
          setTimeout(() => {
            loading.value = false
          }, delay)
        } else {
          loading.value = false
        }
      } else {
        data.value = {}
        check.value = checkStatus
        loading.value = false
      }
    }

    function reset() {
      data.value = {}
      check.value = {}
      loading.value = false
    }

    return {
      check,
      data,
      loading,
      actions: {
        load,
        reset,
      },
    }
  })
}

<template>
  <div class="main-wide">
    <div class="crumb">
      <app-link :to="home.toLink()">首页</app-link
      ><app-link :to="user.toLink({ id })">{{ userInfo.nickname }}</app-link
      >编辑个人资料
    </div>
    <section class="comm-mod mod-profile">
      <div class="bd">
        <div class="avatar">
          <img :src="getAvatar()" alt="" />
          <input
            v-if="userInfo.avatarSet"
            type="file"
            @change="onAvatarChange"
          />
        </div>
        <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <th>昵称</th>
              <td>
                <span>{{ nickname }}</span
                ><a
                  v-if="userInfo.nicknameSet"
                  href="javascript:;"
                  class="btn-edit"
                  @click="changeNickname"
                  ><svg-icon name="publish"></svg-icon
                ></a>
              </td>
            </tr>
            <tr>
              <th>个性签名</th>
              <td>
                <span v-if="userInfo.signature">{{ userInfo.signature }}</span
                ><a
                  v-if="userInfo.signatureSet"
                  href="javascript:;"
                  class="btn-edit"
                  @click="signatureEditVisible = true"
                  ><svg-icon name="publish"></svg-icon
                ></a>
              </td>
            </tr>
            <tr>
              <th>性别</th>
              <td>
                <span>{{
                  userInfo.gender === 0
                    ? '保密'
                    : userInfo.gender === 1
                    ? '男'
                    : '女'
                }}</span
                ><a
                  href="javascript:;"
                  class="btn-edit"
                  @click="genderEditVisible = true"
                  ><svg-icon name="publish"></svg-icon
                ></a>
              </td>
            </tr>
            <tr>
              <th>当前账号</th>
              <td>{{ getName() }}</td>
            </tr>
            <tr>
              <th>密码</th>
              <td>
                <app-link to="https://passport.17173.com/password/update"
                  >修改密码&gt;&gt;</app-link
                >
              </td>
            </tr>
            <tr>
              <th>密保状态</th>
              <td class="links">
                <app-link to="http://passport.17173.com/safe/mobile/index"
                  >手机绑定</app-link
                >
                <app-link to="http://passport.17173.com/safe/qq/index"
                  >QQ绑定</app-link
                >
                <app-link to="http://passport.17173.com/safe/email/index"
                  >邮箱绑定</app-link
                >
                <app-link to="http://passport.17173.com/safe/question/index"
                  >密保问题</app-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
  <app-fixed-bar></app-fixed-bar>
  <signature-dialog
    v-show="signatureEditVisible"
    v-model="userInfo.signature"
    @close="signatureEditVisible = false"
  ></signature-dialog>
  <gender-dialog
    v-show="genderEditVisible"
    v-model="userInfo.gender"
    @close="genderEditVisible = false"
  ></gender-dialog>
  <avatar-dialog
    v-if="avatarEditVisible"
    v-model="avatarEditData"
    @close="avatarEditVisible = false"
  ></avatar-dialog>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { home, user } from '@/core/pages'
import { userAPI } from '@/api'
import { getName, getUname, getAvatar } from '@/core/passport'

import SvgIcon from '@/components/SvgIcon.vue'

import AvatarDialog from './components/AvatarDialog.vue'
import GenderDialog from './components/GenderDialog.vue'
import SignatureDialog from './components/SignatureDialog.vue'

const { id } = useRoute().params
const userInfo = ref({})
const nickname = ref('')

async function init() {
  nickname.value = getUname()

  userInfo.value = await userAPI.getMyInfo().data()
}
init()

const changeNickname = () => {
  Passport.Dialog.showNameUpdater()
}
Passport.on(Passport.EVENTS.dialogClose, (e) => {
  if (!e.byUser && e.dialogType === 'nameEdit') {
    nickname.value = getUname()
  }
})
const signatureEditVisible = ref(false)
const genderEditVisible = ref(false)

const avatarEditVisible = ref(false)
const avatarEditData = ref(null)
function onAvatarChange(e) {
  const file = e.target.files[0]
  const reader = new FileReader()
  reader.addEventListener('load', () => {
    avatarEditData.value = reader.result
    avatarEditVisible.value = true
  })
  reader.readAsDataURL(file)
}
</script>

<style lang="less">
.mod-profile {
  padding: 50px 0;
  .avatar {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto 20px;
    object-fit: contain;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 32px;
      height: 31px;
      background: url(@/assets/images/ico-camera.png) no-repeat;
    }
    input {
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }

  table {
    table-layout: fixed;
    width: 480px;
    margin: 0 auto;

    th,
    td {
      padding-top: @gap;
    }

    th {
      width: 100px;
      padding-right: @gap;
      color: @color-light;
      text-align: right;
      font-weight: normal;
    }
  }

  .links {
    a {
      margin-right: @gap;
      color: @color-orange;
    }
  }

  .btn-edit {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 17px;
    height: 17px;
    vertical-align: middle;

    &:not(:first-child) {
      margin-left: 0.5em;
    }

    .svg-ico {
      width: 100%;
      height: 100%;
    }
  }
}
.dialog-profile {
  &:not(.dialog-crop) {
    .dialog-in {
      width: 440px;
    }
    .dialog-bd {
      padding: @gap*3 15% @gap*1.5;
    }
  }

  .dialog-hd {
    padding: @gap @gap 0.2em;
    text-align: left;
    border-bottom: 1px solid @bor-gray;
  }
  .dialog-tit {
    font-size: inherit;
    font-weight: normal;
  }

  .dialog-ft {
    padding-bottom: @gap*3;

    display: flex;
    justify-content: center;

    a {
      width: 7em;
      .em(16em);
      line-height: 2.2;
      &:first-child {
        margin-right: @gap;
      }
    }
  }
}
.dialog-profile-form-item {
  display: flex;
  justify-content: space-between;

  .tit {
    flex-shrink: 0;
    margin-right: @gap;
    line-height: 2.5;
  }
  .con {
    flex: 1;
  }

  .input-txt {
    width: 100%;
    height: 2.5em;
    padding: 0 0.5em;
    border: 1px solid @bor-gray;
    border-radius: 2px;
  }
  textarea.input-txt {
    height: 5em;
    padding-top: 0.2em;
    line-height: 1.5em;
    outline: 0;
    resize: none;
  }

  input[type='radio'] {
    cursor: pointer;
  }

  label {
    line-height: 2.5;
    margin-left: 0.2em;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 2em;
    }
  }

  .note {
    margin-top: 0.5em;
    font-size: 0.825em;
    color: @color-light;
  }
}
</style>

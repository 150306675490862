<template>
  <div>
    <!-- 组件状态示例 -->
    <h3>{{ desc }}</h3>
    <!-- 列表示例 -->
    <ol v-if="!homeThreadList.states.empty.value">
      <li
        v-for="item in homeThreadList.items.value"
        :key="item.id"
        :data-id="item.id"
      >
        {{ item.id }} : {{ item.title }}
      </li>
    </ol>
    <div v-else>没有数据</div>
    <!-- 图片示例/API调用示例 -->
    <img :src="logo" alt="" srcset="" @click="homeThreadList.actions.load" />
    <div v-if="homeThreadList.states.loading.value">加载中...</div>
    <!-- 链接示例 -->
    <p>
      <app-link :to="home.toLink({ a: 1, b: 2 })" target="self"
        >链接到</app-link
      >
    </p>
    <p>
      <app-link :to="sample.toLink({ id: 11, cc: 22 })" target="self"
        >普通的a链接示例</app-link
      >
    </p>
    <editor ref="editorRef"></editor>
    <button type="button" class="ql-clean" title="getContent" @click="save">
      保存
    </button>
    <button type="button" class="ql-clean" title="loadContent" @click="load">
      加载
    </button>
  </div>
</template>

<script setup>
import { homeThreadListStore } from '@/store/group'
import { getAssets } from '@/core/util'
import { home } from '@/core/pages'

const logo = getAssets('images/logo.png')
const homeThreadList = homeThreadListStore()
// const allGroupList = allGroupsStore()

// 组件状态示例
const desc = ref('state示例')

const editorRef = ref(null)
const save = () => {
  console.log(editorRef.value.getContent())
}

const load = () => {
  const contents = [
    {
      type: 'text',
      value:
        '就打电话好的回复好的好的好的好的很大很大很大还发还发还发复合肥还好好的很符合还发\n不对不对不符合发货发不发吧八点半对不对不方便\n',
      attr: '{}',
    },
    {
      type: 'image',
      value:
        'http://i1.cdn.test.17173.com/gdthue/YWxqaGBf/haoApp/20210623/pSWbmQbpiCctsoy.jpg',
      attr: '{"height":"1536"}',
      aid: '47',
    },
    {
      type: 'text',
      value: '\n不懂好的好的好多好多话还发回复好的好的不大好大粑粑\n',
      attr: '{}',
    },
    {
      type: 'video',
      value: '567608184043380736',
      attr: '{"videoId":"567608184043380736"}',
      aid: '48',
    },
  ]
  editorRef.value.loadContents(contents)
}
// const init = async () => {
//   await allGroupList.actions.load()
//   await homeThreadList.actions.load({ foo: 1 })
//   console.log(homeThreadList)
// }
// init()
</script>

<style scoped>
/*  */
</style>

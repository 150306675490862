<template>
  <div v-if="data.$visible" class="thread-item">
    <div class="thread-item-hd">
      <user-card :data="data"></user-card>
      <div class="time">{{ dateFormat(data.createDate) }}</div>
      <app-link
        v-if="data.groupName"
        :to="group.toLink({ id: data.gid })"
        class="game"
        >{{ data.groupName }}</app-link
      >
    </div>
    <div v-if="data.content.length" class="thread-item-bd">
      <span v-if="data.orginReplyAuthor" class="reply-orgin"
        >回复<app-link :to="user.toLink({ id: data.orginReplyAuthorUid })"
          >@{{ data.orginReplyAuthor }}</app-link
        >：</span
      >
      <app-link v-if="data.orginExist" :to="thread.toLink({ id: data.tid })">
        <div class="con">
          <template v-for="(item, index) in data.richContents" :key="index">
            <paragraph-text
              v-if="item.isText"
              :data="item"
              :is-list="true"
            ></paragraph-text>
          </template>
        </div>
      </app-link>
      <div v-else class="con">
        <template v-for="(item, index) in data.richContents" :key="index">
          <paragraph-text
            v-if="item.isText"
            :data="item"
            :is-list="true"
          ></paragraph-text>
        </template>
      </div>
      <div v-if="data.images.length" class="img-box">
        <figure
          v-for="(item, index) in data.images.slice(0, 4)"
          :key="index"
          class="pic"
        >
          <ImagesViewer :item="item" :data="data.images"></ImagesViewer>
        </figure>
      </div>
      <div class="comment-reply">
        <app-link v-if="data.orginExist" :to="thread.toLink({ id: data.tid })">
          <template v-if="data.orginReplyAuthor">
            <p v-if="data.orginReplyContent">{{ data.orginReplyContent }}</p>
            <p v-else>原贴已删</p>
          </template>
          <template v-else>
            <p v-if="data.orginThreadTitle" class="tit">
              {{ data.orginThreadTitle }}
            </p>
            <p v-if="data.orginThreadContent">{{ data.orginThreadContent }}</p>
            <p v-else>原贴已删</p>
          </template>
        </app-link>
        <template v-else>
          <template v-if="data.orginReplyAuthor">
            <p v-if="data.orginReplyContent">{{ data.orginReplyContent }}</p>
            <p v-else>原贴已删</p>
          </template>
          <template v-else>
            <p v-if="data.orginThreadTitle" class="tit">
              {{ data.orginThreadTitle }}
            </p>
            <p v-if="data.orginThreadContent">{{ data.orginThreadContent }}</p>
            <p v-else>原贴已删</p>
          </template>
        </template>
      </div>
      <div class="action-bar">
        <span class="view"
          ><svg-icon name="view"></svg-icon
          >{{ data.views > 0 ? numberFormat(data.views) : '' }}</span
        >
        <app-link
          v-if="data.orginExist"
          :to="thread.toLink({ id: data.tid }, '#comment')"
          class="reply"
          ><svg-icon name="comment"></svg-icon
          >{{ data.replies > 0 ? numberFormat(data.replies) : '' }}</app-link
        >
        <span v-else class="reply"
          ><svg-icon name="comment"></svg-icon
          >{{ data.replies > 0 ? numberFormat(data.replies) : '' }}</span
        >
        <span class="bump" @click.stop.prevent="postAuthCheck(bump)"
          ><svg-icon
            :name="[data.hasSupported ? 'bump-active' : 'bump']"
          ></svg-icon
          >{{ data.supports > 0 ? numberFormat(data.supports) : '' }}</span
        >
      </div>
    </div>
    <div v-else class="thread-item-bd">
      <div class="orgin-del">评论已删除</div>
    </div>
  </div>
</template>

<script setup>
import { thread, group, user } from '@/core/pages'
import { threadAPI } from '@/api'
import { dateFormat, numberFormat } from '@/core/util'
import { postAuthCheck } from '@/core/passport'
import { toast } from '@/components/toast'

const eventBus = inject('event-bus')

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
})

defineEmits(['remove', 'bump'])

const delayRender = ref(false)
onMounted(() => {
  setTimeout(() => {
    delayRender.value = true
  })
})

async function bump() {
  const { data } = props
  const id = data.ppid ? data.ppid : data.id
  const res = threadAPI.supportComment(id)
  const status = await res.check()
  if (status.success) {
    const d = await res.data()
    data.supports = d.totalCount
    data.hasSupported = d.supportStatus

    if (data.hasSupported) {
      eventBus.emit('bump:up', data.authorUid)
    } else {
      eventBus.emit('bump:cancel', data.authorUid)
    }
  } else {
    toast(status.msg)
  }
}
</script>

<style lang="less">
.thread-item {
  .reply-orgin {
    display: inline-flex;
    a {
      color: @color-orange;
    }
    & + a,
    & ~ .con {
      display: inline-flex;
    }
  }
  .img-box {
    clear: both;
  }
  .comment-reply {
    clear: both;
    padding: @gap;
    .em(14em);
    color: @color-light;

    a,
    .tit {
      color: currentColor;
    }

    .tit {
      margin-bottom: 0.5em;
    }

    p {
      .text-ellipsis();
    }
  }

  .orgin-del {
    color: @color-light;
  }
}
</style>

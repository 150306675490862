<template>
  <!-- eslint-disable vue/no-v-html -->
  <template v-if="isDesc">
    <p :class="data.needCommentClass" v-html="paragraphHtml(data)"></p>
  </template>
  <template v-else-if="!data.childs">
    <component
      :is="tagName"
      :class="data.needCommentClass"
      v-html="paragraphHtml(data)"
    ></component>
  </template>
  <p v-else :class="data.needCommentClass">
    <template v-for="(item, index) in data.childs" :key="index"
      ><span v-html="paragraphHtml(item)"></span>
    </template>
  </p>
  <p
    v-if="!data.value.length && data.needCommentClass"
    :class="data.needCommentClass"
  ></p>
</template>
<script setup>
import { formatParagraph } from '../core/util'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  isList: {
    type: Boolean,
    default: false,
  },
  // 为true,则忽略文本的格式,直接输出无格式文本
  isDesc: {
    type: Boolean,
    default: true,
  },
})

const tagName =
  props.data.attr && props.data.attr.h ? `h${props.data.attr.h}` : 'p'
// console.log({ ...props.data }, tagName)
// if (props.data.childs) {
//   console.log('-----')
//   props.data.childs.forEach((t) => {
//     console.log(t.value === '\n')
//   })
// }

function paragraphHtml(item) {
  let html = ''
  if (item.value === '\n') {
    html = '&nbsp;'
  } else if (props.isList && item.value.length > 70) {
    html = `${formatParagraph(item.value)}...`
  } else {
    html = formatParagraph(item.value)
  }
  if (item.attr.bold) {
    html = `<b>${html}</b>`
  }
  return html
}
</script>

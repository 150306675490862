<template>
  <div class="main-wide">
    <group-visited />
    <div class="comm-mod">
      <div class="thread-hd">
        <div class="thread-tab">
          <div
            v-if="isLoggedIn()"
            class="item"
            :class="{ active: queryType == 'my' }"
            @click="tab('my')"
          >
            关注
          </div>
          <div
            class="item"
            :class="{ active: queryType == 'all' }"
            @click="tab('all')"
          >
            广场
          </div>
        </div>
      </div>
      <div class="thread-bd">
        <div v-if="queryType == 'my' && !hasFollow" class="no-follow-tip">
          暂无关注的人，先看看精华帖吧~
        </div>
        <list-loader
          v-if="initialized"
          v-bind="
            queryType == 'my'
              ? hasFollow
                ? myStates
                : digestStates
              : threadStates
          "
          @load="
            queryType == 'my'
              ? hasFollow
                ? myActions.load()
                : digestActions.load()
              : threadActions.load()
          "
        >
          <div v-if="queryType == 'my' && isLoggedIn()" class="thread-list">
            <thread-item
              v-for="item in hasFollow ? myItems : digestItems"
              :key="item.id"
              :data="item"
            ></thread-item>
          </div>
          <div v-else class="thread-list">
            <thread-item
              v-for="(item, index) in threadItems"
              :key="item.id"
              :data="item"
              :index="index"
            ></thread-item>
          </div>
        </list-loader>
      </div>
    </div>
  </div>
  <app-fixed-bar :id="{}"></app-fixed-bar>
</template>

<script setup>
import { otherAPI } from '@/api'
import { sticky } from '@/core/util'
import { getUid, isLoggedIn } from '@/core/passport'
import GroupVisited from './components/GroupVisited.vue'
import {
  homeThreadListStore,
  homeDynamicListStore,
  homeDigestListStore,
} from '@/store/group'
import ListLoader from '@/components/ListLoader.vue'
import { userFollowsStore } from '@/store/userFollowsStore'

const queryType = isLoggedIn() ? ref('my') : ref('all') // all/my

const storageKey = isLoggedIn() ? `home-tab-${getUid()}` : null

if (localStorage.getItem(storageKey)) {
  queryType.value = localStorage.getItem(storageKey)
}

const initialized = ref(false)
const hasFollow = ref(false)

const {
  items: threadItems,
  states: threadStates,
  actions: threadActions,
} = homeThreadListStore()

const {
  items: myItems,
  states: myStates,
  actions: myActions,
} = homeDynamicListStore()

const {
  items: digestItems,
  states: digestStates,
  actions: digestActions,
} = homeDigestListStore()

document.title = '一起一起上游戏圈_硬核游戏玩家聚集地_17173.com中国游戏门户站'

// 首页置灰设置
const initStatus = async () => {
  const res = await otherAPI.initStatus().data()
  if (res.indexGray && res.indexGray === 1) {
    document.querySelector('html').classList.add('gray')
  }
}

initStatus()

onMounted(() => {
  sticky('.thread-hd', '.thread-tab')
})

if (!isLoggedIn()) {
  initialized.value = true
} else {
  watch(
    userFollowsStore.loading,
    () => {
      if (userFollowsStore.loading.value) return

      initialized.value = true

      hasFollow.value = !!userFollowsStore.items.value[0]
    },
    {
      immediate: true,
    }
  )
}

function tab(type) {
  if (isLoggedIn()) {
    queryType.value = type
    localStorage.setItem(storageKey, type)
  }
}

Passport.on(Passport.EVENTS.loginSuccess, async () => {
  if (await userAPI.login().check()) {
    window.location.reload()
  }
})
</script>

<style lang="less">
.thread-list {
  .mod(padding-bottom);

  .btn-list-more {
    margin-bottom: -@gap;
  }
}
.no-follow-tip {
  margin: 50px 0;
  .tip-ico();
}
.gray {
  filter: grayscale(1);
}
</style>

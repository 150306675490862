import instance from './instance'
import APIResponse from './APIResponse'

// 帖子相关接口
export const threadAPI = {
  // 首页帖子列表
  plazaList(params) {
    const axiosRes = instance.get('/group/plaza-list', { params })
    return new APIResponse(axiosRes)
  },
  dynamicList(params) {
    const axiosRes = instance.get('/dynamic/thread-list', { params })
    return new APIResponse(axiosRes)
  },
  digestList(params) {
    const axiosRes = instance.get('/thread/digest-list', { params })
    return new APIResponse(axiosRes)
  },
  submitSupportThread(id) {
    const axiosRes = instance.get('/thread/support', { params: { tid: id } })
    return new APIResponse(axiosRes)
  },
  delThread(id) {
    const axiosRes = instance.get('/thread/del', { params: { tid: id } })
    return new APIResponse(axiosRes)
  },
  groupHomeList(params) {
    const axiosRes = instance.get('/group/thread-list', { params })
    return new APIResponse(axiosRes)
  },
  getUserThreadList(params) {
    const axiosRes = instance.get('/home/thread', { params })
    return new APIResponse(axiosRes)
  },
  getUserReplyList(params) {
    const axiosRes = instance.get('/home/post', { params })
    return new APIResponse(axiosRes)
  },
  // 评论列表
  getCommentList(params) {
    const axiosRes = instance.get('/thread/post-list', { params })
    return new APIResponse(axiosRes)
  },
  // 评论的回复列表
  getReplyList(params) {
    const axiosRes = instance.get('/thread/reply-list', { params })
    return new APIResponse(axiosRes)
  },
  getThreadInfo(params) {
    const axiosRes = instance.get('/thread/info', { params })
    return new APIResponse(axiosRes)
  },
  // 发表回帖/回复
  postComment(params) {
    const data = new FormData()
    data.append('tid', params.tid)
    data.append('message', params.message)
    data.append('platform', 'web')
    if (params.pid) data.append('pid', params.pid)
    if (params.aid) data.append('attachQuote', params.aid)
    const axiosRes = instance.post('/thread/reply', data)
    return new APIResponse(axiosRes)
  },
  // 发帖&编辑帖子
  postThread(params) {
    const data = new FormData()
    data.append('gid', params.gid)
    data.append('subId', params.sid)
    data.append('subject', params.title)
    data.append('message', params.content)
    data.append('searchKeywords', params.searchKeywords)
    data.append('platform', 'web')
    if (params.id) data.append('tid', params.id)
    const axiosRes = instance.post('/thread/issue', data)
    return new APIResponse(axiosRes)
  },
  supportComment(id) {
    const axiosRes = instance.get('/thread/post-support', {
      params: { pid: id },
    })
    return new APIResponse(axiosRes)
  },
  delComment(id) {
    const axiosRes = instance.get('/thread/post-del', {
      params: { pid: id },
    })
    return new APIResponse(axiosRes)
  },
  hideThread(id) {
    const axiosRes = instance.get('/thread/hide', {
      params: { tid: id },
    })
    return new APIResponse(axiosRes)
  },
  digestThread(id) {
    const axiosRes = instance.get('/thread/digest', {
      params: { tid: id },
    })
    return new APIResponse(axiosRes)
  },
}

export const groupAPI = {
  selectRecGroup(params) {
    const axiosRes = instance.get('/group/rec-list', { params })
    return new APIResponse(axiosRes)
  },
  allGroups() {
    const axiosRes = instance.get('/group/list')
    return new APIResponse(axiosRes)
  },
  getSections(id) {
    const axiosRes = instance.get('/group/sub-list', {
      params: { gid: id },
    })
    return new APIResponse(axiosRes)
  },
  getMyGroupList() {
    const axiosRes = instance.get('/follow/group-list')
    return new APIResponse(axiosRes)
  },
  getHotGroupList(params) {
    const axiosRes = instance.get('/group/rec-list', { params })
    return new APIResponse(axiosRes)
  },
  submitFollowGroup(id) {
    const axiosRes = instance.get('/follow/group', { params: { gid: id } })
    return new APIResponse(axiosRes)
  },
  cancelFollowGroup(id) {
    const axiosRes = instance.get('/follow/group-cancel', {
      params: { gid: id },
    })
    return new APIResponse(axiosRes)
  },
  groupFinal(id) {
    const axiosRes = instance.get('/group/info', {
      params: { gid: id },
    })
    return new APIResponse(axiosRes)
  },
  hotThread(id) {
    const axiosRes = instance.get('/group/hot-thread', { params: { gid: id } })
    return new APIResponse(axiosRes)
  },
}

export const editorAPI = {
  getLinkInfo(href) {
    const axiosRes = instance.get('/app/get-title-by-url', {
      params: { url: href },
    })
    return new APIResponse(axiosRes)
  },
  get17173VideoInfo(href) {
    const axiosRes = instance.get('/app/get-video-info-by-url', {
      params: { vurl: href },
    })
    return new APIResponse(axiosRes)
  },
  async upLoadImage(file, name) {
    const data = new FormData()
    data.append('pic[0]', file, name)

    const axiosRes = instance.post('/upload/image', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return new APIResponse(axiosRes)
  },
  getQiniuToken() {
    const axiosRes = instance.get('/upload/get-video-token')
    return new APIResponse(axiosRes)
  },
  qiniuUploadReceipt(params) {
    const axiosRes = instance.get('/upload/confirm-video-info', {
      params,
    })
    return new APIResponse(axiosRes)
  },
}

export const userAPI = {
  login() {
    const axiosRes = instance.get('/my/login')
    return new APIResponse(axiosRes)
  },
  logout() {
    const axiosRes = instance.get('/my/logout')
    return new APIResponse(axiosRes)
  },
  getAccountStatus() {
    const axiosRes = instance.get('/my/get-status')
    return new APIResponse(axiosRes)
  },
  getMyInfo() {
    const axiosRes = instance.get('/my/info')
    return new APIResponse(axiosRes)
  },
  getUserInfo(id) {
    const axiosRes = instance.get('/home/info', { params: { uuid: id } })
    return new APIResponse(axiosRes)
  },
  getUserFollowList(id) {
    const axiosRes = instance.get('/follow/user-list', { params: { uuid: id } })
    return new APIResponse(axiosRes)
  },
  getUserFansList(id) {
    const axiosRes = instance.get('/follow/fans-list', { params: { uuid: id } })
    return new APIResponse(axiosRes)
  },
  submitFollowUser(id) {
    const axiosRes = instance.get('/follow/user', { params: { uid: id } })
    return new APIResponse(axiosRes)
  },
  cancelFollowUser(id) {
    const axiosRes = instance.get('/follow/user-cancel', {
      params: { uid: id },
    })
    return new APIResponse(axiosRes)
  },
  getGroupManagers(id) {
    const axiosRes = instance.get('/group/manager', {
      params: { gid: id },
    })
    return new APIResponse(axiosRes)
  },
  getNotification(params) {
    const axiosRes = instance.get('/my/message', { params })
    return new APIResponse(axiosRes)
  },
  submitSignature(val) {
    const axiosRes = instance.get('/my/signature', {
      params: {
        signature: val,
      },
    })
    return new APIResponse(axiosRes)
  },
  submitGender(val) {
    const axiosRes = instance.get('/my/gender', {
      params: {
        gender: val,
      },
    })
    return new APIResponse(axiosRes)
  },
  getBadgeList(params) {
    const axiosRes = instance.get('/home/badge', { params })
    return new APIResponse(axiosRes)
  },
  getRecommdUsers(params) {
    const axiosRes = instance.get('/group/active-user', { params })
    return new APIResponse(axiosRes)
  },
}

export const subjectAPI = {
  getSubjectList() {
    const axiosRes = instance.get('/keyword/search', {
      params: { pageSize: 30 },
    })
    return new APIResponse(axiosRes)
  },
  getSubjectInfo(id) {
    const axiosRes = instance.get('/keyword/info', { params: { id } })
    return new APIResponse(axiosRes)
  },
  getSubjectThreadList(params) {
    const axiosRes = instance.get('/thread/search', {
      params: { ...params, type: 'subject' },
    })
    return new APIResponse(axiosRes)
  },
}

export const otherAPI = {
  initStatus() {
    const axiosRes = instance.get('/app/info')
    return new APIResponse(axiosRes)
  },
  report(params) {
    const axiosRes = instance.get('/report/submit', { params })
    return new APIResponse(axiosRes)
  },
}

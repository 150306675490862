<template>
  <div class="list-group">
    <div v-for="(item, index) in data" :key="index" class="group-item">
      <app-link :to="user.toLink({ id: item.uid })" class="con">
        <figure class="pic">
          <img :src="getAvatar(item.uid)" alt="" />
        </figure>
        <div class="txt">
          <p class="name">{{ item.nickname }}</p>
          <p v-if="item.userTitle" class="other">
            <span>{{ item.userTitle }}</span>
          </p>
          <p v-else-if="item.recommend" class="other">
            <span>{{ item.recommend }}</span>
          </p>
        </div>
      </app-link>
      <div class="btn">
        <user-follow-btn :data="item"></user-follow-btn>
      </div>
    </div>
  </div>
</template>

<script setup>
import { user } from '@/core/pages'
import { getAvatar } from '@/core/passport'

const props = defineProps({
  data: {
    type: Object,
    default: null,
  },
})

watch(
  () => props.data,
  () => {},
  { immediate: true }
)
</script>

<template>
  <div
    v-if="recommdUserDate"
    class="mod-recommd"
    :class="gid ? 'group-recommd' : ''"
  >
    <AppUserList :data="recommdUserDate"></AppUserList>
    <a
      v-if="recommdUserDate.length == 4"
      href="javascript:;"
      class="btn-change"
      @click="loadData"
      >换一批</a
    >
  </div>
</template>

<script setup>
import { userAPI } from '@/api'

const props = defineProps({
  gid: {
    type: String,
    default: null,
  },
})

const recommdUserDate = ref(null)

const params = props.gid ? { gid: props.gid } : ''

async function loadData() {
  recommdUserDate.value = await userAPI.getRecommdUsers(params).data()
}

loadData()
</script>

<style lang="less">
.mod-recommd {
  padding-bottom: @gap;
  border-top: @gap solid #f5f6f8;
  border-bottom: @gap solid #f5f6f8;

  .list-group {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;

    .group-item {
      flex-shrink: 0;
      width: 38%;
      margin-left: 8%;
    }
  }
  &.group-recommd {
    .group-item {
      width: 42.5%;
      margin-left: 5%;
    }
  }
  .btn-change {
    display: table;
    margin: @gap auto 0;
    padding-left: 24px;
    font-size: 0.875em;
    color: @color-light;
    background: url(@/assets/images/ico-change.png) no-repeat 0 50%;
  }
}
</style>

<template>
  <div v-if="visible" class="dialog dialog-game">
    <div class="dialog-in">
      <div class="dialog-hd">请选择你关注的游戏</div>
      <div class="dialog-bd">
        <div v-if="loading">Loading</div>
        <div class="dialog-game-list">
          <div v-for="item in items" :key="item.id" class="item">
            <input
              :id="'g' + item.id"
              v-model="checked"
              type="checkbox"
              :value="item.id"
              :disabled="item.$isDefaultFollow"
            />
            <label :for="'g' + item.id"
              ><figure class="pic">
                <group-avatar :data="item.logo"></group-avatar>
              </figure>
              <p class="txt">{{ item.name }}</p>
              <p class="check-box">
                <svg-icon name="checked"></svg-icon></p
            ></label>
          </div>
        </div>
        <div
          class="dialog-btn-submit"
          :class="{ disabled: !checked.length }"
          @click="onSumbit"
        >
          选好了
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { groupAPI } from '@/api'
import useVisitedGroups from '@/hooks/useVisitedGroups'
import { userGroupsStore } from '@/store/userGroupsStore'
import GroupModel from '@/models/group'

const { groups: visitedGroups } = useVisitedGroups()

// 判断是否第一次访问：圈子访问记录 storage 空 && 订阅的圈子<1（原为<=1）
const firstVisite = computed(() => {
  if (userGroupsStore.loading.value) return false
  return !visitedGroups.value.length && userGroupsStore.items.value.length < 1
})

const visible = ref(false)
const items = ref([])
const checked = ref([])

async function init() {
  const data = await groupAPI
    .selectRecGroup({ platform: 'web', num: 12 })
    .data()
  items.value = data.map((g) => GroupModel.fromJson(g))

  const defaultFollowed = items.value.find((g) => g.$isDefaultFollow)
  if (defaultFollowed) {
    checked.value = [defaultFollowed.id]
  }
}

watch(
  firstVisite,
  async () => {
    // console.log('watch firstVisite', firstVisite.value)
    if (firstVisite.value) {
      visible.value = true
      init()
    }
  },
  {
    immediate: true,
  }
)

async function onSumbit() {
  firstVisite.value = false
  visible.value = false
  await groupAPI.submitFollowGroup(checked.value.join(',')).data()
  window.location.reload()
}
</script>

<style lang="less">
.dialog-game {
  position: fixed;
  z-index: @dialog-zindex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#000, 0.5);

  .dialog-in {
    width: 660px;
    padding: 30px;
  }
  .dialog-hd {
    .em(22em);
    font-weight: 700;
    color: #000;
    text-align: center;
    margin-bottom: @gap*.5;
  }

  .dialog-game-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: @gap;

    .item {
      flex-shrink: 0;

      position: relative;
      width: 130px;
      height: 150px;
      border-radius: 6px;

      &:hover {
        background: @bg-gray;
      }
    }

    .check-box {
      display: flex;
      align-content: center;
      justify-content: center;
      overflow: hidden;

      position: absolute;
      top: 15px;
      right: 15px;

      width: 22px;
      height: 22px;
      border: 1px solid @color-yellow;
      border-radius: 50%;
      background: #fff;
      box-sizing: border-box;
    }

    .svg-ico {
      margin-top: 2px;
      width: 80%;
      height: 80%;
      fill: #fff;
    }
    input[type='checkbox'] {
      position: absolute;
      visibility: hidden;

      &:checked {
        & ~ label {
          .check-box {
            background: @color-yellow;
            box-shadow: 0 0 5px @color-yellow;
          }
          .svg-ico {
            fill: #000;
          }
        }
      }
    }
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding-top: @gap * 0.5;
    }

    .pic {
      width: 90px;
      height: 90px;
      .img();
      border-radius: 18px;
    }
    .txt {
      overflow: hidden;
      margin-top: 10px;
      color: #5d6274;
      white-space: nowrap;
    }
  }

  .dialog-btn-submit {
    display: block;
    width: 260px;
    margin: 0 auto;
    .em(18em);
  }
}
</style>

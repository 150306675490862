<template>
  <a
    v-if="followState !== 2"
    class="btn-follow"
    :class="{ followed: followState }"
    @click="onFollowClick"
    >{{ followState ? '已关注' : '关注' }}
  </a>
</template>

<script setup>
import { userAPI } from '@/api'
import { canComment, getUid, isLoggedIn, login } from '@/core/passport'
import { userFollowsStore } from '@/store/userFollowsStore'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const followState = ref(0)

watch(
  () => [userFollowsStore.items.value, props.data],
  () => {
    if (!userFollowsStore.items.value && !props.data) return

    const uuid = props.data.uid || props.data.authorUid

    if (!uuid) return

    const uid = `${uuid}`

    if (uid === getUid()) {
      followState.value = 2
      return
    }

    if (!userFollowsStore.items.value.length) {
      followState.value = 0
    }

    followState.value = userFollowsStore.items.value.find((u) => {
      const id = u.uid || u.authorUid
      return `${id}` === uid
    })
      ? 1
      : 0
  },
  {
    immediate: true,
  }
)

const emit = defineEmits(['follow', 'cancelFollow'])

const eventBus = inject('event-bus')

async function onFollowClick() {
  if (!isLoggedIn()) {
    login()
    return
  }
  const userInfo = props.data

  if (userInfo.followState === 2) {
    return
  }

  if (canComment) {
    const uid = props.data.uid || props.data.authorUid

    if (followState.value === 1) {
      userFollowsStore.remove(props.data)
      await userAPI.cancelFollowUser(uid).getRes()
      emit('cancelFollow')
      eventBus.emit('user:unfollow', uid)
    } else {
      userFollowsStore.add(props.data)
      await userAPI.submitFollowUser(uid).getRes()
      emit('follow')
      eventBus.emit('user:follow', uid)
    }
  } else {
    toast('被禁言')
  }
}
</script>

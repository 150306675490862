import { createRouter, createWebHistory } from 'vue-router'
import { isLoggedIn, login } from './core/passport'
import * as pages from './core/pages'
import templates from '@/pages/index'

const routes = []

Object.keys(pages).forEach((key) => {
  const routeInfo = pages[key]
  routeInfo.component = templates[key]
  routeInfo.meta = {
    title: `${routeInfo.title}_一起一起上游戏圈_硬核游戏玩家聚集地_17173.com中国游戏门户站`,
    ...routeInfo.meta,
    ...{
      needAuth: routeInfo.needAuth,
    },
  }
  routes.push(routeInfo)
})
// 所有未定义的路由跳转到404页面
routes.push({
  path: '/:pathMatch(.*)*',
  redirect: '/404',
})

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to) => {
  // 需要登录的页面,直接调用登录方法
  if (to.meta.needAuth === true && !isLoggedIn()) {
    login()
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.keywords) {
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute('content', to.meta.keywords)
  }
  if (to.meta.description) {
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', to.meta.description)
  }
})

export default router

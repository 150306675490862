<template>
  <div class="subject-top">
    <div class="tit">话题</div>
    <div class="name">
      {{ info.searchKeyword }}
    </div>
    <div class="nums">
      <span
        >{{
          // eslint-disable-next-line vue/no-parsing-error
          numberFormat(threadTotalCount < 0 ? 0 : threadTotalCount)
        }}帖子</span
      >
      <span>{{ numberFormat(info.views) }}浏览</span>
    </div>
    <app-link
      v-track-click="''"
      :to="post.toLink({}, { subject: id })"
      class="btn-go-publish"
      ><svg-icon name="publish"></svg-icon>发帖</app-link
    >
  </div>
  <div class="main">
    <div class="comm-mod mod-thread-home-list">
      <div class="thread-bd">
        <list-loader
          v-bind="threadStates"
          @load="
            info.searchKeyword &&
              threadActions.load({ keyword: info.searchKeyword })
          "
        >
          <div class="thread-list">
            <thread-item
              v-for="item in threadItems"
              :key="item.id"
              :data="item"
              :is-subject-list="true"
            ></thread-item>
          </div>
        </list-loader>
      </div>
    </div>
  </div>
  <app-fixed-bar :id="{ subject: id }"></app-fixed-bar>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { numberFormat } from '@/core/util'
import { post } from '@/core/pages'
import { subjectAPI } from '@/api'
import { subjectThreadListStore } from '@/store/group'
import { toast } from '@/components/toast'

const { id } = useRoute().params
const info = ref({})

async function init() {
  const a = subjectAPI.getSubjectInfo(id)
  const status = await a.check()
  if (status.success) {
    info.value = await a.data()

    document.title = `${info.value.searchKeyword}_一起一起上游戏圈_硬核游戏玩家聚集地_17173.com中国游戏门户站`
  } else {
    toast(status.msg)
  }
}

init()

const {
  items: threadItems,
  states: threadStates,
  actions: threadActions,
  totalCount: threadTotalCount,
} = subjectThreadListStore()

watch([info], () => {
  threadActions.reset()
  threadActions.load({ keyword: info.value.searchKeyword })
})
</script>

<style lang="less">
.subject-top {
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  position: relative;
  width: @main-width;
  height: 120px;
  padding-left: 30px;
  margin: 0 auto @gap;
  background: no-repeat url(@subject-top);

  .tit {
    margin-bottom: 0.2em;
    color: #fff;
    font-weight: 700;
  }

  .name {
    max-width: 21em;
    .em(18em);
    font-weight: 700;
    color: @color-yellow;
    .text-ellipsis();

    &::before {
      content: '#';
    }
  }

  .nums {
    margin-right: 1em;
    .em(14em);
    color: @color-light;

    span:not(:first-child) {
      &::before {
        content: ' ';
        display: inline-block;
        overflow: hidden;
        width: 1px;
        height: 0.8em;
        margin: 0 1em 0.1em;
        vertical-align: middle;
        background: currentColor;
      }
    }
  }

  .btn-go-publish {
    position: absolute;
    z-index: 5;
    right: 30px;
    bottom: 30px;
    .comment-btn(100px,36px);

    .ico-publish {
      width: 1em;
      height: 1em;
      margin-right: 0.4em;
    }
  }

  & + .main {
    margin: 0 auto;
  }
}

.thread-top-list {
  overflow: hidden;
  position: relative;
  max-height: 108px;
  padding: @gap;
  border-bottom: 1px solid @color-lighten;
  transition: max-height 0.2s;

  .item {
    overflow: hidden;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .btn {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 0.4em @gap;
    .em(14em);
    color: @color-blue;
    text-align: right;

    &::after {
      content: ' ';
      display: inline-block;
      .arrow-down();
      width: 0.3em;
      height: 0.3em;
      margin-left: 0.3em;
      margin-bottom: 0.2em;
      transform: rotate(-135deg);
    }
  }

  &.active {
    .btn {
      &::after {
        transform: translateY(0.2em) rotate(45deg);
      }
    }
  }

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: @gap;
    background: #fff;
  }
}

@tool-item-size: 56px;
.mod-thread-tools {
  padding: @gap @gap @gap*.2;
}

.thread-tools-list-wrap {
  overflow: hidden;
  overflow-x: auto;
  .scrollbar();
  padding-bottom: @gap*.3;
}
.thread-tools-list {
  display: flex;

  .item {
    flex-shrink: 0;
    width: (@tool-item-size + 30px);
    &:not(:first-of-type) {
      margin-left: 20px;
    }
  }

  .pic {
    width: @tool-item-size;
    height: @tool-item-size;
    margin-left: auto;
    margin-right: auto;

    .img();
    border-radius: 4px;
  }
  .txt {
    overflow: hidden;
    margin-top: 0.5em;
    .em(14em);
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.thread-tags {
  display: flex;
  flex-wrap: wrap;
  padding: @gap*.5 (@gap - 4px) @gap;
  .item {
    margin: @gap*.5 4px 0;
    padding: 0 @gap;
    .em(14em);
    line-height: 32px;
    white-space: nowrap;
    background: @bg-gray;
    border-radius: 16px;
    cursor: pointer;

    &.active {
      color: @color-yellow;
      border: 1px solid currentColor;
      background: #fff;
    }
  }
}

.mod-thread-home-list {
  .operation-sort {
    font-size: 1rem;
  }
}

.hot-thread-list {
  padding-bottom: @gap;
  .item {
    padding-top: @gap;
  }
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pic {
    flex-shrink: 0;
    width: 30%;
    height: 72px;
    margin-left: @gap * 0.8;
    border-radius: 6px;
    .img();
  }
  .txt {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-word;
  }
  .action-bar {
    margin-top: 0.5em;
    font-size: 0.75em;
    justify-content: flex-start;
  }
}
</style>

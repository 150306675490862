import { defineStore } from './defineStore'
import { userAPI } from '@/api'
import { getUid } from '@/core/passport'

export const userFollowsStore = defineStore(() => {
  const loading = ref(true)
  const items = ref([])

  async function init() {
    const res = userAPI.getUserFollowList(getUid())

    if (!(await res.check()).success) return

    const { dataSet } = await res.data()

    items.value = dataSet
      ? dataSet.map((g) => {
          return {
            uid: g.uid.toString(),
            nickname: g.nickname,
            followState: g.followState,
            fansNum: g.fansNum,
          }
        })
      : []

    loading.value = false
  }

  function add(user) {
    items.value = [...items.value, user]
  }

  function remove(user) {
    items.value = items.value.filter((g) => {
      return `${user.uid || user.authorUid}` !== `${g.uid || g.authorUid}`
    })
  }

  return {
    loading,
    items,
    init,
    add,
    remove,
  }
})({ id: 'userFollows' })

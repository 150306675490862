import { editorAPI } from '@/api'
import { getAssets } from '@/core/util'

const linkIcon = getAssets('images/ico-link.png')

const defaultOptions = {
  theme: 'snow',
  modules: { toolbar: false },
  formats: [
    'EmbedLink',
    'EmbedIframe',
    'EmbedImage',
    'EmbedVideo',
    'EmbedText',
    'needComment',
    'commentwrapper',
  ],
  imageDesc: false,
}

class Editor {
  constructor(options) {
    const opt = { ...defaultOptions, ...options }
    this.ueditor = new UEditor(options.container, opt)
    if (opt.onChange) {
      this.ueditor.quill.on('text-change', () => {
        opt.onChange()
      })
    }
  }

  // 插入空行
  insertEmptyParagraph() {
    this.ueditor.insertText('\n', '')
  }

  // 插入表情
  insertEmo(val) {
    this.ueditor.focus()
    this.ueditor.insertText(val, '')
  }

  /**
   * @returns 插入图片
   */
  insertImage(src, aid, width, height) {
    this.ueditor.focus()
    this.insertEmptyParagraph()
    this.ueditor.insertImage(src, aid, width, height)
    this.insertEmptyParagraph()
  }

  async insertVideo(videoid, aid) {
    if (videoid && aid) {
      this.ueditor.focus()
      this.ueditor.insertVideo(videoid, aid)
    } else {
      const src = prompt('请输入173视频页面地址或者B站视频嵌入代码')
      this.ueditor.focus()
      // https://v.17173.com/v_1_1/NDAwMjYxNTM.html
      // ueditor.insertIframe('https://player.bilibili.com/player.html?aid=717833773&bvid=BV12Q4y1C7wh&cid=402195847&page=1')
      if (!src) return
      if (src.indexOf('v.17173.com') !== -1) {
        const videoInfo = await editorAPI.get17173VideoInfo(src).data()
        this.ueditor.insertVideo(videoInfo.id, videoInfo.aid || 0)
      } else if (src.indexOf('player.bilibili.com') !== -1) {
        this.ueditor.insertIframe(src)
      } else {
        alert('视频格式错误,B站视频请填入视频地址,不要填页面地址')
      }
    }
    this.insertEmptyParagraph()
  }

  /**
   * @returns 弹出插入链接窗口
   */
  async insertLink() {
    const href = window.prompt('请输入链接')
    if (href) {
      const linkInfo = await editorAPI.getLinkInfo(href).data()
      const title = linkInfo ? linkInfo.title : href
      this.ueditor.focus()
      this.ueditor.insertLink(href, title, linkIcon)
      this.insertEmptyParagraph()
    }
  }

  toggleNeedComment() {
    this.ueditor.toggleNeedComment()
  }

  onToggleHeading() {
    this.ueditor.heading()
  }

  onToggleBold() {
    this.ueditor.bold()
  }

  clearFormat() {
    this.ueditor.clearFormat()
  }

  loadContents(contents) {
    this.ueditor.loadContents(contents)
  }

  get contents() {
    return JSON.stringify(this.ueditor.contents)
  }

  get length() {
    return this.ueditor.quill.getText().length
  }

  focus() {
    this.ueditor.quill.focus()
  }
}
export default Editor

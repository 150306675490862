class APIResponse {
  constructor(axiosResPromise, config) {
    this.config = config
    this.axiosResPromise = axiosResPromise
    this.res = null
  }

  async check() {
    const res = await this.getRes()
    return {
      success: res.status === 200 && res.data.code === 200,
      status: res.status,
      code: res.data && res.data.code,
      msg: res.data && res.data.messages ? res.data.messages[0] : '',
    }
  }

  async getRes() {
    this.res = this.res || (await this.axiosResPromise)
    return this.res
  }

  async data() {
    const res = await this.getRes()
    let data = null
    if (
      res !== null &&
      res.status === 200 &&
      res.data.code === 200 &&
      res.data.data
    ) {
      data = res.data.data
    }
    return data
  }
}

export default APIResponse

<template>
  <div class="wrap">
    <app-side-bar />
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
  <dialog-select-rec-group></dialog-select-rec-group>
  <app-mobile-tip v-if="showMobileTip"></app-mobile-tip>
</template>

<script setup>
import { userAPI } from './api'
import { user } from '@/core/pages'
import { userGroupsStore } from '@/store/userGroupsStore'
import { appGroupsStore } from '@/store/appGroupsStore'
import { appHotGroupsStore } from '@/store/appHotGroupsStore'
import { userFollowsStore } from '@/store/userFollowsStore'
import { isLoggedIn } from '@/core/passport'

const showMobileTip = ref(false)
if (/android/gi.test(navigator.userAgent)) {
  showMobileTip.value = true
}

// 全局缓存：全部圈子。用于匹配历史圈子是否有新帖
appGroupsStore.init()

// 全局缓存：热门圈子。全局展示
appHotGroupsStore.init()

// 全局缓存：用户订阅的圈子。给首页热门圈子做用户关注状态
userGroupsStore.init()

// 全局缓存：用户关注的人，关注按钮状态
if (isLoggedIn()) userFollowsStore.init()

// 登录、登出、绑定成功刷新页面
// function passportSucCallback() {
//   window.location.reload()
// }

// 登录、登出、绑定成功调用二次验证接口
Passport.on(Passport.EVENTS.loginSuccess, async () => {
  if (await userAPI.login().check()) {
    document.querySelector('#link_my_home').href = user.toLink({ id: 'mine' })
  }
})
Passport.on(Passport.EVENTS.logoutSuccess, async () => {
  if (await userAPI.logout().check()) {
    window.location.reload()
  }
})
Passport.on(Passport.EVENTS.mobileBindSucess, async () => {
  if (await userAPI.logout().check()) {
    window.location.reload()
  }
})

/* eslint-disable */
window._jc_ping = window._jc_ping || []
</script>

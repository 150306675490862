import * as d from '@/core/dataGenerator'
import UserBadgeModel from './userBadge'

export default class UserModel {
  static fromJson(data) {
    const model = new UserModel()

    model.id = d.gString(data.uid || data.authorUid)
    model.uid = model.id
    model.nickname = d.gString(data.nickname)

    model.gender = d.gNum(data.gender)

    model.title = d.gString(data.userTitle)
    model.signature = d.gString(data.signature)
    model.location = d.gString(data.location)

    model.support = d.gString(data.support)

    model.fansNum = d.gNum(data.fansNum || data.fansNum)

    model.threadNum = d.gNum(data.threadNum)
    model.followNum = d.gNum(data.follow || data.followNum)
    model.postNum = d.gNum(data.postNum)

    model.status = d.gString(data.status)
    model.followState = d.gNum(data.followState)

    model.clubDate = d.gNum(data.clubDate)
    model.clubDays = d.gNum(data.clubDays)
    model.ppDate = d.gNum(data.ppDate)
    model.ppDays = d.gNum(data.ppDays)

    // 徽章
    if (data.badge) {
      model.badge = UserBadgeModel.fromJson(data.badge)
    }
    return model
  }
}

<template>
  <div v-if="data.$visible" class="comment-item">
    <div class="comment-item-hd">
      <user-card :data="data"></user-card>
      <user-badge
        v-if="data.badge && !isReply"
        :id="data.authorUid"
        :data="data.badge"
      ></user-badge>
      <p v-if="data.pname" class="o-name">回复: {{ data.pname }}</p>
      <div class="time">{{ dateFormat(data.createDate) }}</div>
      <div v-if="canComment()" class="user-operations">
        <div class="user-operations-btn"></div>
        <div class="user-operations-popup">
          <a
            v-if="isCurrent(data.authorUid) || isManager"
            @click="onRemoveClick"
            >删除</a
          >
          <a
            v-if="!isCurrent(data.authorUid)"
            href="javascript:;"
            @click="reportDialogVisible = true"
            >举报</a
          >
        </div>
      </div>
    </div>
    <section class="comment-item-bd">
      <div class="con">
        <div v-if="data.attachQuote" class="img-cmt">
          <div class="head">引用{{ data.attachQuote.author }}图片：</div>
          <div class="img-box">
            <figure class="pic">
              <ImagesViewer
                :item="data.attachQuote"
                :data="new Array(data.attachQuote)"
              ></ImagesViewer>
            </figure>
          </div>
        </div>
        <div v-if="data.images.length" class="img-box">
          <figure
            v-for="(item, index) in data.images.slice(0, 4)"
            :key="index"
            class="pic"
          >
            <ImagesViewer :item="item" :data="data.images"></ImagesViewer>
          </figure>
        </div>
        <template v-for="(item, index) in data.richContents" :key="index">
          <paragraph-text v-if="item.isText" :data="item"></paragraph-text>
        </template>

        <div class="others-box">
          <app-publish-platform
            v-if="data.platform === 1"
          ></app-publish-platform>
          <div class="action-bar">
            <span class="reply" @click="showEditor"
              ><svg-icon name="comment"></svg-icon
              >{{ replyNum > 0 ? numberFormat(replyNum) : '' }}</span
            >
            <span class="bump" @click="postAuthCheck(bump)"
              ><svg-icon
                :name="[data.hasSupported ? 'bump-active' : 'bump']"
              ></svg-icon
              >{{ data.supports > 0 ? numberFormat(data.supports) : '' }}</span
            >
          </div>
        </div>

        <div v-if="editorVisible" class="comment-reply-form">
          <div class="editor-wrap">
            <editor
              ref="editorRef"
              :options="editorOption"
              :max-image-length="3"
            ></editor>
          </div>
          <div class="func-box">
            <div v-if="isShowSaveTip" class="txt-note">草稿已保存</div>
            <p class="enter-status">
              <span class="num" :class="{ error: contentCount > maxLength }">{{
                contentCount
              }}</span
              >/{{ maxLength }}
            </p>
            <button class="btn-reply" @click="postAuthCheck(submitComment)">
              发表
            </button>
          </div>
        </div>
      </div>
      <div v-if="replys.length > 0" class="comment-reply">
        <comment-item
          v-for="reply in replys"
          :key="reply.id"
          :data="reply"
          :parent="reply"
          :is-manager="isManager"
          :is-reply="true"
          @inner-add-reply="onAddReplySuccess"
          @inner-remove-reply="onRemoveReplySuccess"
        ></comment-item>
        <div v-if="totalPage" class="pagination">
          <span
            v-if="!(currentPage <= 1)"
            class="prev"
            @click="loadReply(currentPage - 1)"
            >上一页</span
          >
          <span
            v-for="i in totalPage"
            :key="i"
            :class="{ current: i === currentPage }"
            @click="loadReply(i)"
            >{{ i }}</span
          >
          <span
            v-if="!(currentPage >= totalPage)"
            class="next"
            @click="loadReply(currentPage + 1)"
            >下一页</span
          >
        </div>
      </div>
    </section>
  </div>
  <dialog-report
    v-show="reportDialogVisible"
    :data="reportData"
    @close="reportDialogVisible = false"
  ></dialog-report>
</template>
<script setup>
import { threadAPI } from '@/api'
import { dateFormat, numberFormat } from '@/core/util'
import {
  postAuthCheck,
  canComment,
  getUid,
  isBindPhone,
  isSockpuppet,
  isCurrent,
} from '@/core/passport'
import ThreadModel from '@/models/thread'
import { toast } from './toast'
import { dialog } from '@/components/dialog'

const eventBus = inject('event-bus')

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  parent: {
    type: Object,
    default: null,
  },
  isManager: {
    type: Boolean,
    default: false,
  },
  isReply: {
    type: Boolean,
    default: false,
  },
})

const reportDialogVisible = ref(false)
const reportData = {
  kind: 3,
  list: [
    '内容与主题不符',
    '垃圾广告营销',
    '谩骂侮辱不友善内容',
    '淫秽色情信息',
    '违法有害信息',
  ],
  content: props.data.id,
  reportedUid: props.data.authorUid,
  hasForm: true,
}

const emit = defineEmits(['innerAddReply', 'innerRemoveReply'])

// Editor
const editorRef = ref(null)
const contentCount = ref(0)
const editorVisible = ref(false)
const maxLength = 1000
const storageKey = ref(0)

const saveDraft = () => {
  localStorage.setItem(storageKey.value, editorRef.value.contents)
}

let isChange = 0
let saveTimer = 0
const isShowSaveTip = ref(0)

const setTimerFunc = () => {
  saveTimer = setInterval(() => {
    if (!isChange) {
      isShowSaveTip.value = 1
      return
    }
    isChange = 0
    isShowSaveTip.value = 0
  }, 3000)
}

const onChange = () => {
  contentCount.value = editorRef.value.length - 1
  saveDraft()
  isChange = 1
}

onUnmounted(() => {
  if (saveTimer) clearInterval(saveTimer)
})

const showEditor = () => {
  editorVisible.value = !editorVisible.value
  if (editorVisible.value) {
    setTimerFunc()
    storageKey.value = `reply-post-draft-${getUid()}-${props.data.tid}-${
      props.data.id
    }`
    const draftContents = JSON.parse(localStorage.getItem(storageKey.value))

    setTimeout(() => {
      // 渲染本地缓存的回复
      if (draftContents) {
        editorRef.value.loadContents(draftContents)
      }
      editorRef.value.focus()
    }, 300)
  } else {
    clearInterval(saveTimer)
  }
}

const editorOption = {
  image: true,
  splitMode: true,
  onChange,
}

const replys = ref(props.data.replys)
const replyNum = ref(props.data.replies)

// 分页
const currentPage = ref(1)
const totalPage = computed(() =>
  replyNum.value > 5 ? Math.ceil(replyNum.value / 5) : 0
)

async function loadReply(pageNo) {
  currentPage.value = pageNo
  const postPromise = threadAPI.getReplyList({
    pid: props.data.id,
    pageSize: 5,
    page: currentPage.value,
  })
  const checkRes = await postPromise.check()
  if (checkRes.success) {
    const data = await postPromise.data()
    replys.value = data.dataSet.map((t) => {
      return ThreadModel.fromJson(t)
    })
  } else {
    toast(checkRes.msg)
  }
}

const onAddReplySuccess = async () => {
  replyNum.value += 1
  await nextTick()
  await loadReply(totalPage.value)
  eventBus.emit('addReply')
}

// 回复
let isSubmit = true
const submitComment = async () => {
  if (!isSubmit) {
    return
  }
  isSubmit = false
  _jc_ping.push(['_trackBlockClick', 'rQb6na'])
  const params = {
    tid: props.data.tid,
    message: editorRef.value.contents,
    pid: props.data.id,
  }

  if (!(await isSockpuppet()) && !(await isBindPhone())) {
    Passport.Dialog.showMobileBinder()
    return
  }
  const postPromise = threadAPI.postComment(params)
  const checkRes = await postPromise.check()
  if (checkRes.success) {
    editorVisible.value = false
    if (props.parent) {
      emit('innerAddReply')
    } else {
      onAddReplySuccess()
    }
    toast(checkRes.msg)
    localStorage.removeItem(storageKey.value)
    clearInterval(saveTimer)
  } else {
    toast(checkRes.msg)
  }
  setTimeout(() => {
    isSubmit = true
  }, 2000)
}

const onRemoveReplySuccess = async () => {
  replyNum.value -= 1
  await nextTick()
  await loadReply(Math.min(totalPage.value, currentPage.value))

  eventBus.emit('removeReply')
}

// 删帖
function onRemoveClick() {
  dialog({
    msg: '确定要删除这条评论吗？',
    onConfirm: async () => {
      const { data } = props
      await threadAPI.delComment(data.id).check()
      data.$visible = false
      if (props.parent) {
        emit('innerRemoveReply')
      } else {
        // onRemoveReplySuccess()
        replyNum.value -= 1
        await nextTick()
        eventBus.emit('removeReply')
      }
      toast('删除成功')
    },
  })
}

// 点赞
async function bump() {
  const { data } = props
  const res = threadAPI.supportComment(data.id)
  const status = await res.check()
  if (status.success) {
    const d = await res.data()
    data.supports = d.totalCount
    data.hasSupported = d.supportStatus

    if (data.hasSupported) {
      eventBus.emit('bump:up', data.authorUid)
    } else {
      eventBus.emit('bump:cancel', data.authorUid)
    }
  } else {
    toast(status.msg)
  }
}
</script>

<template>
  <div class="main-wide">
    <div class="crumb">
      <app-link :to="home.toLink()">首页</app-link
      ><app-link :to="group.toLink({ id })">{{ name }}</app-link
      >管理成员列表
    </div>

    <section class="comm-mod mod-group">
      <div class="bd">
        <app-user-list
          v-if="mList.dataSet"
          :data="mList.dataSet"
        ></app-user-list>
      </div>
    </section>
  </div>
  <app-fixed-bar></app-fixed-bar>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { home, group } from '@/core/pages'
import { groupAPI, userAPI } from '@/api'

const { id } = useRoute().params

const mList = ref([])
const name = ref('')

async function init() {
  mList.value = await userAPI.getGroupManagers(id).data()
  name.value = (await groupAPI.groupFinal(id).data()).groupInfo.name
}

init()
</script>

<style lang="less">
.mod-group {
  .list-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .group-item {
    overflow: hidden;
    width: 45%;
    flex-shrink: 0;
  }
}
</style>

import { createVNode, render } from 'vue'
import DialogTemplate from './DialogTemplate.vue'

export const dialog = ({ msg, onConfirm, onCancel }) => {
  const container = document.createElement('div')

  const close = () => {
    document.body.removeChild(container)
  }

  const opt = {
    msg,

    onConfirm: () => {
      if (onConfirm) {
        onConfirm()
      }
      close()
    },
    onCancel: () => {
      if (onCancel) {
        onCancel()
      }
      close()
    },
  }

  const vm = createVNode(DialogTemplate, opt)
  render(vm, container)
  document.body.appendChild(container)

  return close
}

<template>
  <header class="user-info">
    <div class="top"></div>
    <div class="info">
      <figure class="avatar">
        <img :src="getAvatar(id)" alt="" />
        <svg-icon v-if="userInfo.userTitle" name="vip"></svg-icon>
      </figure>
      <div class="name">
        <span>{{ userInfo.nickname }}</span>
        <template v-if="isLoggedIn()"
          ><span
            v-if="userInfo.gender != 0"
            :class="['gender', userInfo.gender === 1 ? 'male' : 'female']"
            ><svg-icon
              :name="userInfo.gender === 1 ? 'male' : 'female'"
            ></svg-icon></span
        ></template>
        <user-badge v-if="userInfo.badge" :data="userInfo.badge"></user-badge>
        <app-link
          v-if="isSelfCard"
          :to="userProfile.toLink({ id })"
          class="link-profile"
          title="修改个人资料"
          ><svg-icon name="publish"></svg-icon
        ></app-link>
      </div>
      <div class="others">
        <span v-if="userInfo.signature" class="uid">{{
          userInfo.signature
        }}</span>
      </div>
    </div>
    <div class="other-self-info">
      <div v-if="userInfo.location" class="loc">{{ userInfo.location }}</div>
      <app-link
        v-if="isSelfCard"
        :to="userNotification.toLink()"
        class="link-msg"
        ><img :src="msgIco" alt="" /><i
          v-if="userInfo.newMsgCount"
          class="sign-new"
          >{{ userInfo.newMsgCount > 99 ? '99' : userInfo.newMsgCount }}</i
        ></app-link
      >
    </div>
    <div v-track-module="'mERNvy'" class="funcs">
      <!-- <a href="" class="btn-message">私信</a> -->
      <user-follow-btn :data="userInfo"></user-follow-btn>
    </div>
    <div v-if="canComment() && !isCurrent(id)" class="user-operations">
      <div class="user-operations-btn"></div>
      <div class="user-operations-popup">
        <a href="javascript:;" @click="reportDialogVisible = true">举报</a>
      </div>
    </div>
  </header>
  <div class="main">
    <div class="comm-mod">
      <div class="thread-hd">
        <div class="thread-tab">
          <div
            class="item"
            :class="{ active: queryType == 'all' }"
            @click="queryType = 'all'"
          >
            帖子<span v-if="threadTotal > 0" class="num">{{
              numberFormat(threadTotal)
            }}</span>
          </div>
          <div
            class="item"
            :class="{ active: queryType == 'reply' }"
            @click="queryType = 'reply'"
          >
            回帖<span v-if="replyTotal > 0" class="num">{{
              numberFormat(replyTotal)
            }}</span>
          </div>
          <div
            v-if="userInfo.badge"
            class="item"
            :class="{ active: queryType == 'badge' }"
            @click="getBadgeList()"
          >
            徽章
          </div>
        </div>
      </div>
      <div class="thread-bd">
        <list-loader
          v-if="queryType == 'all' || queryType == 'reply'"
          v-bind="queryType == 'all' ? threadStates : replyStates"
          @load="
            queryType == 'all'
              ? threadActions.load({ uuid: id })
              : replyActions.load({ uuid: id })
          "
        >
          <div v-if="queryType == 'all'" class="thread-list">
            <thread-item
              v-for="item in threadItems"
              :key="item.id"
              :data="item"
            ></thread-item>
          </div>
          <div v-else class="thread-list">
            <thread-reply-item
              v-for="item in replyItems"
              :key="item.id"
              :data="item"
            ></thread-reply-item>
          </div>
        </list-loader>
        <div v-if="queryType == 'badge'" class="badge-box">
          <div class="mod-badge">
            <div class="hd">已解锁勋章</div>
            <div class="bd">
              <div
                v-for="item in badgeList"
                :key="item.id"
                :class="['item', 'level' + item.level]"
              >
                <figure class="logo">
                  <img :src="item.src" :alt="item.title" />
                </figure>
                <p class="title">{{ item.title }}</p>
                <p class="time">{{ dateFormatNormal(item.time, true) }}</p>
                <p class="desc">{{ item.desc }}</p>
              </div>
            </div>
            <div class="ft"><div class="badge-tip">徽章生产中</div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="side">
    <div v-if="userInfo.title" class="comm-mod mod-user-honor-list">
      <div class="item">
        <svg-icon name="vip"></svg-icon>官方认证：{{ userInfo.title }}
      </div>
    </div>
    <div class="comm-mod mod-user-datas">
      <app-link :to="userFollowList.toLink({ id })" class="follows-num"
        ><b>{{ numberFormat(userInfo.followNum) }}</b
        >关注</app-link
      >
      <app-link :to="userFansList.toLink({ id })" class="fans-num"
        ><b>{{ numberFormat(userInfo.fansNum) }}</b
        >粉丝</app-link
      >
      <span class="thread-num"
        ><b>{{ userInfo.support > 0 ? numberFormat(userInfo.support) : 0 }}</b
        >获赞</span
      >
    </div>
    <section class="comm-mod mod-user-profile">
      <header class="hd">
        <div class="tit">个人资料</div>
        <div class="more">
          <app-link v-if="isSelfCard" :to="userProfile.toLink({ id })"
            >修改 &gt;&gt;</app-link
          >
        </div>
      </header>
      <div class="bd">
        <div class="item"><span class="tit">UID：</span>{{ id }}</div>
        <div v-if="isLoggedIn()" class="item">
          <span class="tit">性别：</span
          >{{
            userInfo.gender === 0 ? '保密' : userInfo.gender === 1 ? '男' : '女'
          }}
        </div>
        <div class="item">
          <span class="tit">注册17173：</span>{{ userInfo.ppDate }}
          <p :class="['days', userInfo.ppDays > 365 * 3 ? 'high-level' : '']">
            距离至今 <span>{{ userInfo.ppDays }}</span> 天
          </p>
        </div>
        <div class="item">
          <span class="tit">首次入驻APP：</span>{{ userInfo.clubDate }}
          <p :class="['days', userInfo.clubDays > 365 * 3 ? 'high-level' : '']">
            距离至今 <span>{{ userInfo.clubDays }}</span> 天
          </p>
        </div>
      </div>
    </section>
  </div>
  <app-fixed-bar></app-fixed-bar>
  <dialog-report
    v-show="reportDialogVisible"
    :data="reportData"
    @close="reportDialogVisible = false"
  ></dialog-report>
</template>

<script setup>
import { useRoute } from 'vue-router'
import {
  userFollowList,
  userFansList,
  userNotification,
  userProfile,
} from '@/core/pages'
import { numberFormat, getAssets, dateFormatNormal } from '@/core/util'
import {
  getUid,
  getAvatar,
  isCurrent,
  canComment,
  isLoggedIn,
  login,
} from '@/core/passport'
import { userAPI } from '@/api'
import { userThreadListStore } from '@/store/group'
import { userReplyListStore } from '@/store/user'
import SvgIcon from '@/components/SvgIcon.vue'
import UserBadgeModel from '@/models/userBadge'
import UserModel from '@/models/user'

const msgIco = getAssets('images/ico-msg.png')

// id为mine时取passport的uid
let id
if (useRoute().params.id === 'mine') {
  if (isLoggedIn()) {
    id = getUid()
  } else {
    login()
  }
} else {
  id = useRoute().params.id
}

const { hash } = useRoute()

const reportDialogVisible = ref(false)

const reportData = {
  kind: 2,
  list: [
    '用户资料存在违规内容',
    '发布违法违规内容',
    '发布垃圾广告内容',
    '发布抄袭或侵权内容',
    '冒充他人账号',
    '疑似水军',
    '恶意骚扰行为',
  ],
  content: id,
  reportedUid: id,
  hasForm: true,
}

const {
  items: threadItems,
  states: threadStates,
  totalCount: threadTotal,
  actions: threadActions,
} = userThreadListStore()

const {
  items: replyItems,
  states: replyStates,
  totalCount: replyTotal,
  actions: replyActions,
} = userReplyListStore()

replyActions.load({ uuid: id })

const userInfo = ref({})
const isSelfCard = getUid() === id || id === 'mine'

const badgeList = ref([])

const queryType = ref('all') // all/reply

async function getBadgeList() {
  const temp = await userAPI.getBadgeList({ uuid: id }).data()
  if (temp.dataSet) {
    badgeList.value = temp.dataSet.map((g) => UserBadgeModel.fromJson(g))
  }
  queryType.value = 'badge'
}

async function init() {
  let temp = null
  if (isSelfCard) {
    document.querySelector('#link_my_home').classList.add('active')
    temp = await userAPI.getMyInfo().data()
  } else {
    document.querySelector('#link_my_home').classList.remove('active')
    temp = await userAPI.getUserInfo(id).data()
  }

  userInfo.value = UserModel.fromJson(temp)

  // 定位到徽章tab
  if (userInfo.value.badge && hash === '#badge') {
    getBadgeList()
  }

  document.title = `${userInfo.value.nickname}_一起一起上游戏圈_硬核游戏玩家聚集地_17173.com中国游戏门户站`
}
init()

const eventBus = inject('event-bus')
eventBus.on('user:follow', (uid) => {
  // 自己
  // const isSelfCard = getUid() === id

  // 关注当前用户
  const isFollowCurrentUser = id === String(uid)
  if (isSelfCard) {
    userInfo.value.followNum += 1
  }
  if (isFollowCurrentUser) {
    userInfo.value.fansNum += 1
  }
})
eventBus.on('user:unfollow', (uid) => {
  // const isSelfCard = getUid() === id
  const isUnFollowCurrentUser = id === String(uid)
  if (isSelfCard) {
    userInfo.value.followNum -= 1
  }
  if (isUnFollowCurrentUser) {
    userInfo.value.fansNum -= 1
  }
})

eventBus.on('bump:up', (uid) => {
  if (String(uid) === id) {
    userInfo.value.support += 1
  }
})
eventBus.on('bump:cancel', (uid) => {
  if (String(uid) === id) {
    userInfo.value.support -= 1
  }
})
</script>

<style lang="less">
.user-info {
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: (170px+50);
  margin-bottom: @gap;
  background: #fff;
  border-radius: 6px;

  .top {
    position: relative;
    overflow: hidden;
    height: 170px;
    border-radius: 6px 6px 0 0;
    background: url(@user-default-head) no-repeat 50% 50% / cover;

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(#000, 0.1);
    }
  }
  .user-operations {
    z-index: 20;
    top: @gap;
    right: @gap;
    bottom: auto;
    width: 36px;
    height: 36px;
    background: rgba(#000, 0.3);
    border-radius: 50%;

    &-btn {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      color: rgba(#fff, 0.7);

      &::before,
      &::after {
        margin: -2px 0;
        border-radius: 2px;
      }
    }
  }

  .info {
    position: absolute;
    left: 30px;
    top: 127px;
    padding-left: 95px;
  }
  .avatar {
    position: absolute;
    top: 0;
    left: 3px;
    width: 80px;
    height: 80px;
    border: 1px solid #fff;
    border-radius: 50%;

    .img(visible);

    img {
      border-radius: 50%;
    }
    .svg-ico {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 30%;
      height: 30%;
    }
  }

  .name {
    display: flex;
    align-items: center;

    height: (220px-127-50);

    .em(22em);
    font-weight: 700;
    color: #fff;
    line-height: 1.2;
  }
  .gender,
  .link-profile {
    flex-shrink: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 17px;
    height: 17px;
  }
  .gender {
    margin-left: 0.2em;
    margin-top: -0.5em;
    border-radius: 50%;

    &.male {
      background: #55abd9;
    }
    &.female {
      background: #ff4184;
    }

    .svg-ico {
      width: 11px;
      height: 80%;
      fill: #fff;
    }
  }

  .link-profile {
    margin-left: 0.5em;
    .svg-ico {
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }

  .others {
    display: flex;
    align-items: center;

    height: 50px;
    .em(14em);
  }
  .intro {
    position: relative;
    max-width: 450px;
    padding-left: 2em;
    &::before {
      content: ' ';
      overflow: hidden;
      position: absolute;
      left: 1em;
      top: 0;
      bottom: 0;
      width: 1px;
      height: 60%;
      margin: auto;
      background: #e5e7ea;
    }
  }

  .funcs {
    display: flex;
    align-items: center;

    position: absolute;
    right: 30px;
    bottom: 0;
    height: 50px;
  }

  .btn-message {
    flex-shrink: 0;
    width: 66px;
    height: 30px;
    margin-right: 10px;
    font-size: 0.875em;
    line-height: 30px;
    color: #222;
    text-align: center;
    background: @bg-gray;
    border-radius: 15px;
  }

  .other-self-info {
    position: absolute;
    right: 25px;
    bottom: 70px;

    display: flex;
    align-items: center;
  }
  .loc {
    margin-right: @gap;
    padding: 0 0.5em 0 1.5em;
    .em(14em);
    color: #fff;
    background: rgba(#000, 0.2) url(@/assets/images/ico-loc.png) no-repeat 0.5em
      50%;
    border-radius: 4px;
  }
  .link-msg {
    position: relative;
    img {
      display: block;
    }

    .sign-new {
      position: absolute;
      top: -0.6em;
      left: 60%;
      min-width: 1.2em;
      height: 1.2em;
      padding: 0 0.2em;
      .em(12em);
      font-style: normal;
      line-height: 1.2;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background: @color-red;
      border-radius: 0.6em;
    }
  }
}
.mod-user-honor-list {
  padding: @gap;
  .item {
    display: flex;
    align-items: center;
    color: @color-orange;
  }
  .svg-ico {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    margin-right: 0.3em;
  }
}

.mod-user-datas {
  display: flex;
  padding: @gap 0;

  .em(14em);
  text-align: center;

  a,
  span {
    flex: 1;
    position: relative;
    padding: 0 2em;

    b {
      display: block;
      overflow: hidden;
      white-space: nowrap;
    }

    &:not(:first-child) {
      &::before {
        content: ' ';
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        height: 2.57em;
        margin: auto;
        background: @bg-gray;
      }
    }
  }
}
.mod-user-profile {
  .bd {
    .em(14em);
    padding-bottom: @gap;
  }
  .item {
    margin-top: 1em;
  }
  .days {
    height: 36px;
    margin-top: 0.2em;
    padding: 6px 20px 0;
    line-height: 30px;

    &:not(.high-level) {
      color: @color-light;

      background-color: @bg-gray;
      mask: url(@/assets/images/bg-reg-time.png) no-repeat;
    }

    &.high-level {
      color: #fff;
      background: url(@/assets/images/bg-reg-time.png) no-repeat;

      span {
        font-weight: 700;
      }
    }
  }
}

.mod-badge {
  @g: 30px;
  padding: @g 0;
  .hd {
    margin-bottom: @g;
    line-height: (30 / 16);
    color: @color-light;
    text-align: center;
    background: url(@/assets/images/badge-tit-bg.png) no-repeat 50% 50% /
      contain;
  }

  .bd {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 @gap;
  }
  .item {
    flex-shrink: 0;
    position: relative;
    width: 30%;
    height: 260px;
    margin: 0 (@gap*.5) @gap;
    text-align: center;
    background: @bg-gray;
    border-radius: 0.375em;
    cursor: default;

    &:hover {
      .desc {
        opacity: 1;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    padding-top: 25px;

    img {
      height: 116px;
    }
  }

  .title {
    font-weight: 700;
  }

  .level2 {
    .title {
      color: #7f45bb;
    }
  }
  .level3 {
    .title {
      color: #e32b00;
    }
  }

  .time {
    .em(14em);
    color: @color-light;
  }

  .desc {
    position: absolute;
    right: 5%;
    bottom: 0.5em;
    left: 5%;
    .em(14em);
    color: @color-light;
    line-height: 1.2;
    opacity: 0;
    transition: opacity 0.2s;
  }
}
.badge-tip {
  margin-top: 50px;
  .tip-ico();
}
</style>

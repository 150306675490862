<template>
  <div class="main-wide">
    <div class="crumb">
      <app-link :to="home.toLink()">首页</app-link
      ><app-link :to="user.toLink({ id })">{{ userName }}</app-link
      >粉丝
    </div>

    <section class="comm-mod mod-user-fans">
      <div class="bd">
        <app-user-list v-if="fansList" :data="fansList"></app-user-list>
        <div v-else class="no-more">暂无粉丝</div>
      </div>
    </section>
  </div>
  <app-fixed-bar></app-fixed-bar>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { home, user } from '@/core/pages'
import { getUid, getUname } from '@/core/passport'
import { userAPI } from '@/api'

const { id } = useRoute().params

const userInfo = ref({})
const fansList = ref(null)
const userName = ref('')

const titleEndText =
  '_一起一起上游戏圈_硬核游戏玩家聚集地_17173.com中国游戏门户站'

async function init() {
  if (getUid() === id) {
    document.title = `我的粉丝${titleEndText}`
    userName.value = getUname()
  } else {
    userInfo.value = await userAPI.getUserInfo(id).data()
    userName.value = userInfo.value.nickname
    document.title = `${userName.value}的粉丝${titleEndText}`
  }
  const fans = await userAPI.getUserFansList(id).data()
  fansList.value = fans.dataSet && fans.dataSet
}

init()
</script>

<style lang="less">
.mod-user-fans {
  .list-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .group-item {
    overflow: hidden;
    width: 45%;
    flex-shrink: 0;
  }
  .no-more {
    margin-top: 0;
  }
}
</style>

<template>
  <div class="main-wide">
    <thread-article
      v-if="tStore.data.value !== null"
      :data="tStore.data.value"
      :is-manager="isManager"
      :group-info="gInfo"
      :scroll-to-comment="scrollToComment"
      :hot-thread-list="hotThreadList"
    ></thread-article>
    <div id="comment" class="comment">
      <section class="comment-mod-publish">
        <header class="comment-hd">
          <div class="tit">写评论</div>
        </header>
        <div class="comment-bd">
          <comment-form v-if="tid" :tid="tid" :bi-key="'rQb6na'"></comment-form>
        </div>
      </section>

      <!-- <section class="comment-mod-hot">
        <header class="comment-hd">
          <div class="tit">热门评论</div>
        </header>
        <div class="bd">
          <comment-list></comment-list>
        </div>
      </section> -->
      <section class="comment-mod-list">
        <comment-list
          v-if="tid && tStore.data.value"
          :tid="tid"
          :is-manager="isManager"
          :total="tStore.data.value.replies"
        ></comment-list>
      </section>
    </div>
  </div>
  <app-fixed-bar
    :show-btn="true"
    :data="tStore.data.value"
    :scroll-to-comment="scrollToComment"
  ></app-fixed-bar>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { groupAPI } from '@/api'
import { threadInfoStore } from '@/store/group'
import ThreadArticle from './components/ThreadArticle.vue'
import CommentList from './components/CommentList.vue'
import CommentForm from './components/CommentForm.vue'
import { toast } from '@/components/toast'
import { home } from '@/core/pages'
import { getUid } from '@/core/passport'

import GroupModel from '@/models/group'
import ThreadModel from '@/models/thread'

const route = useRoute()
const eventBus = inject('event-bus')

const tStore = threadInfoStore()
const tid = ref()

const isManager = ref(false)
const gInfo = ref({})
const hotThreadList = ref([])

const scrollToComment = () => {
  const el = document.querySelector('#comment')
  const artTop = 49
  const top = el.offsetTop - 44 - artTop
  document.body.scrollTop = top
  document.documentElement.scrollTop = top
}

onMounted(async () => {
  const { id } = route.params
  tid.value = id
  await tStore.actions.load({ tid: id })
  if (tStore.check.value && tStore.check.value.code === 400) {
    toast(tStore.check.value.msg)
    setTimeout(() => home.to(), 3000)
    return
  }
  if (!tStore.data.value.id) {
    toast('帖子不存在,即将跳转到首页')
    setTimeout(() => home.to(), 3000)
  }

  // 设置title等页面属性
  let docTitle = ''
  const docContent = tStore.data.value.content
    .find((c) => c.type === 'text')
    .value.substr(0, 10)
  if (tStore.data.value.title) {
    docTitle = `${tStore.data.value.title}_${docContent}`
  } else {
    docTitle = docContent
  }
  document.title = `${docTitle}_一起一起上游戏圈_硬核游戏玩家聚集地_17173.com中国游戏门户站`

  // 跳转到评论
  if (route.hash.indexOf('#comment') > -1) {
    await nextTick()
    scrollToComment()
  }

  // 获取圈子信息
  const a = groupAPI.groupFinal(tStore.data.value.gid)
  const status = await a.check()
  if (status.success) {
    const d = await a.data()
    gInfo.value = GroupModel.fromJson(d.groupInfo)
    isManager.value =
      d.manager &&
      Boolean(d.manager.filter((m) => m.toString() === getUid()).length)
  }

  // 热帖
  const htList = (await groupAPI.hotThread(gInfo.value.id).data()) || []
  if (htList.length > 0) {
    hotThreadList.value = htList.map((t) => {
      return ThreadModel.fromJson(t)
    })
  }
})

eventBus.on('addReply', () => {
  tStore.data.value.replies += 1
})
eventBus.on('removeReply', () => {
  tStore.data.value.replies -= 1
})
</script>

<style lang="less">
.comment {
  .mod();

  .btn-list-more {
    margin-bottom: -@gap;
  }
}

[class*='comment-mod-'] {
  padding-top: 0.3em;
}
.comment-hd {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5em 0;

  border-bottom: 1px solid @color-lighten;

  .tit {
    padding: 0 @gap;
    color: @color-black;
    font-weight: 700;
  }
}

.comment-tab {
  display: flex;

  & > div {
    padding: 0 @gap;
    color: @color-light;

    &.active {
      font-weight: 700;
      color: @color-black;
    }
  }
}

.comment-mod-publish {
  .comment-hd {
    border-bottom: 0;
  }
  .comment-bd {
    padding: 0 @gap @gap;
  }
}

.comment-mod-list {
  margin-top: @gap;
}
</style>

import { defineStore } from './defineStore'
import { groupAPI } from '@/api'
import GroupModel from '@/models/group'

export const userGroupsStore = defineStore(() => {
  const loading = ref(true)
  const items = ref([])

  async function init() {
    const res = groupAPI.getMyGroupList()

    if (!(await res.check()).success) return

    const { dataSet } = await res.data()

    items.value = dataSet ? dataSet.map((g) => GroupModel.fromJson(g)) : []

    loading.value = false
  }

  function add(group) {
    items.value = [...items.value, group]
  }

  function remove(group) {
    items.value = items.value.filter((g) => g.id !== group.id)
  }

  return {
    loading,
    items,
    init,
    add,
    remove,
  }
})({ id: 'userGroups' })

<template>
  <div class="main-wide">
    <div class="crumb">
      <app-link :to="home.toLink()">首页</app-link>圈子列表
    </div>

    <section v-if="myGroupItems.length" class="comm-mod mod-grouplist-my">
      <header class="hd">
        <div class="tit">我订阅的圈子</div>
      </header>
      <div class="bd">
        <div class="list-group">
          <div
            v-for="item in myGroupItems"
            :key="item.id"
            class="group-item"
            :data-group-id="item.id"
          >
            <app-link :to="group.toLink({ id: item.id })" class="con">
              <figure class="pic">
                <group-avatar :data="item.logo"></group-avatar>
              </figure>
              <div class="txt">
                <p class="name">{{ item.name }}</p>
                <p class="other">
                  <span>{{ item.threadNum }}个帖子</span>
                </p>
              </div>
            </app-link>
            <div class="btn">
              <group-follow-btn :data="item" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-track-module="'N3I32e'" class="comm-mod mod-grouplist-all">
      <header class="hd">
        <div class="tit">游戏圈子</div>
      </header>
      <div class="bd">
        <div class="list-group">
          <div
            v-for="item in appGroupsItems"
            :key="item.id"
            class="group-item"
            :data-group-id="item.id"
          >
            <app-link :to="group.toLink({ id: item.id })" class="con">
              <figure class="pic">
                <group-avatar :data="item.logo"></group-avatar>
              </figure>
              <div class="txt">
                <p class="name">{{ item.name }}</p>
                <p class="other">
                  <span>{{ item.followNum }}订阅</span>
                  <span>{{ item.threadNum }}个帖子</span>
                </p>
              </div>
            </app-link>
            <div class="btn">
              <group-follow-btn :data="item" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <app-fixed-bar></app-fixed-bar>
</template>

<script setup>
import { home, group } from '@/core/pages'
import { appGroupsStore } from '@/store/appGroupsStore'
import { userGroupsStore } from '@/store/userGroupsStore'

const { items: appGroupsItems } = appGroupsStore

const { items: myGroupItems } = userGroupsStore
</script>

<style lang="less">
[class*='mod-grouplist'] {
  .list-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .group-item {
    overflow: hidden;
    width: 45%;
    flex-shrink: 0;
  }
}
</style>

import { createVNode, render } from 'vue'
import ToastTemplate from './ToastTemplate.vue'

const defaultOption = {
  duration: 3000,
}

export function toast(msg, option) {
  const container = document.createElement('div')
  const opt = { ...defaultOption, ...option, msg }
  const vm = createVNode(ToastTemplate, opt)
  render(vm, container)
  document.body.appendChild(container)
  // 销毁弹窗, 如果duration设置为0,需要调用者手动销毁
  const dispose = () => {
    if (vm.el.querySelector('.toast-msg')) {
      // 淡入淡出效果
      vm.el.querySelector('.toast-msg').classList.add('reomve') // 销毁时添加淡入淡出效果
      const t = setTimeout(() => {
        render(null, container)
        clearTimeout(t)
        document.body.removeChild(container)
      }, 400)
    }
  }
  if (opt.duration) {
    const timer = setTimeout(() => {
      dispose()
      clearTimeout(timer)
    }, opt.duration)
  }
  return {
    dispose,
  }
}

<template>
  <a v-if="isAbsolute" :href="to" :target="target"><slot></slot></a>
  <router-link v-else :to="to" :target="target"><slot></slot></router-link>
</template>

<script setup>
const props = defineProps({
  // 链接地址
  to: {
    type: String,
    required: true,
    default: '',
  },
  target: {
    type: String,
    required: false,
    default: '_blank',
  },
})

const isAbsolute = ref(props.to.indexOf('//') !== -1)
</script>

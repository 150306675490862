<template>
  <div class="ql-toolbar">
    <div class="ql-toolbar-in">
      <button
        v-if="options.image"
        type="button"
        title="上传图片"
        class="ql-clean"
        @click="postAuthCheck(selectImage)"
      >
        <svg-icon name="editor-img"></svg-icon></button
      ><button
        v-if="options.hasEmo"
        type="button"
        :class="['ql-clean', isShowEmoDialog ? 'active' : '']"
        title="表情"
        @click="postAuthCheck(showEmoDialog)"
      >
        <img src="@/assets/images/ico-face.png" alt="" /></button
      ><button
        v-if="options.video"
        type="button"
        class="ql-clean"
        title="上传视频"
        @click="postAuthCheck(selectVideo)"
      >
        <svg-icon name="editor-video"></svg-icon>
      </button>
      <button
        v-if="options.link"
        type="button"
        class="ql-clean"
        title="插入网址"
        @click="postAuthCheck(onInsertLink)"
      >
        <svg-icon name="editor-link"></svg-icon>
      </button>
      <button
        v-if="options.iframe"
        type="button"
        class="ql-clean"
        title="插入视频(支持17173、B站)"
        @click="postAuthCheck(onInsertIframe)"
      >
        <svg-icon name="editor-iframe"></svg-icon>
      </button>
      <button
        v-if="options.needComment"
        type="button"
        class="ql-clean"
        title="文本回复可见"
        @click="postAuthCheck(onToggleNeedComment)"
      >
        <svg-icon name="editor-hide"></svg-icon>
      </button>
      <button
        v-if="options.hasHeading"
        type="button"
        class="ql-clean"
        title="标题1(CTRL+SHIFT+H)"
        @click="postAuthCheck(onToggleHeading)"
      >
        <svg-icon name="editor-h1"></svg-icon>
      </button>
      <button
        v-if="options.hasBold"
        type="button"
        class="ql-clean"
        title="文字加粗(CTRL+B)"
        @click="postAuthCheck(onToggleBold)"
      >
        <svg-icon name="editor-b"></svg-icon>
      </button>
      <button
        v-if="options.hasClearFormat"
        type="button"
        class="ql-clean"
        title="清除格式(CTRL+SHIFT+K)"
        @click="postAuthCheck(clearFormat)"
      >
        <svg-icon name="editor-clear"></svg-icon>
      </button>
    </div>
    <div v-if="isShowEmoDialog" class="emo-dialog">
      <div
        v-for="(item, index) in emoList"
        :key="index"
        @click="postAuthCheck(() => onSelectEmo(item.title))"
      >
        <img :src="item.url" />
      </div>
    </div>
  </div>
  <div ref="container" class="ueditor editor-con"></div>
  <input
    ref="editorUploadInput"
    style="display: none"
    type="file"
    accept="image/gif,image/jpeg,image/jpg,image/png"
    multiple="multiple"
    @change="onImageSelect"
  />
  <input
    ref="editorVideoUploadInput"
    style="display: none"
    type="file"
    accept="video/*"
    @change="onVideoSelect"
  />
  <div v-if="showImageUploadTip" class="tip-image-upload">
    图片上传中，请耐心等待...
  </div>
</template>

<script setup>
// import * as qiniu from 'qiniu-js/lib'
import { sticky, emoList } from '@/core/util'
import Editor from '@/plugins/editor'
import { editorAPI } from '@/api'
import { postAuthCheck } from '@/core/passport'
import { toast } from '@/components/toast'

const props = defineProps({
  // 链接地址
  content: {
    type: String,
    required: false,
    default: '',
  },
  options: {
    type: Object,
    default: null,
  },
  maxImageLength: { type: Number, default: 0 },
  isToolbarFixed: {
    type: Boolean,
    default: false,
  },
})

const options = {
  ...{
    image: true,
    video: false,
    link: false,
    iframe: false,
    needComment: false,
    hasHeading: false,
    hasBold: false,
    hasClearFormat: false,
    hasEmo: true,
  },
  ...props.options,
}

let editor
const editorUploadInput = ref(null)
const editorVideoUploadInput = ref(null)

const maxSelImgLength = props.maxImageLength || 20
const currentImageLength = ref(0)

// 图片是否加载中
const showImageUploadTip = ref(false)

// 压缩前将file转换成img对象
function readImg(file) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const reader = new FileReader()
    reader.onload = (e) => {
      img.src = e.target.result
    }
    reader.onerror = (e) => {
      reject(e)
    }
    reader.readAsDataURL(file)
    img.onload = () => {
      resolve(img)
    }
    img.onerror = (e) => {
      reject(e)
    }
  })
}

/**
 * 压缩图片
 *@param img 被压缩的img对象
 * @param type 压缩后转换的文件类型
 * @param mx 触发压缩的图片最大宽度限制
 * @param mh 触发压缩的图片最大高度限制
 */
function compressImg(img, type, mx, mh) {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const { width: originWidth, height: originHeight } = img
    // 最大尺寸限制
    const maxWidth = mx || 1920
    const maxHeight = mh || 1080

    // 目标尺寸
    let targetWidth = originWidth
    let targetHeight = originHeight
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > 1) {
        //  宽图片
        targetWidth = maxWidth
        targetHeight = Math.round(maxWidth * (originHeight / originWidth))
      } else {
        // 高图片
        targetHeight = maxHeight
        targetWidth = Math.round(maxHeight * (originWidth / originHeight))
      }
    }
    canvas.width = targetWidth
    canvas.height = targetHeight
    context.clearRect(0, 0, targetWidth, targetHeight)
    // 图片绘制
    context.drawImage(img, 0, 0, targetWidth, targetHeight)
    canvas.toBlob(
      (blob) => {
        console.log(blob)
        resolve(blob)
      },
      type || 'image/jpg',
      0.5
    )
  })
}

const selectImage = () => {
  currentImageLength.value = JSON.parse(editor.contents).filter(
    (el) => el.type === 'image'
  ).length
  if (props.maxImageLength && currentImageLength.value >= maxSelImgLength) {
    toast(`最多上传${maxSelImgLength}张图片`)
    return
  }
  const input = editorUploadInput.value
  input.value = null
  input.click()
}

const onImageSelect = async (event) => {
  const fileList = event.target.files
  let num = maxSelImgLength

  if (props.maxImageLength) {
    num = maxSelImgLength - currentImageLength.value
  }
  if (fileList.length > num) {
    toast(`最多选择${num}张图片`)
  }

  const images = []

  // eslint-disable-next-line no-restricted-syntax
  for await (const i of Object.keys(fileList).slice(0, num)) {
    const file = fileList[i]
    if (!file) return
    showImageUploadTip.value = true
    const maxSize = 20 * 1024 * 1024
    if (file.size > maxSize) {
      toast('图片不能大于20M')
    } else {
      try {
        const img = await readImg(file)
        let res = null
        if (/gif/gi.test(file.type)) {
          res = await editorAPI.upLoadImage(file, file.name)
        } else {
          const blob = await compressImg(img, file.type)
          res = await editorAPI.upLoadImage(blob, file.name)
        }

        const checkRes = await res.check()
        if (checkRes.success) {
          const data = await res.data()
          if (data.length > 0) {
            const imageInfo = data[0]
            // editor.insertImage(imageInfo.picUrl, imageInfo.aid)
            images.push(imageInfo)
          }
        } else {
          toast(checkRes.msg)
        }
      } catch (error) {
        toast('上传失败,请重试')
      }
    }
  }
  showImageUploadTip.value = false
  if (images.length > 0) {
    images.forEach((item) => {
      editor.insertImage(item.picUrl, item.aid)
    })
  }
}

const selectVideo = () => {
  if (
    JSON.parse(editor.contents).filter((el) => el.type === 'video').length >= 1
  ) {
    toast(`最多上传1个视频`)
    return
  }
  const input = editorVideoUploadInput.value
  input.value = null
  input.click()
}

const onVideoSelect = async (event) => {
  const file = event.target.files[0]
  if (file) {
    const tokenInstance = editorAPI.getQiniuToken()
    const tokenCheckRes = await tokenInstance.check()
    //     fileName: "1636344008.mp4"
    // token: "T-iRQMq3AtTk82jpNN2zlWYU4adrPGEGKGK4guiO:h0Eyiuj9gEMvQHqu7fxJPhgFI7Y=:eyJzY29wZSI6InYtdmlkZW9iYXNlLWRldiIsImRlYWRsaW5lIjoxNjM2MzQ3NjA4fQ=="
    // videoId: "617537438927163392"
    if (tokenCheckRes.success) {
      const token = await tokenInstance.data()
      console.log(token)
      const observable = qiniu.upload(file, token.fileName, token.token)
      observable.subscribe({
        next(res) {
          console.log('上传进度:', res.total.percent.toFixed(1))
        },
        error(err) {
          toast(err.toString())
        },
        async complete(res) {
          const receiptRes = editorAPI.qiniuUploadReceipt({
            fileName: res.key,
            videoId: token.videoId,
          })
          const checkRes = await receiptRes.check()
          if (checkRes.success) {
            const receipt = await receiptRes.data()
            // console.log('rece', receipt)
            editor.insertVideo(receipt.videoId, receipt.aid)
          } else {
            toast(checkRes.msg)
          }
        },
      })
    }
  }
}

const onPaste = async (e) => {
  const { items } = e.clipboardData || e.originalEvent.clipboardData
  let file = null
  if (items && items.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (/image\//gi.test(item.type)) {
        file = item.getAsFile()
      }
    }

    if (file) {
      const maxSize = 20 * 1024 * 1024

      if (file.size > maxSize) {
        toast('图片不能大于20M')
      } else {
        showImageUploadTip.value = true
        try {
          const img = await readImg(file)
          let res = null
          if (/gif/gi.test(file.type)) {
            res = await editorAPI.upLoadImage(file, file.name)
          } else {
            const blob = await compressImg(img, file.type)
            res = await editorAPI.upLoadImage(blob, file.name)
          }

          const checkRes = await res.check()
          if (checkRes.success) {
            const data = await res.data()
            if (data.length > 0) {
              const imageInfo = data[0]
              editor.insertImage(imageInfo.picUrl, imageInfo.aid)
            }
          } else {
            toast(checkRes.msg)
          }
        } catch (error) {
          toast('上传失败,请重试')
        }
        showImageUploadTip.value = false
      }
    }
  }
}

const onInsertLink = () => {
  editor.insertLink()
}
const onInsertIframe = () => {
  editor.insertVideo()
}
const onToggleNeedComment = () => {
  editor.toggleNeedComment()
}

const onToggleHeading = () => {
  editor.onToggleHeading()
}
const onToggleBold = () => {
  editor.onToggleBold()
}
const clearFormat = () => {
  editor.clearFormat()
}

const isShowEmoDialog = ref(false)

const showEmoDialog = () => {
  isShowEmoDialog.value = !isShowEmoDialog.value
}
const onSelectEmo = (val) => {
  editor.insertEmo(`[${val}]`)
  isShowEmoDialog.value = !isShowEmoDialog.value
}

defineExpose({
  get contents() {
    return editor.contents
  },
  get length() {
    return editor.length
  },
  focus() {
    editor.focus()
  },
  loadContents: (content) => {
    return editor.loadContents(content)
  },
})

const container = ref(null)

onMounted(() => {
  const opt = { container: container.value, ...options }
  editor = new Editor(opt)
  document.querySelector('.ql-editor').addEventListener('paste', (e) => {
    postAuthCheck(() => onPaste(e))
  })

  if (props.isToolbarFixed) {
    sticky('.ql-toolbar', '.ql-toolbar-in')
  }
})
</script>

<template>
  <div class="dialog dialog-profile">
    <div class="dialog-in">
      <div class="dialog-hd">
        <div class="dialog-tit">修改个性签名</div>
        <a class="dialog-btn-close" @click="emits('close')">×</a>
      </div>
      <div class="dialog-bd">
        <div class="dialog-profile-form">
          <div class="dialog-profile-form-item">
            <div class="tit">个性签名</div>
            <div class="con">
              <textarea
                id="text"
                v-model="value"
                class="input-txt"
                type="text"
                maxlength="30"
              ></textarea>
              <p class="note">30个字以内；1天修改1次</p>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-ft">
        <a class="dialog-btn-submit" @click="submit">确定</a>
        <a class="dialog-btn-cancel" @click="emits('close')">取消</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import DOMPurify from 'dompurify'
import xss from 'xss'
import { userAPI } from '@/api'
import { toast } from '@/components/toast'

const emits = defineEmits(['close', 'update:modelValue'])

const value = ref('')

const submit = async () => {
  if (!value.value.length) {
    toast('请输入新的个性签名')
    return
  }
  if (value.value.length > 30) {
    toast('个性签名不能超过30个字')
    return
  }

  const val = DOMPurify.sanitize(xss(value.value))
  const res = await userAPI.submitSignature(val).check()

  if (res.success) {
    emits('update:modelValue', val)
  }
  toast(res.msg)
  emits('close')
}
</script>

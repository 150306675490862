import { defineStore } from './defineStore'
import { groupAPI } from '@/api'
import GroupModel from '@/models/group'

export const appGroupsStore = defineStore(() => {
  const loading = ref(false)
  const items = ref([])

  async function init() {
    const data = await groupAPI.allGroups().data()

    items.value = data.map((g) => GroupModel.fromJson(g))

    loading.value = false
  }

  return {
    loading,
    items,
    init,
  }
})({ id: 'appGroups' })

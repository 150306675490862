<template>
  <div class="fixed-box">
    <div class="item">
      <app-link
        to="https://club.17173.com/thread/info/6097#comment"
        class="link-feedback"
        title="bug反馈"
      ></app-link>
    </div>
    <div v-if="showBtn" class="item">
      <a href="javascript:;" class="btn-go-comment" @click="scrollToComment"
        ><svg-icon name="comment-num"></svg-icon
        ><b v-if="tinfo.replies" class="num">{{ tinfo.replies }}</b></a
      >
    </div>
    <div v-if="showBtn" class="item">
      <div class="share"></div>
    </div>
    <div v-if="id" class="item">
      <app-link :to="post.toLink({}, id)" class="btn-go-publish"
        ><svg-icon name="publish"></svg-icon
      ></app-link>
    </div>
    <div class="item">
      <a
        v-if="backTop"
        href="javascript:;"
        class="btn-gotop"
        @click.prevent="toTop"
      >
        <svg-icon name="gotop"></svg-icon>
      </a>
    </div>
  </div>
</template>

<script setup>
import debounce from 'lodash.debounce'
import { post } from '@/core/pages'

const props = defineProps({
  data: {
    type: Object,
    default: null,
  },
  showBtn: {
    type: Boolean,
    default: () => false,
  },
  id: {
    type: Object,
    default: null,
  },
  scrollToComment: {
    type: Function,
    default: null,
  },
})

const backTop = ref(0)

const handleScroll = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  const windowHeight = document.documentElement.clientHeight
  backTop.value = scrollTop >= windowHeight * 0.1
}
const scrollTop = (from = 0, to, duration = 500, endCallback) => {
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame =
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (callback) {
        return window.setTimeout(callback, 1000 / 60)
      }
  }
  const difference = Math.abs(from - to)
  const steps = Math.ceil((difference / duration) * 50)

  function scroll(start, end, step) {
    if (start === end) {
      if (endCallback) endCallback()
      return
    }

    let d = start + step > end ? end : start + step
    if (start > end) {
      d = start - step < end ? end : start - step
    }
    window.scrollTo(d, d)

    window.requestAnimationFrame(() => scroll(d, end, step))
  }
  scroll(from, to, steps)
}

const tinfo = ref({})
watch(
  () => props.data,
  () => {
    if (props.data) {
      tinfo.value = props.data
    }
  },
  {
    immediate: true,
  }
)

const debouncedFunction = debounce(handleScroll, 100)

onMounted(() => {
  window.addEventListener('scroll', debouncedFunction)
  window.addEventListener('resize', debouncedFunction)
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', debouncedFunction)
  window.removeEventListener('resize', debouncedFunction)
})

const toTop = () => {
  const sTop = document.documentElement.scrollTop || document.body.scrollTop
  scrollTop(sTop, 0, props.duration)
  // emits('click')
}

// 分享
/* eslint-disable */
pandora.use(['share'], function (Share) {
  new Share({
    element: '.share',
    showLabel: false,
    shareList: 'weixin,qq,qzone,tsina',
    importStyle: false,
    customShare: {
      weixin: {
        position: 'left',
      },
    },
  })
})
</script>

import { threadAPI, userAPI } from '@/api'
import defineListStore from './defineListStore'
import ThreadModel from '@/models/thread'

// 用户回帖列表
export const userReplyListStore = defineListStore({
  getMethod: threadAPI.getUserReplyList,
  dataParser: (items) => {
    return items.map((item) => {
      const threadModel = ThreadModel.fromJson(item)
      try {
        return threadModel
      } catch (e) {
        console.error(e)
      }
      return item
    })
  },
})

export const userNotificationStore = defineListStore({
  getMethod: userAPI.getNotification,
})

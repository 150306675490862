<template>
  <!-- <iframe
    id="uploadface"
    src="http://avatar.my.17173.com/avatar/form"
    width="750"
    height="530"
    scrolling="no"
    align="center"
    marginwidth="0"
    marginheight="0"
    frameborder="0"
  ></iframe> -->
  <div class="dialog dialog-profile dialog-crop">
    <div class="dialog-in">
      <div class="dialog-hd">
        <div class="dialog-tit">修改头像</div>
        <a class="dialog-btn-close" @click="emits('close')">×</a>
      </div>
      <div class="dialog-bd">
        <div class="crop-box">
          <img ref="cropImageRef" :src="modelValue" alt="" />
        </div>
        <div class="preview-box">
          <p class="tit">效果预览：</p>
          <div ref="preview1" class="preview-rec"></div>
          <p class="txt">120px120</p>
          <div ref="preview2" class="preview-cir"></div>
          <p class="txt">50px50</p>
        </div>
      </div>
      <div class="dialog-ft">
        <a class="dialog-btn-submit" @click="submit">确定</a>
        <a class="dialog-btn-cancel" @click="emits('close')">取消</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import Cropper from 'cropperjs'
import { toast } from '@/components/toast'

defineProps({
  modelValue: {
    type: String,
    default: '',
  },
})

const emits = defineEmits(['close', 'update:modelValue'])

const cropImageRef = ref()

const preview1 = ref()
const preview2 = ref()

const cropper = ref()
function init() {
  cropper.value = new Cropper(cropImageRef.value, {
    aspectRatio: 1,
    viewMode: 1,
    movable: false,
    zoomable: false,
    preview: [preview1.value, preview2.value],
  })
}

onMounted(() => {
  init()
})

const value = ref('')
const submit = async () => {
  value.value = cropper.value
    .getCroppedCanvas({
      width: 240,
      height: 240,
    })
    .toDataURL('image/jpeg', 0.7)

  const url = '//avatar.17173.com/thumbnail/saveAvatar'
  // const res = await axios.post(url, {
  //   imgData:value.value,
  // })

  $.ajax({
    url,
    type: 'POST',
    xhrFields: {
      withCredentials: true,
    },
    data: {
      imgData: value.value,
    },
    crossDomain: true,
    success(d) {
      const res = JSON.parse(d)
      if (res.code === 1) {
        emits('update:modelValue', value.value)
        toast('修改成功')
        emits('close')
      } else {
        toast(res.message)
      }
    },
  })

  // if (res.status === 200) {
  //   emits('update:modelValue', value.value)
  //   toast('修改成功')
  // }

  // emits('close')
}
</script>

<style lang="less">
.dialog-crop {
  .dialog-in {
    width: 660px;
  }
  .dialog-bd {
    overflow: hidden;
    padding: @gap*2 @gap;
  }
}

.crop-box {
  float: left;
  width: 480px;
  height: 300px;
}
.preview-box {
  float: right;
  width: 120px;

  .tit {
    margin-bottom: 0.2em;
    font-weight: 700;
  }
  .preview-rec {
    overflow: hidden;
    width: 120px;
    height: 120px;
  }
  .preview-cir {
    overflow: hidden;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .txt {
    margin-bottom: @gap;
    .em(15em);
    color: @color-light;
  }
}
.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cropper-container img {
  display: block;
  height: 100%;
  image-orientation: 0deg;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  width: 100%;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.cropper-wrap-box,
.cropper-canvas {
  overflow: hidden;
}

.cropper-drag-box {
  background-color: #fff;
  opacity: 0;
}

.cropper-modal {
  background-color: #000;
  opacity: 0.5;
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
  overflow: hidden;
  width: 100%;
}

.cropper-dashed {
  border: 0 dashed #eee;
  display: block;
  opacity: 0.5;
  position: absolute;
}

.cropper-dashed.dashed-h {
  border-bottom-width: 1px;
  border-top-width: 1px;
  height: calc(100% / 3);
  left: 0;
  top: calc(100% / 3);
  width: 100%;
}

.cropper-dashed.dashed-v {
  border-left-width: 1px;
  border-right-width: 1px;
  height: 100%;
  left: calc(100% / 3);
  top: 0;
  width: calc(100% / 3);
}

.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  width: 0;
}

.cropper-center::before,
.cropper-center::after {
  background-color: #eee;
  content: ' ';
  display: block;
  position: absolute;
}

.cropper-center::before {
  height: 1px;
  left: -3px;
  top: 0;
  width: 7px;
}

.cropper-center::after {
  height: 7px;
  left: 0;
  top: -3px;
  width: 1px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}

.cropper-face {
  background-color: #fff;
  left: 0;
  top: 0;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  cursor: ew-resize;
  right: -3px;
  top: 0;
  width: 5px;
}

.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  left: 0;
  top: -3px;
}

.cropper-line.line-w {
  cursor: ew-resize;
  left: -3px;
  top: 0;
  width: 5px;
}

.cropper-line.line-s {
  bottom: -3px;
  cursor: ns-resize;
  height: 5px;
  left: 0;
}

.cropper-point {
  background-color: #39f;
  height: 5px;
  opacity: 0.75;
  width: 5px;
}

.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  right: -3px;
  top: 50%;
}

.cropper-point.point-n {
  cursor: ns-resize;
  left: 50%;
  margin-left: -3px;
  top: -3px;
}

.cropper-point.point-w {
  cursor: ew-resize;
  left: -3px;
  margin-top: -3px;
  top: 50%;
}

.cropper-point.point-s {
  bottom: -3px;
  cursor: s-resize;
  left: 50%;
  margin-left: -3px;
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  right: -3px;
  top: -3px;
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  left: -3px;
  top: -3px;
}

.cropper-point.point-sw {
  bottom: -3px;
  cursor: nesw-resize;
  left: -3px;
}

.cropper-point.point-se {
  bottom: -3px;
  cursor: nwse-resize;
  height: 20px;
  opacity: 1;
  right: -3px;
  width: 20px;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }
}

.cropper-point.point-se::before {
  background-color: #39f;
  bottom: -50%;
  content: ' ';
  display: block;
  height: 200%;
  opacity: 0;
  position: absolute;
  right: -50%;
  width: 200%;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}

.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
</style>

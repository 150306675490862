<template>
  <div class="dialog dialog-profile">
    <div class="dialog-in">
      <div class="dialog-hd">
        <div class="dialog-tit">修改性别</div>
        <a class="dialog-btn-close" @click="emits('close')">×</a>
      </div>
      <div class="dialog-bd">
        <div class="dialog-profile-form">
          <div class="dialog-profile-form-item">
            <div class="tit">性别</div>
            <div class="con">
              <input
                id="male"
                v-model="value"
                name="gender"
                type="radio"
                value="1"
              />
              <label for="male">男</label>

              <input
                id="famale"
                v-model="value"
                name="gender"
                type="radio"
                value="2"
              />
              <label for="famale">女</label>

              <input
                id="secret"
                v-model="value"
                name="gender"
                type="radio"
                value="0"
              />
              <label for="secret">保密</label>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-ft">
        <a class="dialog-btn-submit" @click="submit">确定</a>
        <a class="dialog-btn-cancel" @click="emits('close')">取消</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { userAPI } from '@/api'
import { toast } from '@/components/toast'

const props = defineProps({
  modelValue: { type: Number, default: 0 },
})

const value = ref('')

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue === undefined) {
      return
    }
    value.value = props.modelValue
  },
  {
    immediate: true,
  }
)

const emits = defineEmits(['close', 'update:modelValue'])

const submit = async () => {
  const res = await userAPI.submitGender(value.value).check()
  if (res.success) {
    emits('update:modelValue', parseInt(value.value, 10))
  }
  toast(res.msg)
  emits('close')
}
</script>

<style lang="less"></style>

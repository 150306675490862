<template>
  <div class="mobile-tip">
    <a href="javascript:;" @click="openAPP">用APP打开</a>
  </div>
</template>

<script setup>
const openAPP = (e) => {
  e.preventDefault()
  window.location.href = 'fahao://newfahao/'

  window.setTimeout(() => {
    window.location.href = '//hao.17173.com/app/index.shtml'
  }, 2000)
}
</script>

<style lang="less">
.mobile-tip {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  z-index: @mobile-zindex;
  right: 0;
  bottom: 0;
  left: 0;

  height: 20%;
  max-height: 150px;
  background: linear-gradient(
    to bottom,
    rgba(#fff, 0) 0%,
    rgba(#fff, 0.5) 40%,
    #fff 100%
  );

  a {
    width: 7.5em;
    .em(14em);
    line-height: 2.1;
    color: @color-yellow;
    text-align: center;
    background: @color-black;
    border-radius: (12em / 28);
    box-shadow: 0 1px 6px rgba(#000, 0.7);
    &:hover {
      color: @color-yellow;
    }
  }
}
</style>

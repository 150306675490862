<template>
  <div class="main-wide">
    <div class="crumb">
      <app-link :to="home.toLink()">首页</app-link
      ><app-link :to="user.toLink({ id })">{{ userName }}</app-link
      >关注的人
    </div>

    <section class="comm-mod mod-user-follow">
      <div class="bd">
        <app-user-list v-if="followList" :data="followList"></app-user-list>
        <div v-else class="no-more">暂无关注的人</div>
      </div>
    </section>
  </div>
  <app-fixed-bar></app-fixed-bar>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { home, user } from '@/core/pages'
import { getUid, getUname } from '@/core/passport'
import { userAPI } from '@/api'
import { userFollowsStore } from '@/store/userFollowsStore'

const { id } = useRoute().params

const followList = ref(null)
const userName = ref('')

const titleEndText =
  '_一起一起上游戏圈_硬核游戏玩家聚集地_17173.com中国游戏门户站'

async function init() {
  if (getUid() !== id) {
    const userInfo = await userAPI.getUserInfo(id).data()
    userName.value = userInfo.nickname
    document.title = `${userName.value}关注的人${titleEndText}`

    if (userInfo.followNum) {
      const follows = await userAPI.getUserFollowList(id).data()
      followList.value = follows.dataSet
    }
  }
}

init()

watch(
  () => userFollowsStore.items.value,
  () => {
    if (getUid() !== id) return
    if (!userFollowsStore.items.value) return
    document.title = `我关注的人${titleEndText}`
    userName.value = getUname()
    followList.value = userFollowsStore.items.value
  }
)
</script>

<style lang="less">
.mod-user-follow {
  .list-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .group-item {
    overflow: hidden;
    width: 45%;
    flex-shrink: 0;
  }
  .no-more {
    margin-top: 0;
  }
}
</style>

const storeCache = {}

// debug
window.storeCache = storeCache

/*
 * 定义一个store函数,如果调用该函数时传入id字符串,则会以该id缓存store, 下次执行该函数会返回上一次缓存的结果.
 */
function defineStore(creater) {
  return (config) => {
    let storeInstance
    if (config !== undefined && config.id !== undefined) {
      if (!storeCache[config.id]) {
        storeCache[config.id] = creater(config)
      }
      storeInstance = storeCache[config.id]
    } else {
      storeInstance = creater(config)
    }
    return storeInstance
  }
}
export { defineStore }

<template>
  <div class="dialog dialog-comfirm">
    <div class="dialog-in">
      <div class="dialog-hd">
        <div class="dialog-tit">提示</div>
        <a class="dialog-btn-close" @click.prevent="onCancel">×</a>
      </div>
      <div class="dialog-bd">{{ msg }}</div>
      <div class="dialog-ft">
        <a class="dialog-btn-cancel" @click.prevent="onCancel">取消</a>
        <a class="dialog-btn-submit" @click.prevent="onConfirm">确定</a>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  type: {
    type: String,
    default: 'confirm',
  },
  msg: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['cancel', 'confirm'])

function onConfirm() {
  emit('confirm')
}

function onCancel() {
  emit('cancel')
}
</script>

<style lang="less">
.dialog-comfirm {
  .dialog-in {
    width: 400px;
  }
  .dialog-bd {
    padding: 20px 25px 30px;
    text-align: center;
  }
  .dialog-ft {
    padding: 0 25px 30px;

    display: flex;
    justify-content: space-between;

    a {
      width: 48%;
    }
  }
}
</style>

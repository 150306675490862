import * as d from '@/core/dataGenerator'
import RichContentModel from './richContent'
import UserBadgeModel from './userBadge'

const generalParaContent = (textPara) => {
  let text = ''
  // 父节点的文本内容为所有子元素文本合并
  textPara.forEach((tt) => {
    text += tt.value
  })
  const textParaContent = RichContentModel.fromJson({
    type: 'text',
    value: text,
    childs: [...textPara],
  })
  textParaContent.needComment = textPara[0].attr.needComment
  if (textParaContent.needComment) {
    textParaContent.needCommentClass =
      textParaContent.value.length > 0
        ? 'show-need-comment-con'
        : 'hide-need-comment-con'
  }
  return textParaContent
}

export default class ThreadModel {
  static fromJson(data) {
    const model = new ThreadModel()
    // 如果是回帖&回复则id取的是pid, 如果是帖子则取得是tid
    model.id = d.gString(data.pid || data.tid)

    // 圈子id
    model.gid = d.gString(data.gid)

    // 子板块id
    model.subId = d.gString(data.subId)

    // 标题
    model.title = d.gString(data.subject)

    // 作者昵称
    model.author = d.gString(data.author)

    // 用户认证组名称
    model.authorTitle = d.gString(data.authorTitle)

    // 作者uid
    model.authorUid = d.gString(data.authorid)

    const contentStr = d.gString(data.message)
    // 内容
    model.contentStr = contentStr
    model.content = JSON.parse(contentStr)

    // 点赞数
    model.supports = d.gNum(data.support)

    // 当前用户是否已点赞
    model.hasSupported = d.gBool(data.supportState)

    // 回复数量(如果是帖子,则是所有评论)
    model.replies = d.gNum(data.replies)

    // 浏览数量
    model.views = d.gNum(data.views)

    // 回复的帖子用户uid
    model.puid = d.gString(data.puid)

    // 回复的帖子用户昵称
    model.pname = d.gString(data.pname)

    // 创建时间
    model.createDate = d.gNum(data.threadDate || data.postDate)
    // model.updateDate = d.gNum(data.threadEditDate || data.postEditDate)

    // 关联游戏
    model.newGameCode = d.gString(data.newGameCode)

    // 圈子名称
    model.groupName = d.gString(data.groupName)

    // 精华帖
    model.digest = d.gBool(data.digest)

    // 回复关联的回复id(对回复的回复)
    model.rpid = d.gString(data.rpid)

    // 回复关联的回帖id(对回帖的回复)
    model.ppid = d.gString(data.ppid)

    // 回帖/回复关联的帖子id
    model.tid = d.gString(data.tid)

    // 是否隐藏
    model.isHide = d.gNum(data.displayState) === 3

    // 发布平台platform(1: app 2: web)
    model.platform = d.gNum(data.platform || 0)

    // 回复列表
    model.replys = []
    if (data.reply) {
      data.reply.forEach((t) => {
        model.replys.push(ThreadModel.fromJson(t))
      })
    }

    // 【回帖】数，含子评论
    model.posts = d.gNum(data.totalPostTotal)

    // 回帖列表新增字段
    // 主贴的字段
    model.orginThreadTitle = d.gString(data.threadSubject)
    model.orginThreadContent = d.gString(data.threadMessage)
    // 回帖的评论的字段
    model.orginReplyAuthor = d.gString(data.atAuthor)
    model.orginReplyAuthorUid = d.gString(data.atAuthorid)
    model.orginReplyContent = d.gString(data.postMessage)
    model.orginExist = model.orginThreadContent || model.orginReplyContent

    // 徽章
    if (data.badge) {
      model.badge = UserBadgeModel.fromJson(data.badge)
    }

    // 表情引用
    model.attachQuote = data.attachQuote ? data.attachQuote : null

    // 话题
    model.searchKeywords = data.searchKeywords
      ? data.searchKeywords.map((el) => {
          return { id: el.skid, label: el.searchKeyword }
        })
      : []

    // 内容：文本
    // model.text = model.getText

    // 内容：图片
    // model.images = model.getImages

    // 内容：视频
    // model.videos = model.getVideos

    // ui state
    // 列表里面删帖
    model.$visible = true
    model.cache = {
      contentStr: null,
      richContents: null,
    }
    return model
  }

  // 内容：文本
  get text() {
    return this.richContents.filter((t) => t.isText)
  }

  // 内容：图片
  get images() {
    return this.richContents.filter((t) => t.isImage)
  }

  // 内容：视频
  get videos() {
    return this.richContents.filter((t) => t.isVideo)
  }

  // 内容：视频
  get iframe() {
    return this.richContents.filter((t) => t.isIframe)
  }

  // 内容：鏈接
  get link() {
    return this.richContents.filter((t) => t.isLink)
  }

  // content转成RichContentModel列表
  get richContents() {
    if (this.contentStr === this.cache.contentStr && this.cache.richContents) {
      return this.cache.richContents
    }
    this.cache.contentStr = this.contentStr
    const list = []

    let textPara = []
    // 拆分换行
    const splitContents = []
    // console.log(this.cache.contentStr)
    this.content.forEach((t) => {
      if (t.type === 'text') {
        if (t.value === '') {
          splitContents.push({ ...t })
        } else {
          const values = t.value.split(/(\n)/g).filter((value) => value !== '')
          values.forEach((value) => {
            splitContents.push({ ...t, value })
          })
        }
      } else {
        splitContents.push(t)
      }
    })
    // console.log(splitContents, 2)
    const models = splitContents.map((t) => RichContentModel.fromJson(t))
    // console.log(models, 'begin')
    for (let i = 0; i < models.length; i += 1) {
      const richModel = models[i]

      const prevNeedComment =
        typeof models[i - 1]?.attr.needComment === 'string'
          ? Boolean(models[i - 1]?.attr.needComment)
          : models[i - 1]?.attr.needComment

      const nextNeedComment =
        typeof models[i + 1]?.attr.needComment === 'string'
          ? Boolean(models[i + 1]?.attr.needComment)
          : models[i + 1]?.attr.needComment

      // fixbug: 旧数据回复可见里 前后都为回复可见的\n元素自动加上回复可见
      if (
        richModel.type === 'text' &&
        i > 0 &&
        i !== models.length - 1 &&
        richModel.value.split('\n').filter((t) => t !== '').length === 0 &&
        (prevNeedComment || models[i - 1].value === '\n') &&
        (nextNeedComment || models[i + 1].value === '\n')
      ) {
        richModel.attr.needComment = true
        richModel.needCommentClass =
          richModel.value.length > 0
            ? 'show-need-comment-con'
            : 'hide-need-comment-con'
      }

      // 遇到需要分隔的元素时,将临时分组存为一个父节点(子节点为该段落所有文本)
      // 文本组: 多个text类型的行内node组合成一行,按\n或块状node(image,link,video,iframe)分隔
      if (
        textPara.length &&
        (textPara[0].attr.needComment !== richModel.attr.needComment ||
          richModel.type !== 'text' ||
          i === splitContents.length - 1 ||
          richModel.isBlockText)
      ) {
        const textParaContent = generalParaContent(textPara)
        textPara = []
        // console.log(textParaContent, 111)
        list.push(textParaContent)
        // console.log(textParaContent, 1)
      }

      // 添加元素到临时分组
      const sameNeedCommentValue =
        !textPara.length ||
        textPara[0].attr.needComment === richModel.attr.needComment
      if (
        richModel.type === 'text' &&
        !richModel.isBlockText &&
        sameNeedCommentValue
      ) {
        textPara.push(richModel)
        if (i === models.length - 1) {
          list.push(generalParaContent(textPara))
        }
      }
      if (
        (richModel.type !== 'text' || richModel.isBlockText) &&
        (typeof richModel.value !== 'string' ||
          richModel.attr.needComment ||
          richModel.value)
      ) {
        list.push(richModel)
      }
    }
    // console.log(list, 3)
    const richContents = []
    // 连续多个换行只保留一个
    for (let i = 0; i < list.length; i += 1) {
      if (
        list[i].value !== '\n' ||
        (i > 0 &&
          i !== list.length - 1 &&
          list[i - 1].value !== '\n' &&
          list[i + 1].value === '\n')
      ) {
        // console.log('push', list[i])
        richContents.push(list[i])
      }
    }
    this.cache.richContents = richContents // list.filter((t) => t.value !== '\n')
    // console.log(this.cache.richContents, 4)
    return this.cache.richContents
  }
}

<template>
  <div class="main-wide">
    <div class="crumb">
      <app-link :to="home.toLink()">首页</app-link
      >{{ id ? '编辑' : '发表' }}帖子
    </div>

    <div class="comm-mod mod-post">
      <header class="hd">
        <div class="tit">发表帖子</div>
        <div class="more">
          <span v-if="isShowSaveTip" class="txt-note">草稿已保存</span>
        </div>
      </header>
      <div class="bd">
        <div class="input-tit">标题<span class="txt-note">(选填)</span></div>
        <div class="input-txt">
          <input v-model="title" type="text" @change="saveDraft" />
        </div>
        <div class="input-tit">正文</div>
        <div class="editor-wrap">
          <editor
            ref="editorRef"
            :options="editorOption"
            :is-toolbar-fixed="true"
          ></editor>
        </div>
        <p class="enter-status">
          <span class="num" :class="{ error: contentCount > maxLength }">{{
            contentCount
          }}</span
          >/{{ maxLength }}
        </p>

        <div v-if="subjectData" class="subject-mod">
          <div class="input-tit">话题</div>
          <TagSelect v-model="subjectValue" :options="subjectData" />
        </div>
        <div class="filter-box">
          <select v-model="selectedGroupId" name="" required>
            <option value="0" disabled selected>选择游戏圈（必选）</option>
            <option v-for="group in groups" :key="group.id" :value="group.id">
              {{ group.name }}
            </option>
          </select>
          <select v-model="selectedSectionId" name="" required>
            <option value="0" selected>选择子版块（必选）</option>
            <option
              v-for="section in sections"
              :key="section.id"
              :value="section.id"
            >
              {{ section.name }}
            </option>
          </select>
        </div>
        <div class="func-box">
          <button
            type="button"
            class="ql-clean"
            title="getContent"
            @click="save"
          >
            {{ model.id ? '编辑' : '发表' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { home, thread } from '@/core/pages'
// import ThreadModel from '@/models/thread'
import { groupAPI, threadAPI, subjectAPI } from '@/api'
import GroupModel from '@/models/group'
import { toast } from '@/components/toast'
import {
  postAuthCheck,
  getUid,
  isBindPhone,
  isSockpuppet,
} from '@/core/passport'
import { threadInfoStore } from '@/store/group'

// 话题
import TagSelect from './components/TagSelect.vue'

const subjectData = ref([])
const subjectValue = ref([])

const routeInfo = useRoute()
const { id } = routeInfo.params
const { gid, sid, subject } = routeInfo.query
const editorRef = ref(null)
// 帖子
const model = ref({})
// 圈子列表
const selectedGroupId = ref(0)
const groups = ref([])
// 子版块列表
const selectedSectionId = ref(0)
const sections = ref([])

const title = ref('')
const maxLength = 10000
const contentCount = ref(0)
const storageKey = `thread-post-draft-${getUid()}`
const tStore = threadInfoStore()

let isSubmit = true
const save = async () => {
  if (!isSubmit) {
    return
  }

  _jc_ping.push(['_trackBlockClick', 'BfYnqu'])

  if (editorRef.value.length - 1 <= 10) {
    toast('请输入最少10个字')
    return
  }
  if (!selectedGroupId.value || !selectedSectionId.value) {
    toast('请选择帖子和版块')
    return
  }

  if (!(await isSockpuppet()) && !(await isBindPhone())) {
    Passport.Dialog.showMobileBinder()
    return
  }

  isSubmit = false

  postAuthCheck(async () => {
    const params = {
      id,
      title: title.value,
      gid: selectedGroupId.value,
      sid: selectedSectionId.value,
      content: editorRef.value.contents,
      searchKeywords: subjectData.value
        .filter((o) => subjectValue.value.includes(o.id))
        .map((o) => o.label)
        .join(','),
    }
    const postPromise = threadAPI.postThread(params)
    const checkRes = await postPromise.check()
    if (checkRes.success) {
      const data = await postPromise.data()

      localStorage.removeItem(storageKey)
      thread.to({ id: data.threadInfo.tid })
    } else {
      toast(checkRes.msg)
      setTimeout(() => {
        isSubmit = true
      }, 2000)
    }
  })
}

const loadGroups = async () => {
  const data = await groupAPI.allGroups().data()
  groups.value = data.map((g) => GroupModel.fromJson(g))
  // 新帖子并且制定圈子id
  if (
    !id &&
    gid &&
    groups.value.filter((t) => t.id * 1 === gid * 1).length > 0
  ) {
    selectedGroupId.value = gid
  }
}

const loadSection = async () => {
  if (selectedGroupId) {
    sections.value = await groupAPI.getSections(selectedGroupId.value).data()
    // 新帖子并且制定圈子id
    if (
      !id &&
      sid &&
      sections.value.filter((t) => t.id * 1 === sid * 1).length > 0
    ) {
      selectedSectionId.value = sid
    } else if (
      tStore.data.value &&
      sections.value.filter((t) => t.id * 1 === tStore.data.value.subId * 1)
        .length > 0
    ) {
      selectedSectionId.value = tStore.data.value.subId
    }
  }
}

const loadSujects = async () => {
  const subjectRes = await subjectAPI.getSubjectList().data()
  subjectData.value = subjectRes.dataSet
    ? subjectRes.dataSet.map((el) => {
        return { id: el.id, label: el.searchKeyword }
      })
    : []

  if (subject) {
    // 赋值给subjectValue
    subjectValue.value = [Number(subject)]
  }
}

watch(selectedGroupId, loadSection)

const saveDraft = () => {
  // console.log('save draft')
  localStorage.setItem(
    storageKey,
    JSON.stringify({
      title: title.value,
      content: JSON.parse(editorRef.value.contents),
    })
  )
}

let isChange = 0
let saveTimer = 0
const isShowSaveTip = ref(0)

if (!id) {
  saveTimer = setInterval(() => {
    if (!isChange) {
      isShowSaveTip.value = 1
      return
    }
    isChange = 0
    isShowSaveTip.value = 0
  }, 3000)
}

const onChange = () => {
  contentCount.value = editorRef.value.length - 1
  saveDraft()
  isChange = 1
}
const editorOption = {
  video: true,
  iframe: true,
  link: true,
  needComment: true,
  onChange,
  imageDesc: true,
  hasHeading: true,
  hasBold: true,
  hasClearFormat: true,
}
onMounted(async () => {
  await loadGroups()

  await loadSujects()

  if (id) {
    await tStore.actions.load({ tid: id })
    selectedGroupId.value = tStore.data.value.gid * 1
    if (tStore.data.value.searchKeywords) {
      subjectValue.value = tStore.data.value.searchKeywords.map((el) => el.id)
    }
    title.value = tStore.data.value.title
    editorRef.value.loadContents(JSON.stringify(tStore.data.value.content))
  } else {
    const draftContents = JSON.parse(localStorage.getItem(storageKey))
    if (!draftContents) return
    title.value = draftContents.title
    editorRef.value.loadContents(draftContents.content)
  }
})
onUnmounted(() => {
  if (saveTimer) clearInterval(saveTimer)
})
</script>

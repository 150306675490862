import home from './home/Home.vue'
import thread from './thread/Thread.vue'
import post from './thread/post.vue'
import group from './group/Group.vue'
import groupManagerList from './group/GroupManagerList.vue'
import groupList from './group/GroupList.vue'
import user from './user/User.vue'
import userFollowList from './user/UserFollowList.vue'
import userFansList from './user/UserFansList.vue'
import userNotification from './user/UserNotification.vue'
import userProfile from './user/UserProfile.vue'
import help from './help/Help.vue'
import error from './404.vue'
import subject from './subject/Subject.vue'
import sample from './Sample.vue'

export default {
  home,
  thread,
  post,
  group,
  groupManagerList,
  groupList,
  user,
  userFollowList,
  userFansList,
  userNotification,
  userProfile,
  help,
  error,
  subject,
  sample,
}

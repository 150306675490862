<template>
  <slot></slot>

  <div v-if="error" class="loading-error">{{ error }}</div>
  <div v-else-if="empty" class="no-more">没有更多了</div>
  <div v-else-if="finished" class="no-more">没有更多了</div>
  <div v-else-if="loading" data-loading></div>

  <div ref="watcher" style="height: 1px"></div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    finished: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [Boolean, String],
      default: false,
    },
    offset: {
      type: Number,
      default: 100,
    },
    empty: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['load'],
  setup(props, { emit }) {
    const watcher = ref()
    const observer = ref()
    const checkAfterLoading = ref()

    function triggerLoadMore() {
      if (props.loading) {
        checkAfterLoading.value = true
        return
      }
      if (props.finished || props.error) {
        return
      }
      emit('load')
    }

    function init() {
      observer.value = new IntersectionObserver((entries) => {
        const entry = entries[0]
        if (!entry || !entry.isIntersecting) return
        triggerLoadMore()
      }, {})
      observer.value.observe(watcher.value)
    }

    function isInViewport() {
      const distance = watcher.value.getBoundingClientRect()
      return (
        distance.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
      )
    }

    watch(
      () => props.loading,
      () => {
        if (!props.loading && checkAfterLoading.value && isInViewport()) {
          checkAfterLoading.value = false
          emit('load')
        }
      }
    )

    onMounted(init)

    onUnmounted(() => {
      if (observer.value) observer.value.disconnect()
    })

    return {
      watcher,
    }
  },
}
</script>
<style lang="less">
[data-loading] {
  padding: @gap;
  height: 50px;
  text-align: center;
  background: url(@/assets/images/loading.gif) no-repeat 50% 50%;
}
.no-more {
  padding: @gap;
  margin-top: -20px;
  .em(14rem);
  color: @color-light;
  text-align: center;
  background: #fff;
  border-radius: 4px;
}
.loading-error {
  padding: @gap;
  color: @color-orange;
  text-align: center;
}
</style>

<template>
  <div v-track-click="'r2A3ei'" class="user-card" @mouseenter="showPopup">
    <template v-if="data">
      <figure class="avatar">
        <app-link :to="user.toLink({ id: data.authorUid })"
          ><img :src="getAvatar(data.authorUid)" alt="" />
          <svg-icon v-if="data.authorTitle.length" name="vip"></svg-icon
        ></app-link>
      </figure>
      <div class="name">
        <app-link :to="user.toLink({ id: data.authorUid })">{{
          data.author
        }}</app-link>
      </div>
    </template>

    <user-card-popup v-if="popupVisible" :data="data"></user-card-popup>
  </div>
</template>

<script setup>
import { user } from '@/core/pages'
import { getAvatar } from '@/core/passport'

defineProps({
  data: {
    type: Object,
    default: null,
  },
})

const popupVisible = ref(false)

function showPopup() {
  popupVisible.value = true
}
</script>

<style lang="less">
.user-card {
  position: relative;
  .avatar {
    .img(visible);

    img {
      border-radius: 50%;
    }
  }
  .svg-ico {
    position: absolute;
  }
  & > .avatar {
    position: relative;
    width: 32px;
    height: 32px;

    .svg-ico {
      right: -1px;
      bottom: -1px;
      width: 43.75%;
      height: 43.75%;
    }
  }

  &:hover {
    z-index: @user-card-zindex;

    .user-card-popup {
      display: block;
    }
  }
}
</style>

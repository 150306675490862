import * as d from '@/core/dataGenerator'

export default class UserBadgeModel {
  static fromJson(data) {
    const model = new UserBadgeModel()

    model.src = d.gString(data.logo)
    model.title = d.gString(data.title)
    model.time = d.gString(data.time)
    model.desc = d.gString(data.intro)
    model.level = 1

    const levelStr = model.src.match(/-\d+\.png/)
    if (levelStr) {
      const level = levelStr[0].split('-')[1].split('.')[0]
      model.level = level
    }

    return model
  }
}

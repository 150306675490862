<template>
  <div class="side-bar">
    <nav class="nav-list">
      <app-link :to="home.toLink()" target="_blank"
        ><svg-icon name="nav-home"></svg-icon>社区首页</app-link
      >
      <app-link
        id="link_my_home"
        :to="user.toLink({ id: 'mine' })"
        @click="goMyPage"
        ><img v-if="isLoggedIn()" :src="getAvatar()" alt="" /><svg-icon
          v-else
          name="nav-user"
        ></svg-icon
        >个人中心</app-link
      >
    </nav>

    <section v-if="myGroups.length" v-track-module="'b2amei'" class="mod">
      <header class="hd">
        <app-link :to="groupList.toLink()" class="tit">我的圈子</app-link>
      </header>
      <div class="bd">
        <div class="list-group">
          <div
            v-for="(item, index) in myGroups"
            :key="index"
            class="group-item"
          >
            <app-link :to="group.toLink({ id: item.id })" class="con">
              <figure class="pic">
                <group-avatar :data="item.logo"></group-avatar>
              </figure>
              <div class="txt">
                <p class="name">{{ item.name }}</p>
              </div>
            </app-link>
          </div>
        </div>
      </div>
    </section>

    <section v-if="hotGroups.length" v-track-module="'yM3yYf'" class="mod">
      <header class="hd">
        <app-link :to="groupList.toLink()" class="tit">热门圈子</app-link>
      </header>
      <div class="bd">
        <div class="list-group">
          <div
            v-for="(item, index) in hotGroups"
            :key="index"
            class="group-item"
          >
            <app-link :to="group.toLink({ id: item.id })" class="con">
              <figure class="pic">
                <group-avatar :data="item.logo"></group-avatar>
              </figure>
              <div class="txt">
                <p class="name">{{ item.name }}</p>
              </div>
            </app-link>
          </div>
        </div>
      </div>
    </section>

    <section class="mod">
      <header class="hd">用手机观看</header>
      <div class="bd">
        <div class="qrcode">
          <img :src="qrcode" alt="" />
        </div>
        <div class="btns">
          <app-link to="https://app.17173.com/" class="link-android"
            ><svg-icon name="android"></svg-icon>Android版下载</app-link
          >
          <span class="link-ios"
            ><svg-icon name="ios"></svg-icon>iOS版敬请期待</span
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { userAPI } from '@/api'
import { home, user, group, groupList } from '@/core/pages'
import { isLoggedIn, getUid, login, getAvatar } from '@/core/passport'
import { getAssets } from '@/core/util'

import { userGroupsStore } from '@/store/userGroupsStore'
import { appHotGroupsStore } from '@/store/appHotGroupsStore'

const qrcode = getAssets('images/qrcode.png')

const { items: myGroups } = userGroupsStore
const { items: hotGroups } = appHotGroupsStore

function goMyPage(e) {
  if (!isLoggedIn()) {
    e.preventDefault()
    login()

    Passport.on(Passport.EVENTS.loginSuccess, async () => {
      if (await userAPI.login().check()) {
        window.location.href = user.toLink({ id: getUid() })
      }
    })
  }
}
</script>

<style lang="less">
@side-bar-lr-gap: 10px;
.side-bar {
  position: fixed;
  z-index: @menu-zindex;
  top: 44px;
  bottom: 0;
  left: 0;
  width: @nav-width;
  padding: 30px 0;
  .em(14em);
  background: #fff;
  overflow-y: auto;
  filter: drop-shadow(0 0 5px #eee);

  &::-webkit-scrollbar {
    width: 4px;
    background: #fff;
    transition: background ease 0.5s;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
  }

  &:hover {
    &::-webkit-scrollbar {
      width: 4px;
      background: #eee;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(#000, 0.2);
    }
  }
  .logo {
    margin-bottom: 15px;
    text-align: center;
  }

  .mod {
    margin-left: @side-bar-lr-gap;
    margin-right: @side-bar-lr-gap;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid @color-lighten;

    .hd {
      .tit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: @color-light;

        &:hover {
          color: @color-black;
        }

        &::after {
          content: ' ';
          width: 0.4em;
          height: 0.4em;
          border-top: 1px solid currentColor;
          border-right: 1px solid currentColor;
          transform: rotate(45deg);
          margin-right: 0.5em;
        }
      }
    }

    .btns {
      [class*='link-'] {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        margin: 15px auto;
        line-height: 40px;
        border-radius: 6px;

        .svg-ico {
          width: 1.2em;
          height: 1.2em;
          margin-right: 0.5em;
          fill: currentColor;
        }
      }
    }
  }

  .list-group {
    padding-bottom: 0;
    .group-item {
      margin-top: 10px;
    }
    .con {
      padding: 5px @gap;
      border-radius: 4px;
      &:hover {
        background-color: @bg-gray;
      }
    }
    .pic {
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }
  }

  .qrcode {
    width: 110px;
    height: 137px;
    margin: 20px auto;
    padding: 5px;
    background: url(@/assets/images/qrcode-bg.png) no-repeat center top;
    img {
      width: 100px;
    }
  }
  .btns {
    margin-top: 30px;
  }

  .link-android {
    color: #fff;
    background-color: #222;
  }
  .link-ios {
    color: @color-light;
    background-color: #fff;
    border: 1px solid @color-lighten;
    cursor: default;
  }
}

@media all and (max-width: 640px) {
  .side-bar {
    display: none;
  }
  .wrap {
    margin-left: 0;
  }
}

.nav-list {
  margin-left: @side-bar-lr-gap;
  margin-right: @side-bar-lr-gap;
  .svg-ico {
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.5em;
    margin-bottom: 0.2em;
    fill: #a7aab5;
    vertical-align: middle;
  }
  img {
    width: 1.3em;
    height: 1.3em;
    margin-right: 0.4em;
    margin-bottom: 0.2em;
    border-radius: 50%;
    vertical-align: middle;
  }

  a {
    display: block;
    padding-left: @gap;
    line-height: 3.5;
    border-radius: 4px;

    &:not(:first-child) {
      margin-top: 5px;
    }

    &:hover {
      &:not(.active) {
        font-weight: 700;
      }
    }

    &.active {
      background: #fffae5;

      .svg-ico {
        fill: @color-yellow;
      }
    }
  }
}
</style>

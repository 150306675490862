<template>
  <section
    v-if="items.length"
    v-track-module="'VRbyym'"
    class="comm-mod mod-group-visited"
  >
    <header class="hd">
      <div class="tit">常逛的圈子</div>
    </header>
    <div class="bd">
      <ul class="list-hor list-group-visited">
        <li v-for="item in items" :key="item.id">
          <app-link :to="group.toLink({ id: item.id })">
            <figure class="pic">
              <group-avatar :data="item.logo"></group-avatar>
            </figure>
            <p class="name">{{ item.name }}</p>
            <i v-if="item.$hasNew" class="ico-new"></i>
          </app-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
import { group } from '@/core/pages'
import useVisitedGroups from '@/hooks/useVisitedGroups'
import { appGroupsStore } from '@/store/appGroupsStore'

const { groups: visitedGroups } = useVisitedGroups()
const { items: appGroup } = appGroupsStore

const items = computed(() => {
  return visitedGroups.value.reduce((res, v) => {
    const matched = appGroup.value.find((g) => g.id === v.id)

    if (!matched) return res

    res.push({
      ...matched,
      $hasNew: matched.lastThread > v.visitedDate,
    })

    return res
  }, [])
})
</script>

<style lang="less">
.mod-group-visited {
  background: #fff url(@/assets/images/visited-bg.png) no-repeat center top /
    cover;
}
.list-group-visited {
  display: flex;
  flex-wrap: wrap;
  li {
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    position: relative;
    width: 70px;
    margin: @gap 0;
    text-align: center;

    .em(14em);

    &:not(:first-child) {
      margin-left: @gap + 1;
    }

    .pic {
      width: 70px;
      height: 70px;
      margin-bottom: 0.2em;
      border-radius: 14px;
      .img();
    }

    .name {
      .text-ellipsis();
    }

    .ico-new {
      overflow: hidden;
      position: absolute;
      top: -4px;
      right: -4px;
      width: 12px;
      height: 12px;
      background: #ff4747;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }
}
</style>

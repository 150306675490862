<template>
  <div class="thread-top">
    <div class="intro">
      <figure class="avatar">
        <group-avatar :data="info.logo"></group-avatar>
      </figure>
      <div class="con">
        <div class="name">
          {{ info.name }}<img v-if="info.official" :src="ico" alt="" />
        </div>
        <div class="others">
          <div class="nums">
            <span>{{ numberFormat(info.followNum) }}订阅</span>
            <span>{{ numberFormat(info.threadNum) }}帖子</span>
          </div>
          <app-link
            v-if="info.rule"
            :to="thread.toLink({ id: info.rule })"
            class="btn-show-role"
            ><svg-icon name="role"></svg-icon>版规</app-link
          >
          <!-- <app-link
            :to="
              'https://newgame.17173.com/game-info-' +
              info.newGameCode +
              '.html'
            "
            class="btn-game-url"
            >游戏详情&gt;</app-link
          > -->
        </div>
      </div>
    </div>
    <div class="btn-group-rss" @click="postAuthCheck(onFollowClick)">
      <svg-icon :name="[info.followState ? 'rss-active' : 'rss']"></svg-icon
      >{{ info.followState ? '已订阅' : '订阅' }}
    </div>
    <app-link
      v-track-click="'qaii6b'"
      :to="post.toLink({}, { gid: id })"
      class="btn-go-publish"
      ><svg-icon name="publish"></svg-icon>发帖</app-link
    >
    <div
      class="bg"
      :style="info.bgImage ? `background-image: url(${info.bgImage})` : ''"
    ></div>
  </div>
  <div class="main">
    <div
      v-if="headlines"
      v-track-module="'JVRVFr'"
      class="comm-mod mod-thread-tools"
    >
      <div class="thread-tools-list-wrap">
        <div class="thread-tools-list">
          <app-link
            v-for="headline in headlines"
            :key="headline.id"
            :to="headline.url"
            class="item"
            :data-id="headline.id"
          >
            <figure class="pic">
              <group-avatar :data="headline.icon"></group-avatar>
            </figure>
            <p class="txt">{{ headline.name }}</p>
          </app-link>
        </div>
      </div>
    </div>
    <div class="comm-mod mod-thread-home-list">
      <div class="thread-hd">
        <div class="thread-tab">
          <div
            class="item"
            :class="{ active: queryType == 'all' }"
            @click="queryType = 'all'"
          >
            全部
          </div>
          <div
            class="item"
            :class="{ active: queryType == 'digest' }"
            @click="queryType = 'digest'"
          >
            精华
          </div>
          <div
            class="item"
            :class="{ active: queryType == 'video' }"
            @click="queryType = 'video'"
          >
            视频
          </div>
        </div>
        <div class="operation-sort">
          <div class="operation-sort-btn">
            {{ querySort === 'res' ? '按回帖' : '按发贴' }}
          </div>
          <div v-track-module="'MF7fMv'" class="operation-sort-popup">
            <div @click="querySort = 'res'">按回帖</div>
            <div @click="querySort = 'post'">按发贴</div>
          </div>
        </div>
      </div>
      <div class="thread-bd">
        <div
          v-if="tops.length"
          v-track-module="'BZBzym'"
          :class="['thread-top-list', isShowAll ? 'active' : '']"
        >
          <div v-for="top in tops" :key="top.id" class="item">
            <app-link :to="thread.toLink({ id: top.tid })">
              <span class="type-tag-top">置顶</span>{{ top.subject }}
            </app-link>
          </div>
          <a
            v-if="tops.length > 2"
            href="javascript:;"
            class="btn"
            @click="topsShowToggle"
          >
            {{ isShowAll ? '收起' : '展开' }}
          </a>
        </div>
        <div
          v-if="subjects.length"
          v-track-module="'qAFFBf'"
          class="thread-tags"
        >
          <a
            v-for="subject in subjects"
            :key="subject.id"
            href="javascript:;"
            class="item"
            :class="{ active: subject.id === querySubject }"
            @click="querySubject = subject.id"
          >
            {{ subject.name }}
          </a>
        </div>
        <list-loader
          v-bind="threadStates"
          @load="threadActions.load(getQuery())"
        >
          <div class="thread-list">
            <thread-item
              v-for="(item, index) in threadItems"
              :key="item.id"
              :data="item"
              :is-manager="isManager"
              :index="index"
              :gid="id"
            ></thread-item>
          </div>
        </list-loader>
      </div>
    </div>
  </div>
  <div class="side">
    <section
      v-if="mList.totalCount"
      v-track-module="'FjUzii'"
      class="comm-mod mod-thread-group"
    >
      <header class="hd">
        <div class="tit">
          管理成员<span class="num">({{ mList.totalCount }})</span>
        </div>
        <div class="more">
          <app-link :to="groupManagerList.toLink({ id })"
            >查看更多&gt;</app-link
          >
        </div>
      </header>
      <div class="bd">
        <app-user-list
          v-if="mList.dataSet"
          :data="mList.dataSet"
        ></app-user-list>
      </div>
    </section>
    <section
      v-if="hotThreadList.length"
      v-track-module="'fEJ7je'"
      class="comm-mod mod-hot-thread"
    >
      <header class="hd">
        <div class="tit">近期热帖</div>
      </header>
      <div class="bd">
        <div class="hot-thread-list">
          <div
            v-for="data in hotThreadList.slice(0, 5)"
            :key="data.id"
            class="item"
          >
            <app-link :to="thread.toLink({ id: data.id })">
              <div class="con">
                <template
                  v-for="(item, index) in data.richContents"
                  :key="index"
                >
                  <div v-if="item.isText" class="txt">{{ item.value }}</div>
                </template>
                <div class="action-bar">
                  <span class="time">{{ dateFormat(data.createDate) }}</span>
                  <span class="reply"
                    ><svg-icon name="comment"></svg-icon
                    >{{ data.posts > 0 ? numberFormat(data.posts) : '' }}</span
                  >
                </div>
              </div>
              <figure v-if="data.images.length" class="pic">
                <img :src="data.images[0].value" />
              </figure>
            </app-link>
          </div>
        </div>
      </div>
    </section>
  </div>
  <app-fixed-bar :id="{ gid: id }"></app-fixed-bar>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { getAssets, numberFormat, dateFormat } from '@/core/util'
import { thread, groupManagerList, post } from '@/core/pages'
import { getUid, postAuthCheck } from '@/core/passport'
import { groupAPI, userAPI } from '@/api'
import GroupModel from '@/models/group'
import ThreadModel from '@/models/thread'
import useVisitedGroups from '@/hooks/useVisitedGroups'
import { groupHomeThreadListStore } from '@/store/group'
import { toast } from '@/components/toast'

const ico = getAssets('images/medal-official.png')

const { id } = useRoute().params
const { addGroup } = useVisitedGroups()
const info = ref({})

const headlines = ref([])
const tops = ref([])
const isManager = ref(false)
const subjects = ref([])

const mList = ref([])
const hotThreadList = ref([])

// 置顶列表展开收起
const isShowAll = ref(false)
function topsShowToggle() {
  if (isShowAll.value) {
    isShowAll.value = false
    document.querySelector('.thread-top-list').style.maxHeight = '108px'
  } else {
    isShowAll.value = true
    document.querySelector('.thread-top-list').style.maxHeight = `${
      (29 + 10) * tops.value.length + 31
    }px`
  }
}

async function init() {
  const a = await groupAPI.groupFinal(id)
  const status = await a.check()
  if (status.success) {
    const d = await a.data()
    info.value = GroupModel.fromJson(d.groupInfo)
    // set to Visited
    addGroup(info.value)

    headlines.value = d.header
    tops.value = d.top || []
    isManager.value =
      d.manager &&
      Boolean(d.manager.filter((m) => m.toString() === getUid()).length)

    subjects.value = d.sub

    document.title = `${info.value.name}_一起一起上游戏圈_硬核游戏玩家聚集地_17173.com中国游戏门户站`
  } else {
    toast(status.msg)
  }

  mList.value = await userAPI.getGroupManagers(id).data()

  const htList = (await groupAPI.hotThread(id).data()) || []
  if (htList.length > 0) {
    hotThreadList.value = htList.map((t) => {
      return ThreadModel.fromJson(t)
    })
  }
}

init()

const eventBus = inject('event-bus')

function onFollowClick() {
  if (info.value.followState) {
    groupAPI.cancelFollowGroup(info.value.id)
    info.value.followState = false
    info.value.followNum -= 1
    eventBus.emit('group:unfollow', info.value.id)
  } else {
    groupAPI.submitFollowGroup(info.value.id)
    info.value.followState = true
    info.value.followNum += 1
    eventBus.emit('group:follow', info.value.id)
  }
}

// ThreadList

// query
const queryType = ref('all') // all/digest/video
const querySubject = ref(0)
const querySort = ref('res') // res/post

const {
  items: threadItems,
  states: threadStates,
  actions: threadActions,
} = groupHomeThreadListStore()

function getQuery() {
  return {
    gid: id,
    subId: querySubject.value,
    ...(() => {
      const res = {}
      if (queryType.value === 'all') return res
      if (queryType.value === 'digest') {
        res.digest = 1
      }
      if (queryType.value === 'video') {
        res.video = 1
      }
      return res
    })(),
    order: querySort.value === 'res' ? 0 : 1,
  }
}

threadActions.load({
  gid: id,
})

watch([queryType, querySubject, querySort], () => {
  threadActions.reset()
  threadActions.load(getQuery())
})
</script>

<style lang="less">
.thread-top {
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 200px;
  margin-bottom: @gap;
  color: #fff;
  border-radius: 6px;

  .bg {
    height: 100%;
    filter: blur(5px);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0.1);
  }

  .intro {
    display: flex;
    align-items: center;

    position: absolute;
    z-index: 5;
    top: 86px;
    left: 30px;
  }
  .avatar {
    width: 90px;
    height: 90px;
    margin-right: @gap;
    .img();
    border: 2px solid #fff;
    border-radius: 18px;
  }

  .name {
    margin-bottom: 0.2em;
    .em(30em);
    font-weight: 700;

    img {
      margin-left: 0.2em;
    }
  }

  .svg-ico {
    margin-bottom: 0.2em;
    fill: currentColor;
    vertical-align: middle;
  }

  .others {
    display: flex;
    .em(14em);
    a {
      margin-right: 30px;
      color: currentColor;
      &:hover {
        text-decoration: underline;
      }
    }
    .ico-role {
      width: 12px;
      height: 14px;
      margin-right: 0.3em;
    }
  }

  .nums {
    margin-right: 30px;

    span:not(:first-child) {
      &::before {
        content: ' ';
        display: inline-block;
        overflow: hidden;
        width: 1px;
        height: 0.8em;
        margin: 0 1em 0.1em;
        vertical-align: middle;
        background: currentColor;
      }
    }
  }

  .btn-group-rss {
    position: absolute;
    z-index: 5;
    top: 30px;
    right: 30px;
    width: 86px;
    .em(14em);
    line-height: 30px;
    text-align: center;
    background: rgba(#fff, 0.2);
    border-radius: 15px;
    cursor: pointer;

    .svg-ico {
      width: 1.1em;
      height: 1.1em;
      margin-right: 0.3em;
    }

    .ico-rss-active {
      fill: @color-yellow;
    }
  }

  .btn-go-publish {
    position: absolute;
    z-index: 5;
    right: 30px;
    bottom: 30px;
    .comment-btn(100px,36px);

    .ico-publish {
      width: 1em;
      height: 1em;
      margin-right: 0.4em;
    }
  }
}

.thread-top-list {
  overflow: hidden;
  position: relative;
  max-height: 108px;
  padding: @gap;
  border-bottom: 1px solid @color-lighten;
  transition: max-height 0.2s;

  .item {
    overflow: hidden;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .btn {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 0.4em @gap;
    .em(14em);
    color: @color-blue;
    text-align: right;

    &::after {
      content: ' ';
      display: inline-block;
      .arrow-down();
      width: 0.3em;
      height: 0.3em;
      margin-left: 0.3em;
      margin-bottom: 0.2em;
      transform: rotate(-135deg);
    }
  }

  &.active {
    .btn {
      &::after {
        transform: translateY(0.2em) rotate(45deg);
      }
    }
  }

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: @gap;
    background: #fff;
  }
}

@tool-item-size: 56px;
.mod-thread-tools {
  padding: @gap @gap @gap*.2;
}

.thread-tools-list-wrap {
  overflow: hidden;
  overflow-x: auto;
  .scrollbar();
  padding-bottom: @gap*.3;
}
.thread-tools-list {
  display: flex;

  .item {
    flex-shrink: 0;
    width: (@tool-item-size + 30px);
    &:not(:first-of-type) {
      margin-left: 20px;
    }
  }

  .pic {
    width: @tool-item-size;
    height: @tool-item-size;
    margin-left: auto;
    margin-right: auto;

    .img();
    border-radius: 4px;
  }
  .txt {
    overflow: hidden;
    margin-top: 0.5em;
    .em(14em);
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.thread-tags {
  display: flex;
  flex-wrap: wrap;
  padding: @gap*.5 (@gap - 4px) @gap;
  .item {
    margin: @gap*.5 4px 0;
    padding: 0 @gap;
    .em(14em);
    line-height: 32px;
    white-space: nowrap;
    background: @bg-gray;
    border-radius: 16px;
    cursor: pointer;

    &.active {
      color: @color-yellow;
      border: 1px solid currentColor;
      background: #fff;
    }
  }
}

.mod-thread-home-list {
  .operation-sort {
    font-size: 1rem;
  }
}

.hot-thread-list {
  padding-bottom: @gap;
  .item {
    padding-top: @gap;
  }
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pic {
    flex-shrink: 0;
    width: 30%;
    height: 72px;
    margin-left: @gap * 0.8;
    border-radius: 6px;
    .img();
  }
  .txt {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-word;
  }
  .action-bar {
    margin-top: 0.5em;
    font-size: 0.75em;
    justify-content: flex-start;
  }
}
</style>

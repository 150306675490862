import { getUid } from '@/core/passport'

const useVisitedGroups = () => {
  /**
   * {
   *  id,
   *  visitedDate
   * }
   */
  const groups = useStorage(`17173club_visited_group_${getUid() || 0}`, [])

  function addGroup(group) {
    groups.value = [
      {
        id: group.id,
        visitedDate: Math.ceil(Date.now() / 1000),
      },
      ...groups.value.filter((g) => g.id !== group.id),
    ].slice(0, 7)
  }

  return {
    groups,
    addGroup,
  }
}

export default useVisitedGroups

import md5 from 'md5'
import { toast } from '@/components/toast'
import { userAPI } from '@/api/index'

// 当前用户uid
export const getUid = () => {
  return Passport.data('uid')
}

export const getUname = () => {
  return Passport.data('nickname')
}

export const getName = () => {
  return Passport.data('username')
}

export const isBindPhone = async () => {
  return new Promise((resolve) => {
    Passport.getMobileStatus((o) => {
      resolve(o)
    })
  })
}

export const isSockpuppet = async () => {
  const data = await userAPI.getAccountStatus().data()
  return data.identity ? data.identity === 2 : false
}

// 通过Uid获取用户头像, 不传uid则返回当前用户头像
export const getAvatar = (uid) => {
  const userId = (uid || getUid()).toString()
  let avator = 'https://ue.17173cdn.com/a/hao/app/images/avatar.png'
  if (userId) {
    const md5Str = md5(userId)
    avator = `https://i.17173cdn.com/avatar/YWxqaGBf/${md5Str.slice(
      0,
      2
    )}/${md5Str.slice(2, 4)}/${md5Str.slice(
      4,
      6
    )}/${userId}/hd.jpg?t=${Math.floor(Math.random() * 10000)}`
  }

  return avator
}
// 当前用户是否已登录
export const isLoggedIn = () => {
  return Passport.isLoggedIn()
}

// 当前用户是否禁言
export const isMuted = () => {
  return false
}

// 传入的uid是否当前用户
export const isCurrent = (uid) => {
  return uid === getUid()
}

// 当前用户是否被ban
export const isBaned = () => {
  return false
}

// 当前用户是否可以发言
export const canComment = () => {
  return !isBaned() && !isMuted()
}

const isMobile = /android|iphone|ipad|ipod|Windows Phone|SymbianOS/gi.test(
  navigator.userAgent
)

export const login = () => {
  if (!isLoggedIn()) {
    if (isMobile) {
      window.location.href = `https://passport.17173.com/wap?return_url=${encodeURIComponent(
        window.location.href
      )}`
    } else {
      Passport.Dialog.show()
    }
  }
}

export const postAuthCheck = (fn) => {
  if (!isLoggedIn()) {
    if (isMobile) {
      login()
    } else {
      // eslint-disable-next-line no-restricted-globals
      const result = confirm('您尚未登录,是否跳转到登录页?')
      if (result) login()
    }
    return
  }

  if (canComment()) {
    fn()
  } else {
    toast('被禁言')
  }
}
